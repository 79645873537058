import ColorStyles from '@Styles/colors.scss';
import LayoutStyles from '@Styles/layout.scss';
import _isNil from 'lodash/isNil';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import { Checkbox } from '@Components/FormControls';
import { InputSize, TextInput } from '@Components/Inputs';
import { ToastType } from '@Components/Toasts';
import { OrganizationsApi } from '@Data/organizations';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlagsList';
import { isAuthFieldMaxLengthValid } from '@Services/helpers/Auth/AuthHelpers';
import { isEmailAddressValid, toTitleCase } from '@Services/helpers/Strings';
import { AuthKeys, FormKeys } from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';
import Styles from './CreateOrganizationDialog.scss';

interface ICreateOrganizationDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateOrganizationDialog = (props: ICreateOrganizationDialogProps) => {
    const intl = useIntl();
    const FLAG_canUserCreateOrg = useFeatureFlagEnabled(
        FeatureFlags.CanUserCreateOrganization
    );

    let isOpen = props.isOpen;

    useEffect(() => {
        if (!FLAG_canUserCreateOrg) {
            isOpen = false;
        }
    }, [FLAG_canUserCreateOrg]);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [organizationName, setOrganizationName] = useState<string>('');
    const [termsAndConditions, setTermsAndConditions] =
        useState<boolean>(false);
    const organizationToCreate = {
        name: organizationName,
        first_name: firstName,
        last_name: lastName,
        email: emailAddress,
    };

    const handleSetFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        if (isAuthFieldMaxLengthValid(newName)) {
            setFirstName(newName);
        }
    };

    const handleSetLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        if (isAuthFieldMaxLengthValid(newName)) {
            setLastName(newName);
        }
    };

    const handleSetEmailAddress = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newEmailAddress = event.target.value;
        setEmailAddress(newEmailAddress);
    };

    const handleSetOrganizationName = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newName = event.target.value;
        if (isAuthFieldMaxLengthValid(newName)) {
            setOrganizationName(newName);
        }
    };

    const handleTermsAndConditionsChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setTermsAndConditions(event.target.checked);
    };

    const canSubmit =
        firstName.length > 0 &&
        lastName.length > 0 &&
        isEmailAddressValid(emailAddress) &&
        organizationName.length > 0 &&
        termsAndConditions;

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmailAddress('');
        setOrganizationName('');
        setTermsAndConditions(false);
    };

    const handleClose = async () => {
        if (!isSubmitting) {
            await props.onClose();
            resetForm();
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const onCompletion = async (organization) => {
            setIsSubmitting(false);
            await props.onClose();
            const capitalizedStr = toTitleCase(organization.name);

            const toastMsg = intl.formatMessage(
                {
                    id: FormKeys.ObjectCreated,
                    defaultMessage: 'Organization created',
                },
                { object: capitalizedStr }
            );

            createToast(toastMsg, ToastType.SUCCESS);
            resetForm();
        };

        const newOrganization = await OrganizationsApi.createOrganization(
            organizationToCreate
        );
        if (!_isNil(newOrganization)) {
            onCompletion(newOrganization);
        } else {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogHeader
                title={intl.formatMessage({
                    id: AuthKeys.CreateOrganization,
                    defaultMessage: 'Create Organization',
                })}
                onClose={handleClose}
                isCloseDisabled={isSubmitting}
            />
            <DialogBody>
                <TextInput
                    value={firstName}
                    onChange={handleSetFirstName}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: AuthKeys.FirstName,
                        defaultMessage: 'First Name',
                    })}
                    required
                    autoFocus
                    containerClassName={LayoutStyles.dialogInputMedium}
                    style={{
                        width: '100%',
                    }}
                />
                <TextInput
                    value={lastName}
                    onChange={handleSetLastName}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: AuthKeys.LastName,
                        defaultMessage: 'Last Name',
                    })}
                    required
                    containerClassName={LayoutStyles.dialogInputSmall}
                    style={{
                        width: '100%',
                    }}
                />
                <TextInput
                    value={emailAddress}
                    onChange={handleSetEmailAddress}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: AuthKeys.Email,
                        defaultMessage: 'Email Address',
                    })}
                    required
                    containerClassName={LayoutStyles.dialogInputFull}
                    style={{
                        width: '100%',
                    }}
                />
                <TextInput
                    value={organizationName}
                    onChange={handleSetOrganizationName}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: AuthKeys.OrganizationName,
                        defaultMessage: 'Organization Name',
                    })}
                    required
                    containerClassName={LayoutStyles.dialogInputFull}
                    style={{
                        width: '100%',
                    }}
                />
                <div
                    className={Styles.coTsandCsContainer}
                    style={{
                        gridColumn: '1/-1',
                    }}
                >
                    <Checkbox
                        label={intl.formatMessage({
                            id: AuthKeys.TermsAndConditions,
                            defaultMessage:
                                "I agree to Orbica's Terms & Conditions",
                        })}
                        labelStyle={{ color: `${ColorStyles.darkGreen}` }}
                        onChange={handleTermsAndConditionsChange}
                    />
                </div>
            </DialogBody>
            <DialogFooter
                onCancel={handleClose}
                onSubmit={handleSubmit}
                isSubmitDisabled={!canSubmit}
                isSubmitting={isSubmitting}
                submitText={intl.formatMessage({
                    id: AuthKeys.CreateOrganization,
                    defaultMessage: 'Create Organization',
                })}
                submittingText={intl.formatMessage({
                    id: AuthKeys.Loading,
                    defaultMessage: 'Loading...',
                })}
            />
        </Dialog>
    );
};

export default CreateOrganizationDialog;
