import _isNil from 'lodash/isNil';

/**
 * setTimeout method that can be used in asynchronous methods
 */
export function asyncTimeout(timeoutPeriod: number) {
    return new Promise((resolve) => setTimeout(resolve, timeoutPeriod));
}

/**
 * repeatedly calls an async function until it returns a non-null response
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export async function watchAsync(predicate: Function, timeoutPeriod: number) {
    for (; ; await asyncTimeout(timeoutPeriod)) {
        const result = await predicate();

        if (!_isNil(result)) {
            return result;
        }
    }
}

export function parseVersion(branchName: string): string {
    const parsedVersion = branchName;
    const releaseTag = 'release-';

    return parsedVersion.replace(releaseTag, '');
}
