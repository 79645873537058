import BorderStyles from '@Styles/borders.scss';
import ColorStyles from '@Styles/colors.scss';
import { inputUnstyledClasses } from '@mui/base/InputUnstyled';
import { styled } from '@mui/system';

const StyledInputRoot = styled('div')`
    &.${inputUnstyledClasses.focused}, &:focus,
    &:hover,
    &:focus-visible {
        border: ${BorderStyles.focus};
    }

    &:hover {
        border-color: ${ColorStyles.greyscale60};
    }

    &.MuiInput-adornedStart {
        padding-left: 12px;
    }

    &.MuiInput-adornedEnd {
        padding-right: 12px;
    }
`;

export default StyledInputRoot;
