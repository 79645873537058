import { api } from '@orbica/platform-sdk-dev';
import { IOrgUserDetails, IOrganization } from './Interfaces';

export interface IOrganizationsState {
    organizations: Array<IOrganization>;
    selectedOrganization: IOrganization | null;
    selectedOrganizationUsers: IOrgUserDetails[] | null;
    roles: api.IRole[];
}

export const initialOrganizationsState: IOrganizationsState = {
    organizations: [],
    selectedOrganization: null,
    selectedOrganizationUsers: null,
    roles: [],
};
