import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import { ToastType } from '@Components/Toasts';
import { IOrganization } from '@Data/organizations';
import { IProject } from '@Data/projects';
import { SolutionsApi } from '@Data/solutions';
import {
    AddSolutionType,
    IAddAnalyticsInputs,
    IAddServiceCatalogService,
    IAnalyticsResourceLimitInput,
    IAnalyticsVersionInput,
    ICurrentUserInformation,
} from '@Data/solutions/Interfaces';
import {
    hasuraCatalogIdState,
    knativeCatalogIdState,
    projectHasDatabaseState,
    unusableServiceNamesState,
} from '@Data/solutions/Reducer';
import { IWorkspace } from '@Data/workspaces';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlagsList';
import {
    ENTITY_NAME_MAX_CHARACTERS,
    isEntityNameValid,
} from '@Services/helpers/Entities/EntityHelpers';
import {
    EntityKeys,
    FormKeys,
    ProjectKeys,
    SolutionKeys,
} from '@Services/i18n/keys';
import {
    ICreatePersistentNotification,
    PersistentNotificationState,
    createToast,
} from '@Services/notifications';
import ServiceInformation from '../ServiceInformation/ServiceInformation';
import { AddServicePage, AddServiceWizardOptions } from './Interfaces';
import SelectService from './SelectService';

interface IAddServiceDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedProject: IProject;
    selectedWorkspace: IWorkspace;
    selectedOrganization: IOrganization;
}

export const AddSolutionDialog = (props: IAddServiceDialogProps) => {
    const intl = useIntl();
    const knativeCatalogId = useSelector(knativeCatalogIdState);
    const hasuraCatalogId = useSelector(hasuraCatalogIdState);
    const hasDatabase = useSelector(projectHasDatabaseState);
    const unusableServiceNames = useSelector(unusableServiceNamesState);
    const FLAG_tryAiGisJupyter = useFeatureFlagEnabled(
        FeatureFlags.DisplayAiGisJupyter
    );

    const [currentPage, setCurrentPage] = useState<AddServicePage>(
        AddServicePage.SELECT_SERVICE
    );
    const [selectedService, setSelectedService] =
        useState<IAddServiceCatalogService>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const serviceStr = intl.formatMessage({
        id: EntityKeys.Solution,
        defaultMessage: 'Service',
    });

    const resetWizard = () => {
        setCurrentPage(AddServicePage.SELECT_SERVICE);
        setSelectedService(null);
        setIsSubmitting(false);
    };

    const handleClose = async () => {
        if (!isSubmitting) {
            resetWizard();
            await props.onClose();
        }
    };

    const handleSelectService = (service: IAddServiceCatalogService) => {
        const clonedService = _cloneDeep(service);

        // if adding an analytics service and a datastore has been added, set useDatabase true by default
        if (
            clonedService.settings.addServiceType ===
                AddSolutionType.ANALYTICS &&
            hasDatabase
        ) {
            const inputs = clonedService.settings.inputs as IAddAnalyticsInputs;
            inputs.useDatabase = true;
        }

        setSelectedService(clonedService);

        setCurrentPage(AddServicePage.SERVICE_INFORMATION);
    };

    const handleSettingsChange = (
        value: string | boolean | any | any[],
        setting: string | string[]
    ) => {
        let inputs = selectedService.settings.inputs;
        if (Array.isArray(setting)) {
            setting.forEach((key, i) => {
                inputs = { ...inputs, [key]: value[i] };
            });
        } else {
            inputs = { ...inputs, [setting]: value };
        }
        setSelectedService({
            ...selectedService,
            settings: {
                ...selectedService.settings,
                inputs: inputs,
            },
        });
    };

    const handleAddService = async () => {
        setIsSubmitting(true);

        const persistentNotificationObj: ICreatePersistentNotification = {
            toastId: `${props.selectedProject.id}${
                selectedService.id
            }${Math.random().toString(8)}`,
            state: PersistentNotificationState.PENDING,
        };

        const currentUserInfo: ICurrentUserInformation = {
            organization: {
                name: props.selectedOrganization.name,
                id: props.selectedOrganization.id,
            },
            workspace: {
                name: props.selectedWorkspace.name,
                id: props.selectedWorkspace.id,
            },
            project: {
                name: props.selectedProject.name,
                id: props.selectedProject.id,
            },
        };

        const localizedSolutionName = intl.formatMessage({
            id: selectedService.name,
            defaultMessage: selectedService.name,
        });

        createToast(
            intl.formatMessage(
                {
                    id: SolutionKeys.AddingSolutionNotice,
                    defaultMessage: `Your ${localizedSolutionName} Solution is being added. Please do not close this browser tab.`,
                },
                { solution: localizedSolutionName }
            ),
            ToastType.INFO,
            persistentNotificationObj
        );

        const wasSuccessful = await SolutionsApi.addSolution(
            selectedService,
            currentUserInfo,
            persistentNotificationObj,
            knativeCatalogId,
            hasuraCatalogId,
            FLAG_tryAiGisJupyter
        );

        setIsSubmitting(false);

        if (wasSuccessful) {
            await SolutionsApi.getProjectSolutions(props.selectedProject.id);
            persistentNotificationObj.state =
                PersistentNotificationState.COMPLETED;
            createToast(
                intl.formatMessage(
                    {
                        id: SolutionKeys.AddingSolutionNotice,
                        defaultMessage: `Your ${localizedSolutionName} Solution has been added to your project!`,
                    },
                    { solution: localizedSolutionName }
                ),
                ToastType.SUCCESS,
                persistentNotificationObj
            );
            handleClose();
        }
    };

    const wizardOptions: AddServiceWizardOptions = useMemo(() => {
        return currentPage === AddServicePage.SELECT_SERVICE
            ? {
                  body: <SelectService onSelectService={handleSelectService} />,
                  cancelText: intl.formatMessage({
                      id: FormKeys.Cancel,
                      defaultMessage: 'Cancel',
                  }),
                  onCancel: handleClose,
              }
            : {
                  body: (
                      <ServiceInformation
                          service={selectedService}
                          onSettingsChange={handleSettingsChange}
                          unusableNames={unusableServiceNames}
                      />
                  ),
                  cancelText: intl.formatMessage({
                      id: FormKeys.Back,
                      defaultMessage: 'Back',
                  }),
                  onCancel: resetWizard,
                  submitText: intl.formatMessage({
                      id: ProjectKeys.AddSolution,
                      defaultMessage: 'Add Service',
                  }),
                  onSubmit: handleAddService,
              };
    }, [currentPage, selectedService, hasDatabase]);

    const canSubmit = useMemo(() => {
        if (currentPage === AddServicePage.SELECT_SERVICE) {
            return true;
        } else {
            let output = true;

            if (_isNil(selectedService)) output = false;

            Object.entries(selectedService.settings.inputs).forEach((entry) => {
                const [key, value] = entry;
                if (key === 'customServiceSettings') {
                    try {
                        JSON.parse(value);
                    } catch {
                        output = false;
                    }
                } else if (key === 'resourceLimit') {
                    const resourceLimitValue =
                        value as IAnalyticsResourceLimitInput;
                    if (_isNil(resourceLimitValue.selectedResourceLimit)) {
                        output = false;
                    }
                } else if (key === 'analyticsVersion') {
                    if (FLAG_tryAiGisJupyter) {
                        const analyticsVersionValue =
                            value as IAnalyticsVersionInput;
                        if (_isNil(analyticsVersionValue.selectedVersion)) {
                            output = false;
                        }
                    }
                } else if (key === 'displayName') {
                    if (value.length > ENTITY_NAME_MAX_CHARACTERS) {
                        output = false;
                    }
                } else if (key === 'name') {
                    const nameValidation = isEntityNameValid(
                        value,
                        unusableServiceNames,
                        'project'
                    );
                    if (!nameValidation.isValid) {
                        output = false;
                    }
                } else if (value === '' || _isNil(value)) {
                    output = false;
                }
            });

            return output;
        }
    }, [selectedService]);

    return (
        <Dialog
            keepMounted={false}
            open={props.isOpen}
            onClose={handleClose}
            disableBackdropClick={
                currentPage === AddServicePage.SERVICE_INFORMATION
            }
        >
            <DialogHeader
                title={intl.formatMessage({
                    id: SolutionKeys.AddANewSolution,
                    defaultMessage: 'Add a new service',
                })}
                onClose={handleClose}
                isCloseDisabled={isSubmitting}
            />
            <DialogBody>{wizardOptions.body}</DialogBody>
            <DialogFooter
                cancelText={wizardOptions.cancelText}
                onCancel={wizardOptions.onCancel}
                hideSubmitBtn={currentPage === AddServicePage.SELECT_SERVICE}
                submitText={wizardOptions.submitText}
                submittingText={intl.formatMessage(
                    {
                        id: FormKeys.AddingObject,
                        defaultMessage: 'Adding service',
                    },
                    { object: serviceStr.toLowerCase() }
                )}
                onSubmit={wizardOptions.onSubmit}
                isSubmitting={isSubmitting}
                isSubmitDisabled={!canSubmit}
            />
        </Dialog>
    );
};
