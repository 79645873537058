import { ReactComponent as ChevronDownIcon } from '@Icons/lg/arrow-down-circle.svg';
import { ReactComponent as ChevronUpIcon } from '@Icons/lg/arrow-up-circle.svg';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { IconButton } from '@Components/Buttons';
import { InputSize } from '@Components/Inputs';
import { Select, SelectOption, SelectType } from '@Components/Select';
import {
    ProjectSortType,
    ProjectsApi,
    getSortTypeString,
} from '@Data/projects';
import { SortOrder } from '@Services/helpers/Sorting';
import { SortingKeys } from '@Services/i18n/keys';
import Styles from './Sorting.scss';

interface ISortOrderSelectorProps {
    selectedSortType: ProjectSortType;
    selectedSortOrder: SortOrder;
}

const SortOrderSelector: React.FC<ISortOrderSelectorProps> = ({
    selectedSortType,
    selectedSortOrder,
}) => {
    const intl = useIntl();

    const handleSortTypeChange = (value: ProjectSortType) => {
        ProjectsApi.setProjectSortType(value);
    };

    const handleSortOrderChange = () => {
        ProjectsApi.toggleSortOrder();
    };

    const icon = useMemo(() => {
        return selectedSortOrder === SortOrder.ASCENDING ? (
            <ChevronUpIcon />
        ) : (
            <ChevronDownIcon />
        );
    }, [selectedSortOrder]);

    const tooltip = useMemo(() => {
        return selectedSortOrder === SortOrder.ASCENDING
            ? intl.formatMessage({
                  id: SortingKeys.Ascending,
                  defaultMessage: 'Ascending sort order',
              })
            : intl.formatMessage({
                  id: SortingKeys.Descending,
                  defaultMessage: 'Descending sort order',
              });
    }, [selectedSortOrder]);

    return (
        <div className={Styles.sortOrderContainer}>
            <Select
                id="projectSortOrderSelect"
                size={InputSize.MEDIUM}
                selectType={SelectType.SECONDARY}
                value={getSortTypeString(selectedSortType)}
                style={{ minWidth: '150px' }}
                onChange={(e, v) => handleSortTypeChange(v)}
            >
                <SelectOption value={ProjectSortType.CREATED}>
                    {getSortTypeString(ProjectSortType.CREATED)}
                </SelectOption>
                <SelectOption value={ProjectSortType.UPDATED}>
                    {getSortTypeString(ProjectSortType.UPDATED)}
                </SelectOption>
                <SelectOption value={ProjectSortType.NAME}>
                    {getSortTypeString(ProjectSortType.NAME)}
                </SelectOption>
            </Select>

            <IconButton
                onClick={handleSortOrderChange}
                icon={icon}
                tooltip={tooltip}
            />
        </div>
    );
};

export default SortOrderSelector;
