import { HeaderType } from '@Components/Header/Interfaces';
import Routes from './Routes';
import { View } from './Views';

export function tryGetCurrentView(headerType: HeaderType): View {
    const currentLocation = window.location.pathname;

    switch (headerType) {
        case HeaderType.DEFAULT:
            return View.WORKSPACES;
        case HeaderType.PROJECT: {
            const projLocation = currentLocation.split('/').splice(-1)[0];
            switch (projLocation) {
                case Routes.project.overview.href:
                    return View.PROJECT_OVERVIEW;
                case Routes.project.data.href:
                    return View.PROJECT_DATA;
            }
            break;
        }
        case HeaderType.SETTINGS:
            return View.ORGANIZATION_SETTINGS;
        default:
            return null;
    }
}
