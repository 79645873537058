import { api } from '@orbica/platform-sdk-dev';
import store from '@Services/redux/reduxStore';
import { OrbicaSdkClient } from '@Services/sdk/OrbicaSdkClient';
import { handleFailedSdkResponse } from '@Services/sdk/helpers';
import { ClustersActions } from './Reducer';

export const ClustersApi = {
    getClusters: async () => {
        // const devEnvs = ['dev', 'local'];
        // console.log(process.env.ENVIRONMENT);
        // let response;
        // if (devEnvs.includes(process.env.ENVIRONMENT)) {
        //     response = {
        //         status: 'success',
        //         data: [
        //             {
        //                 name: 'Sydney',
        //                 id: '82f47b54-7501-4749-8a47-2fde42d3d648',
        //             },
        //         ],
        //     };
        // } else {
        // const clustersApi = await OrbicaSdkClient.instance.getClustersApi();
        // response = await clustersApi.getClusters();
        const response = {
            status: 'success',
            data: [
                {
                    name: 'Sydney',
                    id: 'f3ceffc7-d826-4694-963c-e38c5713ed11',
                },
                {
                    name: 'Frankfurt',
                    id: 'f7e61d94-13e7-4a75-a98b-0e1f090a087a',
                },
            ],
        };
        // }

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        } else {
            const data = response.data as api.ICluster[];
            store.dispatch(ClustersActions.updateClusters(data));
            return data;
        }
    },
};
