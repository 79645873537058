import GridStyles from '@Styles/grid.scss';
import cx from 'classnames';
import React from 'react';
import Styles from './Page.scss';

export interface IMainContainerProps {
    children?: React.ReactNode;
    hasStaticDrawer?: boolean;
    containerClassName?: string;
    gridClassName?: string;
    alignStart?: boolean;
    fullWidth?: boolean;
    id?: string;
}

export const MainContainer: React.FC<IMainContainerProps> = (props) => {
    let containerCss, gridCss;
    if (props.hasStaticDrawer) {
        containerCss = Styles.mainContainerWithDrawerContainer;
        gridCss = props.fullWidth
            ? Styles.mainContainerWithDrawerFullWidth
            : Styles.mainContainerWithDrawer;
    } else {
        containerCss = Styles.mainContainerContainer;
        gridCss = Styles.mainContainer;
    }

    return (
        <div
            className={cx(containerCss, props.containerClassName, {
                [`${GridStyles.alignStart}`]: props.alignStart,
            })}
            data-cy={props.id}
        >
            <div className={cx(gridCss, props.gridClassName)}>
                {props.children}
            </div>
        </div>
    );
};
