import LayoutStyles from '@Styles/layout.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { InputSize, TextInput } from '@Components/Inputs';
import { SolutionKeys } from '@Services/i18n/keys';

interface ICustomServiceSettingsTextAreaProps {
    onChange: (value: string) => void;
    value: string;
}

export const CustomServiceSettingsTextArea = (
    props: ICustomServiceSettingsTextAreaProps
) => {
    const intl = useIntl();

    let isValidJson = true;

    try {
        JSON.parse(props.value);
    } catch {
        isValidJson = false;
    }

    return (
        <TextInput
            size={InputSize.SMALL}
            value={props.value}
            label={intl.formatMessage({
                id: SolutionKeys.ManageSolutionCustomSettings,
                defaultMessage: 'Custom Settings',
            })}
            containerClassName={LayoutStyles.dialogInputFull}
            required
            multiline
            rows={10}
            onChange={(e) => props.onChange(e.target.value)}
            useMonospace
            error={!isValidJson}
            helperText={intl.formatMessage({
                id: SolutionKeys.InvalidJson,
                defaultMessage: 'Invalid JSON',
            })}
        />
    );
};
