import ColorsStyles from '@Styles/colors.scss';
import { Menu } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dialog, DialogFooter, DialogHeader } from '@Components/Dialog';
import { MenuItem } from '@Components/Menu';
import { EditEntityDisplayNameDialog } from '@Components/Settings/EditEntityDisplayNameDialog';
import { canCurrentUserEditProjectState } from '@Data/auth';
import { ISolution } from '@Data/solutions';
import { EntityKeys, FormKeys } from '@Services/i18n/keys';

export interface IServiceCardMenuProps {
    onDelete: () => void;
    onClose: () => void;
    onEditDisplayName: (newName: string) => void;
    canEditDisplayName: boolean;
    anchorEl?: HTMLElement;
    selectedService?: ISolution;
}

export const ServiceCardMenu = (props: IServiceCardMenuProps) => {
    const intl = useIntl();
    const canUserEditService = useSelector(canCurrentUserEditProjectState);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleDelete = async () => {
        setIsSubmitting(true);
        await props.onDelete();
        setIsDeleteDialogOpen(false);
        setIsSubmitting(false);
    };

    const openEditDialog = () => {
        setIsEditDialogOpen(true);
        props.onClose();
    };

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
        props.onClose();
    };

    const solutionStr = intl.formatMessage({
        id: EntityKeys.Solution,
        defaultMessage: 'solution',
    });

    return (
        <React.Fragment>
            {canUserEditService && (
                <Menu
                    open={!_isNil(props.anchorEl)}
                    anchorEl={props.anchorEl}
                    onClose={props.onClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {props.canEditDisplayName && (
                        <MenuItem onClick={openEditDialog}>
                            {intl.formatMessage(
                                {
                                    id: FormKeys.EditObject,
                                    defaultMessage: 'Edit solution',
                                },
                                { object: solutionStr }
                            )}
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={openDeleteDialog}
                        color={ColorsStyles.red}
                    >
                        {intl.formatMessage(
                            {
                                id: FormKeys.DeleteObject,
                                defaultMessage: 'Delete solution',
                            },
                            { object: solutionStr }
                        )}
                    </MenuItem>
                </Menu>
            )}
            <Dialog open={isDeleteDialogOpen}>
                <DialogHeader
                    onClose={() => setIsDeleteDialogOpen(false)}
                    title={intl.formatMessage(
                        {
                            id: FormKeys.ConfirmDelete,
                            defaultMessage: `Are you sure you want to delete ${props.selectedService?.display_name}?`,
                        },
                        {
                            objectName: !_isNil(props.selectedService)
                                ? props.selectedService.display_name
                                : '',
                        }
                    )}
                />
                <DialogFooter
                    onCancel={() => setIsDeleteDialogOpen(false)}
                    submitText={intl.formatMessage({
                        id: FormKeys.Delete,
                        defaultMessage: 'Delete',
                    })}
                    onSubmit={handleDelete}
                    isSubmitting={isSubmitting}
                    submittingText={intl.formatMessage(
                        {
                            id: FormKeys.DeletingObject,
                            defaultMessage: 'Deleting solution',
                        },
                        { object: solutionStr }
                    )}
                />
            </Dialog>
            <EditEntityDisplayNameDialog
                isOpen={isEditDialogOpen}
                onClose={() => setIsEditDialogOpen(false)}
                onSubmitDisplayName={props.onEditDisplayName}
                entityName={props.selectedService?.name}
                entityDisplayName={props.selectedService?.display_name}
                entityType={solutionStr}
            />
        </React.Fragment>
    );
};
