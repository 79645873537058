import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { IMenuTabProps } from './Interfaces';
import { Tab } from './Tab';

const MenuComponent = styled(Menu)`
    margin-top: -4px;

    & li {
        min-width: 225px;
    }
`;

export const MenuTab: React.FC<IMenuTabProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { menuItems, ...tabProps } = props;

    return (
        <React.Fragment>
            <Tab onClick={handleClick} displayDropdownIcon {...tabProps} />
            <MenuComponent
                open={isOpen}
                anchorEl={document.getElementById(props.id)}
                onClose={handleClose}
            >
                {menuItems}
            </MenuComponent>
        </React.Fragment>
    );
};
