import LayoutStyles from '@Styles/layout.scss';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React from 'react';
import { InputSize } from '@Components/Inputs';
import { LabelWithIconTooltip } from '@Components/Label';
import { Select, SelectOption, SelectType } from '@Components/Select';
import { IAnalyticsVersion } from '@Data/solutions/Interfaces';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlagsList';
import Styles from './ServiceInformation.scss';

interface IAnalyticsVersionSelectorProps {
    options: Array<IAnalyticsVersion>;
    selectedVersion: IAnalyticsVersion;
    onChange: (option: IAnalyticsVersion) => void;
}

export const AnalyticsVersionSelector = (
    props: IAnalyticsVersionSelectorProps
) => {
    const FLAG_tryAiGisJupyter = useFeatureFlagEnabled(
        FeatureFlags.DisplayAiGisJupyter
    );

    if (!FLAG_tryAiGisJupyter) {
        return null;
    }

    return (
        <div className={LayoutStyles.dialogInputMedium}>
            <LabelWithIconTooltip
                label={'Analytics Version'}
                tooltipTitle={
                    <div className={Styles.resourceLimitsTooltipContainer}>
                        <span>
                            The <b>Geospatial</b> version will suit Data
                            Scientists, Environmental Scientists, or anyone who
                            wants to analyse or publish GIS data.
                        </span>
                        <span>
                            The <b>Artificial Intelligence</b> option includes
                            open-source AI frameworks, pre-trained AI models,
                            and a comprehensive stack of standard AI libraries.
                        </span>
                    </div>
                }
                required
            />
            <Select
                selectType={SelectType.PRIMARY}
                size={InputSize.LARGE}
                onChange={(e, v) => props.onChange(v)}
                value={
                    props.selectedVersion
                        ? props.selectedVersion.label
                        : 'Select Version'
                }
            >
                {props.options.map((resource) => {
                    return (
                        <SelectOption key={resource.label} value={resource}>
                            {resource.label}
                        </SelectOption>
                    );
                })}
            </Select>
        </div>
    );
};
