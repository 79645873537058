import React from 'react';
import Styles from './MenuDrawer.scss';

export const MenuDivider = () => {
    return (
        <div className={Styles.menuDividerContainer}>
            <div className={Styles.menuDivider} />
        </div>
    );
};
