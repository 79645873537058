import { ReactComponent as NotificationIcon } from '@Icons/lg/bell-on.svg';
import ColorsStyles from '@Styles/colors.scss';
import { BadgeUnstyled, badgeUnstyledClasses } from '@mui/base';
import { styled } from '@mui/system';
import React from 'react';
import { IconButton } from '@Components/Buttons';
import Styles from './Notifications.scss';

const StyledBadge = styled(BadgeUnstyled)`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    line-height: 1;

    & .${badgeUnstyledClasses.badge} {
        z-index: auto;
        position: absolute;
        top: 0;
        right: 0;
        min-width: 12px;
        height: 12px;
        padding: 0 6px;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        white-space: nowrap;
        text-align: center;
        border-radius: 12px;
        background: ${ColorsStyles.matcha};
        transform: translate(25%, -25%);
        transform-origin: 100% 0;
    }

    & .${badgeUnstyledClasses.invisible} {
        opacity: 0;
    }
`;

export interface INotificationsIconButtonProps {
    hasNotifications: boolean;
    onClick: () => void;
}

export const NotificationsIconButton = (
    props: INotificationsIconButtonProps
) => {
    return (
        <StyledBadge badgeContent={' '} invisible={!props.hasNotifications}>
            <IconButton
                className={Styles.iconBtn}
                icon={<NotificationIcon />}
                onClick={props.onClick}
            />
        </StyledBadge>
    );
};
