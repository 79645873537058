import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IProject, ProjectsApi } from '@Data/projects';
import { IWorkspace } from '@Data/workspaces';
import { WorkspaceKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';
import { WorkspaceButton } from './WorkspaceButton';
import { WorkspaceProjectButton } from './WorkspaceProjectButton';
import { WorkspaceProjectButtonSkeleton } from './WorkspaceProjectButtonSkeleton';
import Styles from './Workspaces.scss';

export interface IWorkspaceAccordionProps {
    workspace: IWorkspace;
    selectedOrganizationId: string;
    selectedProjectId: string;
    isSelected: boolean;
    onSelectProject?: () => void;
    onClick: () => void;
    expanded?: boolean;
    className?: string;
}

export const WorkspaceAccordion = (props: IWorkspaceAccordionProps) => {
    const [projects, setProjects] = useState<Array<IProject>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const intl = useIntl();

    useEffect(() => {
        const loadProjectsEffect = async () => {
            setIsLoading(true);
            const projects = await ProjectsApi.loadProjects(
                props.workspace.id,
                null
            );
            setProjects(projects);
            setIsLoading(false);
        };

        if (
            props.expanded &&
            props.workspace.projectCount > 0 &&
            projects.length !== props.workspace.projectCount
        ) {
            loadProjectsEffect();
        }
    }, [props.expanded, projects, props.workspace.projectCount]);

    const createSkeletons = (projectCount: number) => {
        const output: Array<ReactElement> = [];
        for (let i = 0; i < projectCount; i++) {
            output.push(<WorkspaceProjectButtonSkeleton key={`wpbs${i}`} />);
        }
        return output;
    };

    let projectsHtml;

    if (isLoading) {
        projectsHtml = createSkeletons(props.workspace.projectCount);
    } else if (projects.length > 0) {
        projectsHtml = projects.map((p) => {
            const href = Routes.project.overview.getUrl(
                props.selectedOrganizationId,
                props.workspace.id,
                p.id
            );
            return (
                <WorkspaceProjectButton
                    key={`wpb${p.id}`}
                    project={p}
                    isSelected={
                        props.isSelected && props.selectedProjectId === p.id
                    }
                    href={href}
                    circleColor={props.workspace.color}
                    onSelect={props.onSelectProject}
                />
            );
        });
    } else {
        projectsHtml = (
            <span className={Styles.noProjects}>
                {intl.formatMessage({
                    id: WorkspaceKeys.NoProjectsAdded,
                    defaultMessage: 'No projects have been added',
                })}
            </span>
        );
    }

    return (
        <Accordion
            expanded={props.expanded}
            onClick={props.onClick}
            className={props.className}
        >
            <AccordionSummary>
                <WorkspaceButton
                    workspace={props.workspace}
                    isSelected={false}
                    displayTail={
                        !isLoading && props.expanded && projects.length > 0
                    }
                />
            </AccordionSummary>
            <AccordionDetails>
                <div className={Styles.workspaceProjectButtonsContainer}>
                    {projectsHtml}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
