import LayoutStyles from '@Styles/layout.scss';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledDiv = styled('div')(({ style }) => ({
    '*': {
        transition: `all 200ms ease-in-out`,
    },

    display: 'flex',
    alignItems: 'center',
    height: style?.height ? style.height : LayoutStyles.headerHeight,
    minWidth: style?.minWidth ? style.minWidth : '200px',

    '.hidden': {
        opacity: 0,
        position: 'absolute',
    },

    '.visible': {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
    },

    '&:hover, &:focus': {
        '.hidden': {
            opacity: 1,
            transform: 'translateY(-60%)',
            zIndex: 1,
        },

        '.visible': {
            transform: 'translateY(35%)',
        },
    },
}));

/**
 * @noInheritDoc
 */
export interface IRevealingTitleProps extends React.HTMLProps<HTMLDivElement> {
    hiddenContent: React.ReactElement;
    visibleContent: React.ReactElement;
}

/**
 * A text component that animates downwards to reveal hidden text.
 */
export const RevealingTitle = (props: IRevealingTitleProps) => {
    const { hiddenContent, visibleContent, as, ...divProps } = props;

    return (
        <StyledDiv {...divProps}>
            <div className="hidden">{hiddenContent}</div>
            <div className={'visible'}>{visibleContent}</div>
        </StyledDiv>
    );
};
