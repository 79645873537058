import { ReactComponent as ChevronDown } from '@Icons/lg/chevron-down.svg';
import ColorVariables from '@Styles/colors.scss';
import { TabUnstyled, tabUnstyledClasses } from '@mui/base';
import { styled } from '@mui/material/styles';
import React from 'react';
import { getRoleProps } from '@Components/MuiRouterLink';
import { ITabProps } from './Interfaces';
import Styles from './Tabs.scss';

const TabIcon = styled('i')(({ theme }) => ({
    marginRight: '10px',
    svg: {
        transition: theme.transitions.create(['fill'], {
            duration: theme.transitions.duration.standard,
        }),
        fill: '#fff',
    },
}));

const TabChevron = styled('i')(({ theme }) => ({
    svg: {
        transition: theme.transitions.create(['stroke'], {
            duration: theme.transitions.duration.standard,
        }),
        stroke: '#fff',
    },
    marginLeft: '10px',
}));

const StyledTab = styled(TabUnstyled)(({ theme }) => ({
    textDecoration: 'none',

    transition: theme.transitions.create(['border-bottom', 'color'], {
        duration: theme.transitions.duration.standard,
    }),

    [`&:hover, &:focus, &.${tabUnstyledClasses.selected}`]: {
        borderBottom: `4px solid ${ColorVariables.avocado}`,
        color: `${ColorVariables.avocado}`,
        [`${TabIcon}`]: {
            svg: {
                path: {
                    fill: ColorVariables.avocado,
                },
            },
        },
        [`${TabChevron}`]: {
            svg: {
                path: {
                    stroke: ColorVariables.avocado,
                },
            },
        },
    },
}));

export const Tab: React.FC<ITabProps> = (props) => {
    const { href, onClick, relative, displayDropdownIcon, icon, ...tabProps } =
        props;

    const roleProps = getRoleProps(onClick, href, relative);

    return (
        <div id={props.id} key={props.id}>
            <StyledTab
                className={Styles.tab}
                value={props.value}
                {...roleProps}
                {...tabProps}
            >
                {icon && (
                    <TabIcon
                        aria-label={`${props.label}-icon`}
                        role="icon"
                        className={Styles.tabIcon}
                    >
                        {props.icon}
                    </TabIcon>
                )}
                <span>{props.label}</span>

                {displayDropdownIcon && (
                    <TabChevron
                        aria-label={`${props.label}-chevron`}
                        role="icon"
                        className={Styles.tabIcon}
                    >
                        <ChevronDown />
                    </TabChevron>
                )}
            </StyledTab>
        </div>
    );
};
