import { ReactComponent as EditIcon } from '@Icons/lg/edit-2.svg';
import { ReactComponent as TrashIcon } from '@Icons/lg/trash-2.svg';
import TypographyStyles from '@Styles/typography.scss';
import { createColumnHelper } from '@tanstack/react-table';
import _isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { IconButton } from '@Components/Buttons';
import { Table, TableSkeleton } from '@Components/Table';
import TableStyles from '@Components/Table/Table.scss';
import { SettingsKeys } from '@Services/i18n/keys';
import { ISettingsUser, getFullName } from './Interfaces';

interface IUserRoleSettingsTableProps {
    users: ISettingsUser[];
    isLoading: boolean;
    onEditUserRole: (user: ISettingsUser) => void;
    onRemoveUser?: (user: ISettingsUser) => void;
    entityName: string;
}

export const UserRoleSettingsTable = (props: IUserRoleSettingsTableProps) => {
    const { users, isLoading, onEditUserRole, onRemoveUser } = props;
    const intl = useIntl();

    const columnHelper = createColumnHelper<ISettingsUser>();
    const columns = [
        columnHelper.accessor('email', {
            cell: (info) => info.getValue(),
            header: () =>
                intl.formatMessage({
                    id: SettingsKeys.Email,
                    defaultMessage: 'Email',
                }),
        }),
        columnHelper.accessor('id', {
            cell: (info) => getFullName(info.row.original),
            header: () =>
                intl.formatMessage({
                    id: SettingsKeys.Name,
                    defaultMessage: 'Name',
                }),
        }),
        columnHelper.accessor('role', {
            cell: (info) => {
                const settingsUser = info.row.original;
                return (
                    <div className={TableStyles.editCell}>
                        <div data-cy="user-role">{settingsUser.role.name}</div>
                        <div className={TableStyles.iconsContainer}>
                            <IconButton
                                icon={<EditIcon />}
                                className={TableStyles.icons}
                                data-cy="edit-user"
                                onClick={() => onEditUserRole(settingsUser)}
                                tooltip={intl.formatMessage({
                                    id: SettingsKeys.UpdateRole,
                                    defaultMessage: 'Update Role',
                                })}
                                as="div"
                            />
                            {!_isNil(onRemoveUser) && (
                                <IconButton
                                    icon={<TrashIcon />}
                                    className={TableStyles.icons}
                                    onClick={() => onRemoveUser(settingsUser)}
                                    data-cy="delete-user"
                                    tooltip={intl.formatMessage({
                                        id: SettingsKeys.RemoveUser,
                                        defaultMessage: 'Remove User',
                                    })}
                                    as="div"
                                />
                            )}
                        </div>
                    </div>
                );
            },
            header: () =>
                intl.formatMessage({
                    id: SettingsKeys.Role,
                    defaultMessage: 'Role',
                }),
        }),
    ];

    let tableHtml;
    if (isLoading) {
        tableHtml = <TableSkeleton columns={3} />;
    } else if (users.length > 0) {
        tableHtml = (
            <Table
                data={users}
                columns={columns}
                id="organisation-settings-table"
            />
        );
    } else {
        tableHtml = (
            <div>
                <span className={TypographyStyles.bodyMedium}>
                    {intl.formatMessage(
                        {
                            id: SettingsKeys.NoUsersAddedToYourEntity,
                            defaultMessage: `No users have been added to your ${props.entityName}`,
                        },
                        { entityName: props.entityName }
                    )}
                </span>
            </div>
        );
    }

    return <div>{tableHtml}</div>;
};
