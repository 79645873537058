import { Skeleton } from '@mui/material';
import React from 'react';

const BtnSkeleton = () => {
    return (
        <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
                width: '165.875px',
                height: '48px',
                borderRadius: '6px',
            }}
        />
    );
};

export default BtnSkeleton;
