import cx from 'classnames';
import React from 'react';
import Styles from './StaticDrawer.scss';

export interface IStaticDrawerProps {
    children?: React.ReactNode;
    footer?: React.ReactNode;
    /**
     * set left and right padding to 0
     */
    fullWidth?: boolean;
    gridClassName?: string;
}

/**
 * Static div component, intended to be used as a non-collapsible side menu.
 * See Src/views/WorkspacesView
 */
export const StaticDrawer = (props: IStaticDrawerProps) => {
    const gridCss = props.fullWidth
        ? Styles.fullWidthDrawerGrid
        : Styles.drawerGrid;

    return (
        <div className={Styles.drawer} data-cy="static-drawer">
            <div className={cx(gridCss, props.gridClassName)}>
                {props.children}
                {props.footer}
            </div>
        </div>
    );
};

export default StaticDrawer;
