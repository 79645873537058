import { api } from '@orbica/platform-sdk-dev';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { selectedOrganizationState } from '@Data/organizations';
import { selectedProjectState } from '@Data/projects';
import { selectedWorkspaceState } from '@Data/workspaces';
import { RootState } from '@Services/redux/reduxStore';
import {
    getCanCurrentUserEditSelectedOrg,
    getCanCurrentUserEditSelectedProject,
    getCanCurrentUserEditSelectedWorkspace,
} from './Helpers';
import { IAuthState, initialAuthState } from './State';

const AuthSlice = createSlice({
    name: 'Auth',
    initialState: initialAuthState,
    reducers: {
        updateUser(state: IAuthState, action: PayloadAction<boolean>) {
            state.hasUser = action.payload;
        },
        updateCurrentUserRoles(
            state: IAuthState,
            action: PayloadAction<api.IUserRole>
        ) {
            state.organizationRoles = action.payload.user_organization_roles;
            state.workspaceRoles = action.payload.user_workspace_roles;
            state.projectRoles = action.payload.user_project_roles;
        },
    },
});

export const AuthActions = AuthSlice.actions;

export const hasUserState = (state: RootState) => state.auth.hasUser;
export const userOrganizationRolesState = (state: RootState) =>
    state.auth.organizationRoles;
export const userWorkspaceRolesState = (state: RootState) =>
    state.auth.workspaceRoles;
export const userProjectRolesState = (state: RootState) =>
    state.auth.projectRoles;

export const haveRolesLoadedState = createSelector(
    userOrganizationRolesState,
    (orgRoles) => {
        return Array.isArray(orgRoles);
    }
);

export const canCurrentUserEditOrgState = createSelector(
    userOrganizationRolesState,
    selectedOrganizationState,
    (orgRoles, selectedOrganization) => {
        return getCanCurrentUserEditSelectedOrg(orgRoles, selectedOrganization);
    }
);

export const canCurrentUserEditWorkspaceState = createSelector(
    userOrganizationRolesState,
    userWorkspaceRolesState,
    selectedOrganizationState,
    selectedWorkspaceState,
    (orgRoles, workspaceRoles, selectedOrg, selectedWorkspace) => {
        return getCanCurrentUserEditSelectedWorkspace(
            orgRoles,
            workspaceRoles,
            selectedOrg,
            selectedWorkspace
        );
    }
);

export const canCurrentUserEditProjectState = createSelector(
    userOrganizationRolesState,
    userWorkspaceRolesState,
    userProjectRolesState,
    selectedOrganizationState,
    selectedWorkspaceState,
    selectedProjectState,
    (
        orgRoles,
        workspaceRoles,
        projectRoles,
        selectedOrg,
        selectedWorkspace,
        selectedProject
    ) => {
        return getCanCurrentUserEditSelectedProject(
            orgRoles,
            workspaceRoles,
            projectRoles,
            selectedOrg,
            selectedWorkspace,
            selectedProject
        );
    }
);

export default AuthSlice.reducer;
