import { ReactComponent as CloseIcon } from '@Icons/lg/x.svg';
import ColorStyles from '@Styles/colors.scss';
import { Drawer, DrawerProps } from '@mui/material';
import React from 'react';
import { IconButton } from '@Components/Buttons';
import { LogoIcon } from '@Components/Header/LeftPanels';
import Styles from './MenuDrawer.scss';

/**
 * @noInheritDoc
 */
export interface IFixedWidthMenuDrawerProps extends DrawerProps {
    onClose: () => void;
    isCloseDisabled?: boolean;
    children: React.ReactNode;
}

/**
 * Fixed width menu drawer (as opposed to a menu drawer that can be dragged to be wider).
 * Intended to be used with a header breadcrumbs component to give a user more options.
 * See src/components/Header/LeftPanels/MenuAndBreadcrumbs
 */
export const FixedWidthMenuDrawer = (props: IFixedWidthMenuDrawerProps) => {
    return (
        <Drawer
            variant={'temporary'}
            PaperProps={{ className: Styles.menuDrawerPaper }}
            {...props}
        >
            <div className={Styles.gridContainer}>
                <div className={Styles.header}>
                    <div className={Styles.headerContent}>
                        <LogoIcon href="/" />
                        <IconButton
                            icon={<CloseIcon />}
                            stroke={ColorStyles.greyscale40}
                            onClick={props.onClose}
                            disabled={props.isCloseDisabled}
                        />
                    </div>
                </div>
                <div className={Styles.childrenContainer}>{props.children}</div>
            </div>
        </Drawer>
    );
};
