import _isNil from 'lodash/isNil';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CreateServiceCard,
    ServiceCard,
    ServiceCardMenu,
    ServiceCardSkeleton,
} from '@Components/Cards/ServiceCards';
import { canCurrentUserEditProjectState } from '@Data/auth';
import { ISolution, SolutionsApi } from '@Data/solutions';
import { canEditSolutionDisplayName } from '@Data/solutions/Helpers';
import { activeProjectSolutionsState } from '@Data/solutions/Reducer';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlagsList';
import { asyncTimeout } from '@Services/helpers/Misc';
import { ProjectKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';
import ProjectDataDisplay from '../ProjectDataDisplay';

interface IProjectServiceListProps {
    projectId: string;
    onAddService: () => void;
    onDeleteSolution: (solution: ISolution) => void;
}

export const ProjectSolutionsList = (props: IProjectServiceListProps) => {
    const params = useParams();

    const canCurrentUserEditProject = useSelector(
        canCurrentUserEditProjectState
    );
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [areServicesLoading, setAreServicesLoading] =
        useState<boolean>(false);
    const [menuSolution, setMenuSolution] = useState<ISolution>(null);
    const activeProjectServices = useSelector(activeProjectSolutionsState);
    const intl = useIntl();
    const FLAG_tryDisplaySwagger = useFeatureFlagEnabled(
        FeatureFlags.RenderPublisherSwagger
    );

    const getProjectSolutions = async () => {
        setAreServicesLoading(true);
        await SolutionsApi.getProjectSolutions(
            props.projectId,
            FLAG_tryDisplaySwagger
        );
        setAreServicesLoading(false);
    };
    useEffect(() => {
        if (props.projectId) {
            getProjectSolutions();
        }
    }, [props.projectId, FLAG_tryDisplaySwagger]);

    const handleCloseSolutionMenu = () => {
        setAnchorEl(null);
    };

    const handleDeleteSolution = async () => {
        await props.onDeleteSolution(menuSolution);
    };

    const handleOpenSolutionMenu = (
        anchorElement: HTMLElement,
        solution: ISolution
    ) => {
        setMenuSolution(solution);
        setAnchorEl(anchorElement);
    };

    const handleEditSolutionDisplayName = async (newName: string) => {
        await SolutionsApi.updateSolutionDisplayName(newName, menuSolution);
        await getProjectSolutions();
    };

    const canEditMenuSolution =
        !_isNil(menuSolution) && canEditSolutionDisplayName(menuSolution);

    const servicesHtml = areServicesLoading
        ? [
              <ServiceCardSkeleton key="sks1" />,
              <ServiceCardSkeleton key="sks2" />,
              <ServiceCardSkeleton key="sks3" />,
          ]
        : activeProjectServices.map((projectService) => {
              const url = Routes.temporaryProjectService.getUrl(
                  params.organizationId,
                  params.workspaceId,
                  params.projectId,
                  projectService.id
              );
              return (
                  <ServiceCard
                      name={projectService.display_name}
                      logo={projectService.logo}
                      key={`serviceCard${projectService.id}`}
                      status={projectService.status}
                      serviceId={projectService.id}
                      href={projectService.status === 'up' ? url : null}
                      target="_blank"
                      rel="noopener noreferrer"
                      onOpenMenu={(anchorEl) =>
                          handleOpenSolutionMenu(anchorEl, projectService)
                      }
                  />
              );
          });

    return (
        <ProjectDataDisplay
            title={intl.formatMessage({
                id: ProjectKeys.Solutions,
                defaultMessage: 'Services',
            })}
            isLoading={areServicesLoading}
        >
            <>
                {!areServicesLoading && canCurrentUserEditProject && (
                    <CreateServiceCard
                        onClick={props.onAddService}
                        key="createServicesCard"
                    />
                )}
                {servicesHtml}
            </>
            <ServiceCardMenu
                anchorEl={anchorEl}
                selectedService={menuSolution}
                onClose={handleCloseSolutionMenu}
                onEditDisplayName={handleEditSolutionDisplayName}
                canEditDisplayName={canEditMenuSolution}
                onDelete={handleDeleteSolution}
            />
        </ProjectDataDisplay>
    );
};
