import LayoutStyles from '@Styles/layout.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { canCurrentUserEditOrgState } from '@Data/auth';
import { OrganizationKeys } from '@Services/i18n/keys';
import Styles from './NoEntities.scss';

export const NoWorkspaces = () => {
    const intl = useIntl();
    const canCurrentUserEditOrg = useSelector(canCurrentUserEditOrgState);

    const errorMsg = canCurrentUserEditOrg
        ? intl.formatMessage({
              id: OrganizationKeys.CreateAWorkspace,
              defaultMessage: 'Please create a workspace',
          })
        : intl.formatMessage({
              id: OrganizationKeys.NoWorkspacesUser,
              defaultMessage:
                  'You have not been added to a Workspace within this Organization, please contact your Organization Admin',
          });

    return (
        <div
            className={Styles.noEntitiesContainer}
            style={{ marginTop: LayoutStyles.standardMargin }}
        >
            <div className={Styles.createWorkspaceContainer}>
                <span className={Styles.createWorkspace}>{errorMsg}</span>
            </div>
        </div>
    );
};
