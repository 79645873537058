import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Table, TableSkeleton } from '@Components/Table';
import { IProject } from '@Data/projects';
import { ProjectKeys, WorkspaceKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';
import { IProjectsViewProps } from './Interfaces';
import Styles from './ProjectsDisplay.scss';

export const TABLE_ID_NAME = 'projecTable';
const ProjectsListView = (props: IProjectsViewProps) => {
    const intl = useIntl();
    const columnHelper = createColumnHelper<IProject>();
    const columns = [
        columnHelper.accessor('display_name', {
            cell: (info) => info.getValue(),
            header: () =>
                intl.formatMessage({
                    id: ProjectKeys.Name,
                    defaultMessage: 'Name',
                }),
        }),
        // columnHelper.accessor('tags', {
        //     cell: info => info.getValue()?.join(),
        //     header: () => intl.formatMessage({ id: ProjectKeys.Tags, defaultMessage: "Tags" })
        // }),
        columnHelper.accessor('updated_at', {
            cell: (info) => {
                const date = info.getValue();
                return (
                    <span data-date={DateTime.fromISO(date).toString()}>
                        {DateTime.fromISO(date).toFormat('dd LLL yyyy')}
                    </span>
                );
            },
            header: () =>
                intl.formatMessage({
                    id: ProjectKeys.Updated,
                    defaultMessage: 'Updated',
                }),
        }),
        columnHelper.accessor('created_at', {
            cell: (info) => {
                const date = info.getValue();
                return (
                    <span data-date={DateTime.fromISO(date).toString()}>
                        {DateTime.fromISO(date).toFormat('dd LLL yyyy')}
                    </span>
                );
            },
            header: () =>
                intl.formatMessage({
                    id: ProjectKeys.Created,
                    defaultMessage: 'Created',
                }),
        }),
        columnHelper.accessor('description', {
            cell: (info) => info.getValue(),
            header: () =>
                intl.formatMessage({
                    id: ProjectKeys.Description,
                    defaultMessage: 'Description',
                }),
        }),
    ];

    let tableHtml;
    if (props.isLoading) {
        tableHtml = <TableSkeleton columns={columns} />;
    } else {
        tableHtml =
            props.projects.length > 0 ? (
                <Table
                    data={props.projects}
                    columns={columns}
                    id={TABLE_ID_NAME}
                    getRowHref={(project: IProject) => {
                        return Routes.project.overview.getUrl(
                            props.selectedOrganizationId,
                            props.selectedWorkspaceId,
                            project.id
                        );
                    }}
                />
            ) : (
                <span>
                    {intl.formatMessage({
                        id: WorkspaceKeys.NoProjectsAdded,
                        defaultMessage: 'No projects have been added',
                    })}
                </span>
            );
    }

    return <div className={Styles.listContainer}>{tableHtml}</div>;
};

export default ProjectsListView;
