import theme from '@Styles/MuiTheming';
import LayoutStyles from '@Styles/layout.scss';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { StaticDrawer } from '@Components/Drawers';
import { Header, HeaderType } from '@Components/Header';
import { MainContainer, Page, PageContent } from '@Components/Page';
import {
    OrganizationsApi,
    organizationsState,
    selectedOrganizationState,
} from '@Data/organizations';
import {
    ProjectsApi,
    projectDisplayTypeState,
    projectsState,
} from '@Data/projects';
import {
    WorkspacesApi,
    selectedWorkspaceState,
    workspacesState,
} from '@Data/workspaces';
import Styles from './WorkspacesView.scss';
import CreateProjectDialog from './components/CreateProject/CreateProjectDialog';
import CreateWorkspaceDialog from './components/CreateWorkspace/CreateWorkspaceDialog';
import { NoOrganizations } from './components/NoEntityMessages';
import ProjectsDisplay from './components/ProjectsDisplay/ProjectsDisplay';
import WorkspaceController from './components/WorkspaceController/WorkspaceController';
import WorkspacesDisplay from './components/WorkspacesDisplay/WorkspacesDisplay';

const WorkspacesView = () => {
    const params = useParams();
    const organizationId = params.organizationId;
    const workspaceId = params.workspaceId;

    const organizations = useSelector(organizationsState);
    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);
    const projects = useSelector(projectsState);
    const projectDisplayType = useSelector(projectDisplayTypeState);
    const workspaces = useSelector(workspacesState);

    const [areOrganizationsLoading, setAreOrganizationsLoading] =
        useState<boolean>(true);
    const [areWorkspacesLoading, setAreWorkspacesLoading] =
        useState<boolean>(true);
    const [areProjectsLoading, setAreProjectsLoading] = useState<boolean>(true);
    const [isManageProjectOpen, setIsManageProjectOpen] =
        useState<boolean>(false);
    const [isCreateWorkspaceOpen, setIsCreateWorkspaceOpen] =
        useState<boolean>(false);
    const auth = useAuth();

    useEffect(() => {
        const getOrgs = async () => {
            setAreOrganizationsLoading(true);

            ProjectsApi.reset();
            WorkspacesApi.reset();

            await OrganizationsApi.loadOrganizations();
            setAreOrganizationsLoading(false);
        };

        if (auth.user && !auth.isLoading) {
            getOrgs();
        }
    }, [auth]);

    useEffect(() => {
        if (!areOrganizationsLoading && organizations.length > 0) {
            const org =
                organizations.find((o) => o.id === organizationId) ??
                organizations[0];

            OrganizationsApi.setSelectedOrganization(org);
        }

        if (!areOrganizationsLoading && organizations.length === 0) {
            setAreWorkspacesLoading(false);
            setAreProjectsLoading(false);
        }
    }, [organizationId, organizations, areOrganizationsLoading]);

    useEffect(() => {
        const getWorkspaces = async () => {
            setAreWorkspacesLoading(true);
            ProjectsApi.reset();
            WorkspacesApi.reset();
            await WorkspacesApi.loadWorkspaces(selectedOrganization.id);
            setAreWorkspacesLoading(false);
        };
        if (
            !areOrganizationsLoading &&
            organizations.length > 0 &&
            !_isNil(selectedOrganization)
        ) {
            getWorkspaces();
        }
    }, [areOrganizationsLoading, organizations, selectedOrganization]);

    useEffect(() => {
        if (!areWorkspacesLoading && workspaces.length > 0) {
            const workspace =
                workspaces.find((w) => w.id === workspaceId) ?? workspaces[0];
            WorkspacesApi.setSelectedWorkspace(workspace);
        }
    }, [workspaceId, workspaces, areWorkspacesLoading]);

    useEffect(() => {
        const getProjects = async () => {
            setAreProjectsLoading(true);
            await ProjectsApi.loadProjects(
                selectedWorkspace.id,
                selectedWorkspace.color
            );
            setAreProjectsLoading(false);
        };

        if (
            !areWorkspacesLoading &&
            workspaces.length > 0 &&
            !_isNil(selectedWorkspace)
        ) {
            getProjects();
        } else {
            setAreProjectsLoading(false);
        }
    }, [areWorkspacesLoading, workspaces, selectedWorkspace]);

    const handleOpenManageProject = () => {
        setIsManageProjectOpen(true);
    };

    const handleManageProjectClose = async (): Promise<void> => {
        setIsManageProjectOpen(false);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, theme.transitions.duration.standard);
        });
    };

    const handleOpenManageWorkspaceDialog = () => {
        setIsCreateWorkspaceOpen(true);
    };

    const handleCloseModal = async (): Promise<void> => {
        setIsCreateWorkspaceOpen(false);

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, theme.transitions.duration.standard);
        });
    };

    if (
        !areOrganizationsLoading &&
        organizations.length === 0 &&
        !areWorkspacesLoading &&
        !areProjectsLoading
    ) {
        return (
            <Page>
                <Header headerType={HeaderType.DEFAULT} />
                <PageContent>
                    <MainContainer>
                        <NoOrganizations />
                    </MainContainer>
                </PageContent>
            </Page>
        );
    }

    return (
        <React.Fragment>
            <Page>
                <Header headerType={HeaderType.DEFAULT} />
                <PageContent>
                    <StaticDrawer fullWidth>
                        <WorkspacesDisplay
                            isLoading={
                                areOrganizationsLoading || areWorkspacesLoading
                            }
                            workspaces={workspaces}
                            selectedWorkspaceId={selectedWorkspace?.id}
                            organizations={organizations}
                            selectedOrganizationId={selectedOrganization?.id}
                            onOpenCreateWorkspace={
                                handleOpenManageWorkspaceDialog
                            }
                        />
                    </StaticDrawer>
                    <MainContainer hasStaticDrawer alignStart fullWidth>
                        <div
                            className={cx(
                                LayoutStyles.mainContainerContent,
                                Styles.panelContainer
                            )}
                        >
                            <WorkspaceController
                                isLoading={
                                    areOrganizationsLoading ||
                                    areWorkspacesLoading
                                }
                                selectedWorkspace={selectedWorkspace}
                                projectDisplayType={projectDisplayType}
                                onOpenCreateProject={handleOpenManageProject}
                                organizationId={selectedOrganization?.id}
                            />
                            {!areOrganizationsLoading &&
                                !areWorkspacesLoading &&
                                selectedWorkspace && (
                                    <ProjectsDisplay
                                        isLoading={
                                            areOrganizationsLoading ||
                                            areWorkspacesLoading ||
                                            areProjectsLoading
                                        }
                                        projects={projects}
                                        projectDisplayType={projectDisplayType}
                                        onOpenCreateProject={
                                            handleOpenManageProject
                                        }
                                        selectedOrganizationId={
                                            selectedOrganization?.id
                                        }
                                        selectedWorkspaceId={
                                            selectedWorkspace?.id
                                        }
                                        onEditProject={handleOpenManageProject}
                                    />
                                )}
                        </div>
                    </MainContainer>
                </PageContent>
            </Page>
            <CreateProjectDialog
                isOpen={isManageProjectOpen}
                currentWorkspace={selectedWorkspace}
                onClose={handleManageProjectClose}
                selectedWorkspaceId={selectedWorkspace?.id}
                selectedOrganizationId={selectedOrganization?.id}
            />
            <CreateWorkspaceDialog
                selectedOrganizationId={selectedOrganization?.id}
                isOpen={isCreateWorkspaceOpen}
                onClose={handleCloseModal}
            />
        </React.Fragment>
    );
};

export default WorkspacesView;
