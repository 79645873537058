import { Menu } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { NotificationsApi } from '@Data/notifications';
import { sortedPersistentNotificationsState } from '@Data/notifications/Reducer';
import { NotificationKeys } from '@Services/i18n/keys';
import Styles from './Notifications.scss';
import { NotificationsIconButton } from './NotificationsIconButton';
import { PersistentNotification } from './PersistentNotification';

export const NotificationsDrawer = () => {
    const intl = useIntl();
    const persistentNotifications = useSelector(
        sortedPersistentNotificationsState
    );
    const notificationIconContainerId = 'NotificationBtnContainer';
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const notificationsHtml =
        persistentNotifications.length === 0 ? (
            <div className={Styles.noNotifications}>
                {intl.formatMessage({
                    id: NotificationKeys.NoNotifications,
                    defaultMessage: 'There are no notifications.',
                })}
            </div>
        ) : (
            persistentNotifications.map((pn) => {
                return (
                    <PersistentNotification
                        key={pn.toastId}
                        state={pn.state}
                        type={pn.type}
                        text={pn.message}
                        onRemove={() =>
                            NotificationsApi.removePersistentNotification(pn)
                        }
                    />
                );
            })
        );

    return (
        <React.Fragment>
            <div id={notificationIconContainerId}>
                <NotificationsIconButton
                    hasNotifications={persistentNotifications.length > 0}
                    onClick={() => setIsOpen(true)}
                />
            </div>
            <Menu
                open={isOpen}
                anchorEl={document.getElementById(notificationIconContainerId)}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '.MuiMenu-paper': {
                        padding: '6px',
                    },
                }}
            >
                {notificationsHtml}
            </Menu>
        </React.Fragment>
    );
};
