import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { IProject } from '@Data/projects';
import Styles from './Workspaces.scss';

interface IWorkspaceProjectButtonProps {
    isSelected: boolean;
    circleColor: string;
    href: string;
    onSelect: () => void;
    project: IProject;
}

export const WorkspaceProjectButton = (props: IWorkspaceProjectButtonProps) => {
    return (
        <div
            className={cx(
                Styles.workspaceProjectBtnContainer,
                props.isSelected ? Styles.selectedWorkspaceProjectButton : null
            )}
            data-cy={
                props.isSelected ? 'project-button-selected' : 'project-button'
            }
        >
            <div className={Styles.wpbTail}>
                <div className={Styles.wpbTailTop} />
                <div className={Styles.wpbTailBottom} />
            </div>
            <div className={Styles.wpbContent}>
                <div className={Styles.wpbContentTail} />
                <div className={Styles.wpbColorIconContainer}>
                    <div
                        className={Styles.wpbColorIcon}
                        style={{ backgroundColor: props.circleColor }}
                    />
                </div>
                <div role="button" className={Styles.wpbProjName}>
                    <Link to={props.href} onClick={props.onSelect}>
                        <span>{props.project.display_name}</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};
