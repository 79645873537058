import { Skeleton } from '@mui/material';
import React from 'react';

const SortingSkeleton = () => {
    return (
        <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
                width: '219px',
                height: '42px',
            }}
        />
    );
};

export default SortingSkeleton;
