import _isNil from 'lodash/isNil';
import {
    IAnalyticsResource,
    IAnalyticsVersion,
    ICurrentUserInformation,
} from './Interfaces';

function getEcrUrl(dockerHub = false): string {
    if (_isNil(process.env.AWS_ACCOUNT_NUMBER)) {
        throw new Error(
            'AWS Account number has not been correctly registered.'
        );
    }

    const baseUrl = `${process.env.AWS_ACCOUNT_NUMBER}.dkr.ecr.ap-southeast-2.amazonaws.com`;

    return dockerHub ? `${baseUrl}/docker-hub` : baseUrl;
}

export const getPostGisValues = (instanceSize: string) => {
    return {
        postGISVersion: '3.1',
        instanceSize: instanceSize,
        // patroni: {
        //     dynamicConfiguration: {
        //         postgresql: {
        //             parameters: {
        //                 shared_preload_libraries: 'timescaledb',
        //                 wal_buffers: '500MB',
        //                 wal_recycle: 'off',
        //                 max_wal_size: '5GB',
        //                 wal_init_zero: 'off',
        //             },
        //         },
        //     },
        // },
        instanceMemory: '2Gi',
        instanceCPU: 2,
        instanceReplicas: 1,
    };
};

export const getHasuraValues = (adminSecret: string) => {
    return {
        container: {
            port: 8080,
        },
        image: {
            repository: `${getEcrUrl(true)}/hasura/graphql-engine`,
            tag: 'v2.18.0',
        },
        ingress: {
            enabled: true,
        },
        autoscaling: {
            minReplicas: 1,
        },
        enableConsole: true,
        resources: {
            limits: {
                cpu: '1',
                memory: '1Gi',
            },
            requests: {
                cpu: '1',
                memory: '1Gi',
            },
        },
        adminSecret: adminSecret,
    };
};

export const getPgServerValues = (type: 'tile' | 'feature') => {
    const image =
        type === 'tile'
            ? {
                  tag: '20231005',
                  repository: `${getEcrUrl(true)}/pramsey/pg_tileserv`,
              }
            : {
                  tag: '20230920',
                  repository: `${getEcrUrl(true)}/pramsey/pg_featureserv`,
              };

    const port = type === 'tile' ? 7800 : 9000;

    return {
        image: image,
        ingress: {
            enabled: true,
        },
        container: {
            port: port,
        },
        autoscaling: {
            minReplicas: 1,
        },
        env: [
            {
                name: 'DATABASE_URL',
                valueFrom: {
                    secretKeyRef: {
                        key: 'uri',
                        name: 'postgres-pguser-postgres',
                    },
                },
            },
        ],
    };
};

export const getJupyterValues = (
    password: string,
    currentUserInformation: ICurrentUserInformation,
    environment: string,
    resourceLimits: IAnalyticsResource,
    useDatabase: boolean,
    version: IAnalyticsVersion,
    FLAG_tryAiGisJupyter?: boolean
) => {
    let imageTag;
    if (!FLAG_tryAiGisJupyter) {
        imageTag = 'publish-04-08-2023';
    } else {
        switch (version.id) {
            case 'ai':
                imageTag = 'publisher-ai-12-10-2023';
                break;
            case 'gis':
                imageTag = 'publish-gis-13-10-2023';
                break;
            default:
                throw new Error('Unknown Analytics image type');
        }
    }

    const values = {
        openExternal: true,
        image: {
            repository: `${getEcrUrl()}/platform/jupyter`,
            tag: imageTag,
        },
        ingress: {
            enabled: true,
        },
        container: {
            port: 8888,
        },
        autoscaling: {
            minReplicas: 1,
        },
        resources: {
            limits: {
                cpu: resourceLimits.cpu,
                memory: resourceLimits.memory,
            },
            requests: {
                cpu: resourceLimits.cpu,
                memory: resourceLimits.memory,
            },
        },
        volumes: [
            {
                name: 'notebooks',
                mountPath: '/notebooks',
                type: 'persistentVolumeClaim',
                readOnly: false,
                size: '50Gi',
            },
        ],
        env: [
            {
                name: 'CIABPASSWORD',
                value: password,
            },
            {
                name: 'ORGANIZATION_ID',
                value: currentUserInformation.organization.id,
            },
            {
                name: 'ORGANIZATION_NAME',
                value: currentUserInformation.organization.name,
            },
            {
                name: 'WORKSPACE_ID',
                value: currentUserInformation.workspace.id,
            },
            {
                name: 'WORKSPACE_NAME',
                value: currentUserInformation.workspace.name,
            },
            {
                name: 'PROJECT_ID',
                value: currentUserInformation.project.id,
            },
            {
                name: 'PROJECT_NAME',
                value: currentUserInformation.project.name,
            },
            {
                name: 'VALIDATOR_URL',
                value: process.env.JUPYTER_VALIDATOR_URL,
            },
            {
                name: 'ENVIRONMENT',
                value: environment,
            },
        ],
    };

    if (useDatabase) {
        const dbValues = [
            {
                name: 'DB_HOSTNAME',
                valueFrom: {
                    secretKeyRef: {
                        key: 'host',
                        name: 'postgres-pguser-postgres',
                    },
                },
            },
            {
                name: 'DB_USERNAME',
                valueFrom: {
                    secretKeyRef: {
                        key: 'user',
                        name: 'postgres-pguser-postgres',
                    },
                },
            },
            {
                name: 'DB_PASSWORD',
                valueFrom: {
                    secretKeyRef: {
                        key: 'password',
                        name: 'postgres-pguser-postgres',
                    },
                },
            },
            {
                name: 'DB_DATABASE',
                valueFrom: {
                    secretKeyRef: {
                        key: 'dbname',
                        name: 'postgres-pguser-postgres',
                    },
                },
            },
        ];
        //@ts-ignore
        dbValues.forEach((v) => values.env.push(v));
    }
    return values;
};
