import TypographyStyles from '@Styles/typography.scss';
import React from 'react';
import Styles from './UserMenu.scss';

interface IUserDetailsProps {
    firstName: string;
    lastName: string;
    email: string;
}

const UserDetails = (props: IUserDetailsProps) => {
    return (
        <div
            className={Styles.userDetailsContainer}
            data-cy="menu-user-details"
        >
            <span className={TypographyStyles.h4}>
                {props.firstName} {props.lastName}
            </span>
            <span className={TypographyStyles.bodySmall}>{props.email}</span>
        </div>
    );
};

export default UserDetails;
