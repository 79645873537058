import React from 'react';

export enum AddServicePage {
    SELECT_SERVICE,
    SERVICE_INFORMATION,
}

export interface AddServiceWizardOptions {
    body: React.ReactNode;
    cancelText: string;
    onCancel: () => void;
    submitText?: string;
    onSubmit?: () => void;
}
