export function getServiceStatusString(statusIn: string): string {
    switch (statusIn) {
        case 'down':
            return 'Offline';
        case 'up':
            return 'Live';
        case 'progress':
        default:
            return 'Pending';
    }
}
