import Flex from '@Styles/flex.scss';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { HeaderBreadcrumbsTitle } from '@Components/Breadcrumbs';
import { FixedWidthMenuDrawer } from '@Components/Drawers';
import { WorkspaceAccordion } from '@Components/Workspaces';
import { IProject } from '@Data/projects';
import { IWorkspace, WorkspacesApi, workspacesState } from '@Data/workspaces';
import { ProjectKeys } from '@Services/i18n/keys';
import { LogoIcon } from '../LogoOnly/LogoIcon';
import Styles from './MenuAndBreadcrumbs.scss';

export interface IMenuAndBreadcrumbsProps {
    selectedOrganizationId: string;
    selectedProject: IProject;
    selectedWorkspace: IWorkspace;
    isLoading?: boolean;
}

export const MenuAndBreadcrumbs = (props: IMenuAndBreadcrumbsProps) => {
    const intl = useIntl();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const workspaces = useSelector(workspacesState);
    const [expandedAccordionIndex, setExpandedAccordionIndex] =
        useState<number>(-1);

    useEffect(() => {
        const loadWorkspaces = async () => {
            await WorkspacesApi.loadWorkspaces(props.selectedOrganizationId);
        };

        if (!props.isLoading && props.selectedOrganizationId) {
            if (_isNil(workspaces) || workspaces.length === 0) {
                loadWorkspaces();
            } else {
                setExpandedAccordionIndex(
                    workspaces.findIndex(
                        (w) => w.id === props.selectedWorkspace.id
                    )
                );
            }
        }
    }, [props.isLoading, props.selectedOrganizationId, workspaces]);

    return props.isLoading ? (
        <LogoIcon size="small" />
    ) : (
        <React.Fragment>
            <div className={cx(Flex.flex, Flex.alignItemsCenter)}>
                <LogoIcon size="small" onClick={() => setIsMenuOpen(true)} />
                <HeaderBreadcrumbsTitle
                    selectedOrganizationId={props.selectedOrganizationId}
                    selectedProject={props.selectedProject}
                    selectedWorkspace={props.selectedWorkspace}
                    isLoading={props.isLoading}
                    className={Styles.breadcrumbs}
                />
            </div>
            <FixedWidthMenuDrawer
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
            >
                <div className={Styles.menuContentContainer}>
                    <span className={Styles.projectsTitle}>
                        {intl.formatMessage({
                            id: ProjectKeys.ProjectsList,
                            defaultMessage: 'Projects list',
                        })}
                    </span>
                    <div className={Styles.workspacesContainer}>
                        {workspaces.map((w, i) => (
                            <WorkspaceAccordion
                                key={`workspaceAccordion${w.id}`}
                                workspace={w}
                                selectedOrganizationId={
                                    props.selectedOrganizationId
                                }
                                selectedProjectId={props.selectedProject.id}
                                isSelected={props.selectedWorkspace.id === w.id}
                                expanded={i === expandedAccordionIndex}
                                onClick={() => setExpandedAccordionIndex(i)}
                                onSelectProject={() => setIsMenuOpen(false)}
                            />
                        ))}
                    </div>
                </div>
            </FixedWidthMenuDrawer>
        </React.Fragment>
    );
};
