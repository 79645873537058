const Sorting = {
    DateCreated: 'sorting.dateCreated',
    DateUpdated: 'sorting.dateUpdated',
    Alphabetical: 'sorting.alphabetical',
    Ascending: 'sorting.ascending',
    Descending: 'sorting.descending',
    ViewAsCards: 'sorting.viewAsCards',
    ViewAsList: 'sorting.viewAsList',
};

export default Sorting;
