import analyticsIcon from '@Images/analytics.png';
import customIcon from '@Images/customService.png';
import datastoreIcon from '@Images/dataStore.png';
import { api } from '@orbica/platform-sdk-dev';
import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';
import { ToastType } from '@Components/Toasts';
import { asyncTimeout, watchAsync } from '@Services/helpers/Misc';
import { SolutionKeys } from '@Services/i18n/keys';
import {
    ICreatePersistentNotification,
    createToast,
} from '@Services/notifications';
import store from '@Services/redux/reduxStore';
import { OrbicaSdkClient } from '@Services/sdk/OrbicaSdkClient';
import { handleFailedSdkResponse } from '@Services/sdk/helpers';
import {
    AddSolutionType,
    IAddAnalyticsInputs,
    IAddCustomServiceInputs,
    IAddDatastoreInputs,
    IAddServiceCatalogService,
    ICurrentUserInformation,
    ISolution,
} from './Interfaces';
import { SolutionsActions } from './Reducer';
import {
    getHasuraValues,
    getJupyterValues,
    getPgServerValues,
    getPostGisValues,
} from './Values';

const datastoreInputs: IAddDatastoreInputs = {
    hasuraSecretKey: '',
    instanceSize: process.env.ENVIRONMENT === 'prod' ? '10Gi' : '2Gi',
};

const analyticsInputs: IAddAnalyticsInputs = {
    password: '',
    useDatabase: false,
    resourceLimit: {
        options: [
            {
                cpu: '2',
                memory: '8Gi',
                label: '2 CPUs, 8Gb Memory',
            },
            {
                cpu: '4',
                memory: '16Gi',
                label: '4 CPUs, 16Gb Memory',
            },
            {
                cpu: '8',
                memory: '32Gi',
                label: '8 CPUs, 32Gb Memory',
            },
            {
                cpu: '16',
                memory: '64Gi',
                label: '16 CPUs, 64Gb Memory',
            },
            // 30/10/23 - limit options for easier costing
            // {
            //     cpu: '2',
            //     memory: '8Gi',
            //     label: '2 CPUs, 8Gb Memory (General Purpose)',
            // },
            // {
            //     cpu: '4',
            //     memory: '16Gi',
            //     label: '4 CPUs, 16Gb Memory (General Purpose)',
            // },
            // {
            //     cpu: '2',
            //     memory: '16Gi',
            //     label: '2 CPUs, 16Gb Memory (Memory Optimised)',
            // },
            // {
            //     cpu: '4',
            //     memory: '32Gi',
            //     label: '4 CPUs, 32Gb Memory (Memory Optimised)',
            // },
            // {
            //     cpu: '8',
            //     memory: '16Gi',
            //     label: '8 CPUs, 16Gb Memory (Compute Optimised)',
            // },
            // {
            //     cpu: '16',
            //     memory: '32Gi',
            //     label: '16 CPUs, 32Gb Memory (Compute Optimised)',
            // },
        ],
        selectedResourceLimit: null,
    },
    analyticsVersion: {
        options: [
            { label: 'Geospatial', id: 'gis' },
            { label: 'Artificial Inteligence', id: 'ai' },
        ],
        selectedVersion: null,
    },
};

const customServiceInputs: IAddCustomServiceInputs = {
    name: '',
    displayName: '',
    customServiceSettings: `{
    "image": {
        "tag": "{{ TAG }}",
        "repository": "{{ REPOSITORY_URL }}"
    },
    "ingress": {
        "enabled": true
    },
    "container": {
        "port": {{ PORT }}
    },
    "resources": {
        "limits": {
            "cpu": "2",
            "memory": "2Gi"
        },
        "requests": {
            "cpu": "1",
            "memory": "500Mi"
        }
    },
    "autoscaling": {
        "minReplicas": 1
    },
    "env": [
        {
            "name": "key1",
            "value": "Value One"
        }
    ],
    "openExternal": false
}`,
};

const temporaryServiceCatalogDefinitions = [
    {
        chart: 'postgrescluster',
        name: SolutionKeys.DataStore,
        displayName: SolutionKeys.DataStore,
        info: SolutionKeys.DataStoreDescription,
        logo: datastoreIcon,
        documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Datastore/`,
        contents: [
            {
                name: 'PostgreSQL with PostGIS extension',
                description: `The world's most advanced open source database`,
                documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Datastore/PostgreSQL`,
            },
            {
                name: 'Hasura',
                description:
                    'Inspect your database and create blazing fast APIs',
                documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Datastore/Hasura`,
            },
            {
                name: 'PostGIS Tile Server',
                description: 'Display your geospatial data as vector map tiles',
                documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Datastore/TileServer`,
            },
            {
                name: 'PostGIS Feature Server',
                description:
                    'Provides access to your geospatial data via GeoJSON feature services',
                documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Datastore/FeatureServer`,
            },
        ],
        settings: {
            addServiceType: AddSolutionType.DATA_STORE,
            inputs: datastoreInputs,
        },
    },
    {
        chart: 'knative-services',
        name: SolutionKeys.Analytics,
        displayName: SolutionKeys.Analytics,
        logo: analyticsIcon,
        info: SolutionKeys.AnalyticsDescription,
        documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Analytics/`,
        contents: [
            {
                name: 'Jupyter Notebook',
                description:
                    'Configure and arrange workflows in data science, scientific computing, computational journalism, and machine learning ',
                documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Analytics/Jupyter`,
            },
        ],
        settings: {
            addServiceType: AddSolutionType.ANALYTICS,
            inputs: analyticsInputs,
        },
    },
    {
        chart: 'knative-services',
        name: SolutionKeys.CustomSolution,
        displayName: SolutionKeys.CustomSolutionDisplayName,
        logo: customIcon,
        info: SolutionKeys.CustomSolutionDescription,
        documentationUrl: `${process.env.DOCUMENTATION_URL}docs/Documentation/Platform Management/Solution/Custom Solution`,
        contents: [],
        settings: {
            addServiceType: AddSolutionType.CUSTOM,
            inputs: customServiceInputs,
        },
    },
];

const Api = {
    getProjectSolutions: async (
        projectId: string,
        FLAG_tryDisplaySwagger = false
    ): Promise<ISolution[]> => {
        const servicesApi = await OrbicaSdkClient.instance.getServiceApi();
        const response = await servicesApi.all(projectId, null);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        let solutions = response.data as Array<ISolution>;

        /**
         * 9/10/23 - don't display publisher swagger services in the list if flag is off
         */
        if (!FLAG_tryDisplaySwagger) {
            solutions = solutions.filter((s) => {
                return (
                    _isNil(s.outputs?.type) || s.outputs?.type !== 'publisher'
                );
            });
        }

        solutions = _orderBy(solutions, (s) => new Date(s.created_at), 'asc');
        store.dispatch(SolutionsActions.updateProjectSolutions(solutions));
        return solutions;
    },
    tryUpdatePendingSolution: async (solutionId: string) => {
        const watchSolutionStatus = () => {
            return watchAsync(async () => {
                const servicesApi =
                    await OrbicaSdkClient.instance.getServiceApi();
                const statusResponse = (await servicesApi.checkStatus(
                    solutionId
                )) as {
                    status: string;
                    data: { status: string; url: { endpoint: string } };
                };
                return statusResponse.data.status === 'up' ||
                    statusResponse.data.status === 'down'
                    ? statusResponse.data.status
                    : null;
            }, 3000);
        };
        const tryGetIsStatusUp = async () => {
            const newStatus = await watchSolutionStatus();

            if (newStatus) {
                store.dispatch(
                    SolutionsActions.updateProjectSolutionStatus({
                        id: solutionId,
                        status: newStatus,
                    })
                );
            }
        };

        tryGetIsStatusUp();
    },
    getServiceCatalog: async (): Promise<IAddServiceCatalogService[]> => {
        const serviceCatalogApi =
            await OrbicaSdkClient.instance.getServiceCatalogApi();

        const response = await serviceCatalogApi.all();

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }

        const serviceCatalogServices =
            response.data as api.IServiceCatalogService[];

        const mappedAvailableServices: IAddServiceCatalogService[] =
            temporaryServiceCatalogDefinitions.map((definition) => {
                const foundCatalogService = serviceCatalogServices.find(
                    (s) => s.chart === definition.chart
                );

                return {
                    ...definition,
                    ...foundCatalogService,
                };
            });

        store.dispatch(
            SolutionsActions.updateServiceCatalog(mappedAvailableServices)
        );

        const knativeCatalogService = serviceCatalogServices.find(
            (s) => s.chart === 'knative-services'
        );

        const hasuraCatalogService = serviceCatalogServices.find(
            (s) => s.chart === 'hasura'
        );

        store.dispatch(
            SolutionsActions.setExtraCatalogIds({
                knative: knativeCatalogService.id,
                hasura: hasuraCatalogService.id,
            })
        );

        return mappedAvailableServices;
    },
    addSolution: async (
        newSolution: IAddServiceCatalogService,
        currentUserInformation: ICurrentUserInformation,
        persistentNotificationObj: ICreatePersistentNotification,
        knativeCatalogServiceId: string,
        hasuraCatalogServiceId: string,
        FLAG_tryAiGisJupyter?: boolean
    ): Promise<boolean> => {
        const servicesApi = await OrbicaSdkClient.instance.getServiceApi();

        if (
            newSolution.settings.addServiceType === AddSolutionType.DATA_STORE
        ) {
            return await Api._createDatastore(
                newSolution,
                currentUserInformation.project.id,
                servicesApi,
                persistentNotificationObj,
                knativeCatalogServiceId,
                hasuraCatalogServiceId
            );
        } else if (
            newSolution.settings.addServiceType === AddSolutionType.ANALYTICS
        ) {
            return await Api._createAnalytics(
                newSolution,
                currentUserInformation,
                servicesApi,
                persistentNotificationObj,
                FLAG_tryAiGisJupyter
            );
        } else if (
            newSolution.settings.addServiceType === AddSolutionType.CUSTOM
        ) {
            return await Api._createCustomSolution(
                newSolution,
                currentUserInformation.project.id,
                servicesApi
            );
        }
    },
    _createDatastore: async (
        newService: IAddServiceCatalogService,
        projectId: string,
        servicesApi: api.ServiceApi,
        persistentNotificationObj: ICreatePersistentNotification,
        knativeCatalogServiceId: string,
        hasuraCatalogServiceId: string
    ): Promise<boolean> => {
        const inputs = newService.settings.inputs as IAddDatastoreInputs;

        const newDbBody: api.IServiceNew = {
            name: 'postgres',
            project_id: projectId,
            service_catalog_id: newService.id,
            values: getPostGisValues(inputs.instanceSize),
            display_name: '',
        };

        const postgresResponse = await servicesApi.add(newDbBody);
        if (postgresResponse.status === 'failed') {
            handleFailedSdkResponse(
                postgresResponse.data,
                persistentNotificationObj
            );
            return false;
        }

        // wait for yaml to write
        await asyncTimeout(5000);

        const newHasuraBody: api.IServiceNew = {
            name: 'hasura',
            project_id: projectId,
            service_catalog_id: hasuraCatalogServiceId,
            values: getHasuraValues(inputs.hasuraSecretKey),
            display_name: 'Hasura',
        };

        const hasuraResponse = await servicesApi.add(newHasuraBody);
        if (hasuraResponse.status === 'failed') {
            handleFailedSdkResponse(
                hasuraResponse.data,
                persistentNotificationObj
            );
            return false;
        }

        // wait for yaml to write
        await asyncTimeout(5000);

        const newPgTileservBody: api.IServiceNew = {
            name: 'tileserver',
            project_id: projectId,
            service_catalog_id: knativeCatalogServiceId,
            values: getPgServerValues('tile'),
            display_name: 'PostGIS Tile Server',
        };

        const tileservResponse = await servicesApi.add(newPgTileservBody);
        if (tileservResponse.status === 'failed') {
            handleFailedSdkResponse(
                tileservResponse.data,
                persistentNotificationObj
            );
            return false;
        }

        // wait for yaml to write
        await asyncTimeout(5000);

        const newPgFeatureservBody: api.IServiceNew = {
            name: 'featureserver',
            project_id: projectId,
            service_catalog_id: knativeCatalogServiceId,
            values: getPgServerValues('feature'),
            display_name: 'PostGIS Feature Server',
        };

        const featureservResponse = await servicesApi.add(newPgFeatureservBody);
        if (featureservResponse.status === 'failed') {
            handleFailedSdkResponse(
                featureservResponse.data,
                persistentNotificationObj
            );
            return false;
        }

        return true;
    },
    _createAnalytics: async (
        newService: IAddServiceCatalogService,
        currentUserInformation: ICurrentUserInformation,
        servicesApi: api.ServiceApi,
        persistentNotificationObj: ICreatePersistentNotification,
        FLAG_tryAiGisJupyter?: boolean
    ): Promise<boolean> => {
        const settings = newService.settings.inputs as IAddAnalyticsInputs;

        let environment = process.env.ENVIRONMENT;
        if (environment === 'local') {
            environment = 'DEV';
        }

        let name, displayName;
        if (!FLAG_tryAiGisJupyter) {
            name = 'analytics';
            displayName = 'Analytics';
        } else {
            name =
                settings.analyticsVersion.selectedVersion.id === 'gis'
                    ? 'analytics-gis'
                    : 'analytics-ai';

            displayName =
                settings.analyticsVersion.selectedVersion.id === 'gis'
                    ? 'Analytics (Geospatial)'
                    : 'Analytics (AI)';
        }

        const newJupyterBody: api.IServiceNew = {
            name: name,
            project_id: currentUserInformation.project.id,
            service_catalog_id: newService.id,
            values: getJupyterValues(
                settings.password,
                currentUserInformation,
                environment.toUpperCase(),
                settings.resourceLimit.selectedResourceLimit,
                settings.useDatabase,
                settings.analyticsVersion.selectedVersion,
                FLAG_tryAiGisJupyter
            ),
            display_name: displayName,
        };

        const response = await servicesApi.add(newJupyterBody);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data, persistentNotificationObj);
            return false;
        }

        return true;
    },
    _createCustomSolution: async (
        newSolution: IAddServiceCatalogService,
        projectId: string,
        servicesApi: api.ServiceApi
    ): Promise<boolean> => {
        const settings = newSolution.settings.inputs as IAddCustomServiceInputs;

        // check whether the service's name is in use
        const servicesResponse = await servicesApi.all(projectId);
        if (servicesResponse.status === 'failed') {
            handleFailedSdkResponse(servicesResponse.data);
            return false;
        }
        const solutions = servicesResponse.data as ISolution[];
        const existingSolution = solutions.find(
            (s) =>
                s.name.toLowerCase().trim() ===
                settings.name?.toLowerCase().trim()
        );
        if (existingSolution) {
            createToast(
                `The service name '${settings.name}' is already in use, please use a unique name`,
                ToastType.ERROR
            );
            return false;
        }

        const newCustomServiceBody: api.IServiceNew = {
            name: settings.name,
            project_id: projectId,
            service_catalog_id: newSolution.id,
            values: JSON.parse(settings.customServiceSettings),
            display_name: settings.displayName,
        };

        const response = await servicesApi.add(newCustomServiceBody);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return false;
        }

        return true;
    },
    setSelectedProjectService: (projectService: ISolution) => {
        store.dispatch(
            SolutionsActions.updateSelectedProjectService(projectService)
        );
    },

    loadProjectServiceById: async (serviceId: string): Promise<ISolution> => {
        const servicesApi = await OrbicaSdkClient.instance.getServiceApi();
        const response = await servicesApi.get(serviceId);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const projectService = response.data as ISolution;

        store.dispatch(
            SolutionsActions.updateSelectedProjectService(projectService)
        );
        return projectService;
    },

    deleteSolution: async (solution: ISolution): Promise<boolean> => {
        const servicesApi = await OrbicaSdkClient.instance.getServiceApi();
        const response = await servicesApi.delete(solution.id);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        return true;
    },
    updateSolutionDisplayName: async (newName: string, solution: ISolution) => {
        const servicesApi = await OrbicaSdkClient.instance.getServiceApi();

        const updateObj: api.IUpdateService = {
            name: solution.name,
            display_name: newName,
            values: solution.values,
        };

        const response = await servicesApi.update(solution.id, updateObj);

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }

        return response.data as ISolution;
    },
};

export default Api;
