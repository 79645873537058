import { IPersistentNotification } from '@Services/notifications';
import store from '@Services/redux/reduxStore';
import { NotificationsActions } from './Reducer';

const Api = {
    upsertPersistentNotification: (notification: IPersistentNotification) => {
        store.dispatch(
            NotificationsActions.upsertPersistentNotification(notification)
        );
    },
    removePersistentNotification: (notification: IPersistentNotification) => {
        store.dispatch(
            NotificationsActions.removePersistentNotification(notification)
        );
    },
};

export default Api;
