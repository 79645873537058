import { Skeleton } from '@mui/material';
import React from 'react';
import Styles from './ServiceCards.scss';

export const ServiceCardSkeleton = () => {
    return (
        <Skeleton
            animation="wave"
            variant="rectangular"
            className={Styles.serviceCardSkeleton}
            sx={{
                borderRadius: '6px',
                alignSelf: 'stretch',
                height: 'auto',
                aspectRatio: '1/1',
            }}
        />
    );
};
