const Entities = {
    Organization: 'entities.organization',
    Project: 'entities.project',
    Workspace: 'entities.workspace',
    Solution: 'entities.solution',
    Solutions: 'entities.solutions',
    NameLengthErrorMessage: 'entities.nameLengthErrorMessage',
    NameUniquenessErrorMessage: 'entities.nameUniquenessErrorMessage',
    NameUniquenessErrorMessageWithParent:
        'entities.nameUniquenessErrorMessageWithParent',
    NameIllegalCharactersErrorMessage:
        'entities.nameIllegalCharactersErrorMessage',
    NameIllegalFirstCharacterErrorMessage:
        'entities.nameIllegalFirstCharacterErrorMessage',
    NameIllegalLastCharacterErrorMessage:
        'entities.nameIllegalLastCharacterErrorMessage',
    NameInputTooltip: 'entities.nameInputTooltip',
    NameInputPlaceholder: 'entities.nameInputPlaceholder',
    EntityDisplayName: 'entities.entityDisplayName',
    EntityName: 'entities.entityName',
    UpdateEntityDisplayName: 'entities.updateEntityDisplayName',
    UpdatingEntityDisplayName: 'entities.updatingEntityDisplayName',
};

export default Entities;
