import ColorsStyles from '@Styles/colors.scss';
import { Menu } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dialog, DialogFooter, DialogHeader } from '@Components/Dialog';
import { MenuItem } from '@Components/Menu';
import { canCurrentUserEditProjectState } from '@Data/auth';
import { selectedOrganizationState } from '@Data/organizations';
import { selectedProjectState } from '@Data/projects';
import { selectedWorkspaceState } from '@Data/workspaces';
import { EntityKeys, FormKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';

interface IProjectMenuProps {
    onClose: () => void;
    onDelete: () => void;
    anchorEl?: HTMLElement;
    projectName?: string;
}

const ProjectMenu = (props: IProjectMenuProps) => {
    const intl = useIntl();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const canCurrentUserEditProject = useSelector(
        canCurrentUserEditProjectState
    );
    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);
    const selectedProject = useSelector(selectedProjectState);

    const handleDelete = async () => {
        setIsSubmitting(true);
        await props.onDelete();
        setIsDeleteDialogOpen(false);
        setIsSubmitting(false);
    };

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
        props.onClose();
    };

    const projectStr = intl.formatMessage({
        id: EntityKeys.Project,
        defaultMessage: 'project',
    });

    const projectSettingsUrl = Routes.settings.projectSettings.getUrl(
        selectedOrganization?.id,
        selectedWorkspace?.id,
        selectedProject?.id
    );

    return (
        <React.Fragment>
            {canCurrentUserEditProject && (
                <Menu
                    open={!_isNil(props.anchorEl)}
                    anchorEl={props.anchorEl}
                    onClose={props.onClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    autoFocus={false}
                >
                    <MenuItem href={projectSettingsUrl}>
                        {intl.formatMessage(
                            {
                                id: FormKeys.EditObject,
                                defaultMessage: 'Edit project',
                            },
                            { object: projectStr }
                        )}
                    </MenuItem>
                    <MenuItem
                        onClick={openDeleteDialog}
                        color={ColorsStyles.red}
                    >
                        {intl.formatMessage(
                            {
                                id: FormKeys.DeleteObject,
                                defaultMessage: 'Delete project',
                            },
                            { object: projectStr }
                        )}
                    </MenuItem>
                </Menu>
            )}
            <Dialog open={isDeleteDialogOpen}>
                <DialogHeader
                    onClose={() => setIsDeleteDialogOpen(false)}
                    title={intl.formatMessage(
                        {
                            id: FormKeys.ConfirmDelete,
                            defaultMessage: `Are you sure you want to delete ${props.projectName}?`,
                        },
                        {
                            objectName: props.projectName
                                ? props.projectName
                                : '',
                        }
                    )}
                />
                <DialogFooter
                    onCancel={() => setIsDeleteDialogOpen(false)}
                    submitText={intl.formatMessage({
                        id: FormKeys.Delete,
                        defaultMessage: 'Delete',
                    })}
                    onSubmit={handleDelete}
                    isSubmitting={isSubmitting}
                    submittingText={intl.formatMessage(
                        {
                            id: FormKeys.DeletingObject,
                            defaultMessage: 'Deleting project',
                        },
                        { object: projectStr }
                    )}
                />
            </Dialog>
        </React.Fragment>
    );
};

export default ProjectMenu;
