import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    CreateProjectCard,
    ProjectCard,
    ProjectCardMenu,
    ProjectCardSkeleton,
} from '@Components/Cards/ProjectCards';
import { canCurrentUserEditWorkspaceState } from '@Data/auth';
import { IProject } from '@Data/projects';
import { Routes } from '@Services/navigation';
import { IProjectsCardViewProps } from './Interfaces';
import Styles from './ProjectsDisplay.scss';

const ProjectsCardView = (props: IProjectsCardViewProps) => {
    const canCurrentUserEditWorkspace = useSelector(
        canCurrentUserEditWorkspaceState
    );
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuProject, setMenuProject] = useState<IProject | null>(null);

    const handleOpenProjectMenu = (
        anchorElement: HTMLElement,
        project: IProject
    ) => {
        setMenuProject(project);
        setAnchorEl(anchorElement);
    };

    const handleCloseProjectMenu = () => {
        setAnchorEl(null);
        // setMenuProject(null);
    };

    const handleEditProject = () => {
        props.onEditProject(menuProject);
        setMenuProject(null);
    };

    const handleDeleteProject = async () => {
        await props.onDeleteProject(menuProject);
    };

    const projectsHtml = props.isLoading
        ? [
              <ProjectCardSkeleton key={'pcs1'} />,
              <ProjectCardSkeleton key={'pcs2'} />,
              <ProjectCardSkeleton key={'pcs3'} />,
          ]
        : props.projects.map((p) => (
              <ProjectCard
                  key={p.id}
                  title={p.display_name}
                  date={p.updated_at}
                  tags={p.tags}
                  workspaceColor={p.workspaceColor}
                  href={Routes.project.overview.getUrl(
                      props.selectedOrganizationId,
                      props.selectedWorkspaceId,
                      p.id
                  )}
                  onOpenMenu={(anchorEl) => handleOpenProjectMenu(anchorEl, p)}
              />
          ));

    return (
        <React.Fragment>
            <div
                className={Styles.cardContainer}
                data-cy="card-container-project"
            >
                {!props.isLoading && canCurrentUserEditWorkspace && (
                    <CreateProjectCard onClick={props.onOpenCreateProject} />
                )}
                {projectsHtml}
            </div>
            <ProjectCardMenu
                anchorEl={anchorEl}
                projectName={menuProject?.display_name}
                onClose={handleCloseProjectMenu}
                onEdit={handleEditProject}
                onDelete={handleDeleteProject}
            />
        </React.Fragment>
    );
};

export default ProjectsCardView;
