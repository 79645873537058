import { api } from '@orbica/platform-sdk-dev';

export enum OrganizationType {
    PERSONAL,
    ORGANIZATION,
}

export interface IOrganization extends api.IOrganization {
    type?: OrganizationType;
}

export interface IOrgUserDetails extends api.IOrgUserDetails {
    __typename: string;
}
