export enum View {
    SIGNUP,
    LOGIN,
    WORKSPACES,
    MARKETPLACE,
    MARKETPLACE_DATA_LIBRARY,
    MARKETPLACE_MODELS_ALGORITHMS,
    MARKETPLACE_PROJECT_TEMPLATES,
    MARKETPLACE_PLUGINS_APIS,
    MARKETPLACE_VISUALIZATION_APPS,
    DEVKIT,
    ORGANIZATION_SETTINGS,
    WORKSPACE_SETTINGS,
    PROJECT_SETTINGS,
    PROJECT_OVERVIEW,
    PROJECT_DATA,
    PROJECT_ANALYSIS,
    PROJECT_WORKFLOW,
    PROJECT_DESIGN,
    TEMPORARY_PROJECT_SERVICE,
}
