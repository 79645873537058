import CircularProgress, {
    CircularProgressProps,
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledCircularProgress = styled(CircularProgress, {
    shouldForwardProp: (prop) => prop !== 'indicatorColor',
})((props) => ({
    [`&.${circularProgressClasses.colorPrimary}`]: {
        color: props.indicatorColor ? props.indicatorColor : '#fff',
    },
}));

export interface ISpinnerProps extends CircularProgressProps {
    thickness?: number;
    size?: number;
    indicatorColor?: string;
}

export const Spinner = (props: ISpinnerProps) => {
    const { thickness, size, ...progressProps } = props;
    const spinnerThickness = thickness ?? 6;
    const spinnerSize = size ?? 24;

    return (
        <StyledCircularProgress
            variant="indeterminate"
            thickness={spinnerThickness}
            size={spinnerSize}
            {...progressProps}
        />
    );
};
