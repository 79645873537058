const Forms = {
    Cancel: 'forms.cancel',
    Submit: 'forms.submit',
    CharactersRemaining: 'forms.charactersRemaining',
    Back: 'forms.back',
    NamePlaceholder: 'forms.namePlaceholder',
    ConfirmDelete: 'forms.confirmDelete',
    CreateObject: 'forms.createObject',
    CreatingObject: 'forms.creatingObject',
    ObjectCreated: 'forms.objectCreated',
    EditObject: 'forms.editObject',
    EditingObject: 'forms.editingObject',
    ObjectEdited: 'forms.objectEdited',
    DeleteObject: 'forms.deleteObject',
    Delete: 'forms.delete',
    DeletingObject: 'forms.deletingObject',
    ObjectDeleted: 'forms.objectDeleted',
    ObjectAdded: 'forms.objectAdded',
    AddingObject: 'forms.addingObject',
    ObjectUpdated: 'forms.objectUpdated',
    ObjectNameHasBeenFormatted: 'forms.objectNameHasBeenFormatted',
    Password: 'forms.password',
    ReEnterPassword: 'forms.reEnterPassword',
    PasswordsDoNotMatch: 'forms.passwordsDoNotMatch',
    Name: 'forms.name',
    Email: 'forms.email',
};

export default Forms;
