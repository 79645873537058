import { api } from '@orbica/platform-sdk-dev';
import _isNil from 'lodash/isNil';

export interface ISettingsUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    role?: api.IRole;
}

export function getFullName(user: ISettingsUser): string {
    return !_isNil(user) ? `${user.firstName} ${user.lastName}` : '';
}
