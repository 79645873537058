import React from 'react';
import { useSelector } from 'react-redux';
import {
    ServiceCardSkeleton,
    ServiceCatalogCard,
} from '@Components/Cards/ServiceCards';
import { IAddServiceCatalogService } from '@Data/solutions/Interfaces';
import {
    createServiceCatalogServicesState,
    projectHasDatabaseState,
} from '@Data/solutions/Reducer';

interface ISelectServiceProps {
    onSelectService: (service: IAddServiceCatalogService) => void;
}

const SelectService = (props: ISelectServiceProps) => {
    const createServiceCatalogServices = useSelector(
        createServiceCatalogServicesState
    );

    const hasDatabase = useSelector(projectHasDatabaseState);

    const isLoading = createServiceCatalogServices.length === 0;

    const serviceCatalogHtml = isLoading ? (
        <>
            <ServiceCardSkeleton />
            <ServiceCardSkeleton />
            <ServiceCardSkeleton />
        </>
    ) : (
        createServiceCatalogServices.map((s) => {
            if (hasDatabase && s.chart === 'postgrescluster') {
                return null;
            }
            return (
                <ServiceCatalogCard
                    name={s.displayName}
                    info={s.info}
                    onClick={() => props.onSelectService(s)}
                    key={`${s.name}-card`}
                    logo={s.logo}
                    documentationUrl={s.documentationUrl}
                />
            );
        })
    );

    return <>{serviceCatalogHtml}</>;
};

export default SelectService;
