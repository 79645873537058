import { Skeleton } from '@mui/material';
import React from 'react';
import { BreadcrumbsComponent } from './BreadcrumbsComponent';
import { BreadcrumbsType, IBreadcrumbsTitleProps } from './Interfaces';

/**
 * Displays the breadcrumbs of the location, and the project's name.
 * Intended to be used in the main div for Project views (not the header).
 */
export const BodyBreadcrumbsTitle = (props: IBreadcrumbsTitleProps) => {
    const {
        selectedOrganizationId,
        selectedWorkspace,
        selectedProject,
        isLoading,
        ...divProps
    } = props;

    return isLoading || !selectedProject || !selectedWorkspace ? (
        <Skeleton variant="text" animation="wave" width={350} height={59} />
    ) : (
        <div {...divProps} data-cy="breadcrumb">
            <BreadcrumbsComponent
                type={BreadcrumbsType.BODY}
                displayRootWorkspace
                selectedOrganizationId={selectedOrganizationId}
                selectedWorkspace={selectedWorkspace}
            />
        </div>
    );
};
