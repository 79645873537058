import { ReactComponent as PlusIcon } from '@Icons/lg/plus.svg';
import ColorStyles from '@Styles/colors.scss';
import TypographyVariables from '@Styles/typography.scss';
import { styled } from '@mui/material';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { getRoleProps } from '@Components/MuiRouterLink';
import Styles from './Cards.scss';
import { ICreateCardProps } from './Interfaces';
import StyledCard from './StyledCard';

const StyledCreateCard = styled(StyledCard)`
    background: transparent;
    position: relative;
    overflow: visible;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
`;

const CreateIconContainer = styled('div', {
    shouldForwardProp: (p) => p !== 'parentHeight',
})(
    ({ parentHeight }) => `
    background: ${ColorStyles.matcha};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    filter: none !important;

    height: ${parentHeight / 4}px;
    width: ${parentHeight / 4}px;
    border-radius: ${parentHeight / 4}px;
    margin-bottom: ${parentHeight / 12}px;

    svg {
        path {
            fill: #fff;
        }
    }
`
);

const CreateCard = (props: ICreateCardProps) => {
    const {
        cardClasses,
        titleClasses,
        title,
        onClick,
        href,
        relative,
        className,
        ...buttonProps
    } = props;

    const roleClasses = getRoleProps(onClick, href, relative);

    const titleCss = titleClasses ? titleClasses : TypographyVariables.h4;

    const { ref, height } = useResizeDetector();

    return (
        <StyledCreateCard
            className={cx(Styles.cardContainer, className)}
            {...roleClasses}
            {...buttonProps}
            ref={ref}
            // style={style}
        >
            <div className={cx(Styles.createCard, cardClasses)}>
                <CreateIconContainer parentHeight={height}>
                    <PlusIcon />
                </CreateIconContainer>
                <span className={titleCss}>{title}</span>
            </div>
        </StyledCreateCard>
    );
};

export default CreateCard;
