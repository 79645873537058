import { RelativeRoutingType } from 'react-router-dom';
import { RouterLink } from '@Components/MuiRouterLink';

export interface ButtonRouterProps {
    href?: string;
    /**
     * Used in conjunction with the href prop.
     * See https://reactrouter.com/en/main/components/link#relative
     */
    relative?: RelativeRoutingType;
    /**
     * Used instead of the href prop if you want to navigate programmatically.
     */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    target?: string;
    rel?: string;
}

/**
 * Helper function for button components to set their role and behaviour.
 * Based on whether the href or onClick prop is set.
 */
export function getRoleProps(onClick?, href?: string, relative?): object {
    return href
        ? { component: RouterLink, role: 'a', to: href, relative: relative }
        : { component: 'button', role: 'button', onClick: onClick };
}
