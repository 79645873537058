import cx from 'classnames';
import { InputSize } from './Interfaces';
import Styles from './TextInput.scss';

export function getTextboxClass(size: InputSize): string {
    switch (size) {
        case InputSize.LARGE:
            return Styles.textInputLarge;
        case InputSize.MEDIUM:
            return Styles.textInputMedium;
        case InputSize.SMALL:
            return Styles.textInputSmall;
    }
}

export function getRootInputClass(
    size: InputSize,
    multiline?: boolean,
    error?: boolean,
    useMonospace?: boolean
): string {
    switch (size) {
        case InputSize.LARGE:
            return cx(
                Styles.rootInputLarge,
                !multiline ? Styles.rootInputLargeText : null,
                error ? Styles.rootInputError : null,
                useMonospace ? Styles.monospace : null
            );
        case InputSize.MEDIUM:
            return cx(
                Styles.rootInputMedium,
                !multiline ? Styles.rootInputMediumText : null,
                error ? Styles.rootInputError : null,
                useMonospace ? Styles.monospace : null
            );
        case InputSize.SMALL:
            return cx(
                Styles.rootInputSmall,
                !multiline ? Styles.rootInputSmallText : null,
                error ? Styles.rootInputError : null,
                useMonospace ? Styles.monospace : null
            );
    }
}
