import { MenuItemProps, MenuItem as MuiMenuItem } from '@mui/material';
import React from 'react';
import { RelativeRoutingType } from 'react-router-dom';
import { RouterLink } from '../MuiRouterLink';

/**
 * @noInheritDoc
 */
export interface IMenuItemProps extends MenuItemProps {
    color?: string;
    href?: string;
    relative?: RelativeRoutingType;
}

export const MenuItem = (props: IMenuItemProps) => {
    const { href, ...menuItemProps } = props;

    const roleProps = href
        ? {
              component: RouterLink,
              role: 'a',
              to: href,
              relative: props.relative,
          }
        : { component: 'li', role: 'menuitem' };

    return (
        <MuiMenuItem
            {...roleProps}
            {...menuItemProps}
            sx={{
                color: props.color,
            }}
        />
    );
};
