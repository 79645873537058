import LayoutStyles from '@Styles/layout.scss';
import { Skeleton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { InputSize, SearchInput } from '@Components/Inputs';
import { IOrganization, OrganizationsApi } from '@Data/organizations';
import { IWorkspace } from '@Data/workspaces';
import { Routes } from '@Services/navigation';
import OrganizationSelector from './OrganizationSelector/OrganizationSelector';
import Styles from './WorkspacesDisplay.scss';
import WorkspacesList from './WorkspacesList';

interface IWorkspacesDisplayProps {
    isLoading: boolean;
    workspaces: Array<IWorkspace>;
    selectedWorkspaceId: string;
    organizations: Array<IOrganization>;
    selectedOrganizationId: string;
    onOpenCreateWorkspace: () => void;
}

const WorkspacesDisplay: React.FC<IWorkspacesDisplayProps> = (props) => {
    const navigate = useNavigate();
    const searchInput = props.isLoading ? (
        <Skeleton width={'100%'} height={50} />
    ) : (
        <SearchInput placeholder="Search projects" size={InputSize.LARGE} />
    );

    const handleOrganizationChange = (organizationId: string) => {
        const org = props.organizations.find((o) => o.id === organizationId);
        OrganizationsApi.setSelectedOrganization(org, () => {
            const url = Routes.workspaces.getUrl(organizationId);
            navigate(url);
        });
    };

    return (
        <div className={Styles.sidebarContainer}>
            <OrganizationSelector
                selectedOrganizationId={props.selectedOrganizationId}
                organizations={props.organizations}
                isLoading={props.isLoading}
                onChange={handleOrganizationChange}
                selectClassname={
                    LayoutStyles.sidebarOrganizationSelectorContainer
                }
            />
            <div className={LayoutStyles.sidebarDividerVertical} />
            {/* hidden until we decide how and what to search */}
            {/* <div className={Styles.searchInput}>
                {searchInput}
            </div> */}

            <div className={Styles.workspacesContainer}>
                <WorkspacesList
                    workspaces={props.workspaces}
                    selectedOrganizationId={props.selectedOrganizationId}
                    selectedWorkspaceId={props.selectedWorkspaceId}
                    isLoading={props.isLoading}
                    onCreateWorkspace={props.onOpenCreateWorkspace}
                />
            </div>
        </div>
    );
};

export default WorkspacesDisplay;
