import bgImg from '@Images/mountain-print.png';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Routes, View, tryGetCurrentView } from '@Services/navigation';
import Styles from './Header.scss';
import { DefaultTabs } from './HeaderTabs/DefaultTabs';
import { ProjectTabs } from './HeaderTabs/ProjectTabs';
import { IHeaderProps } from './Interfaces';
import { LogoIcon } from './LeftPanels';
import { NotificationsDrawer, UserMenu } from './RightPanels';

export const Header: React.FC<IHeaderProps> = (props) => {
    const currentView = tryGetCurrentView(props.headerType);
    let leftPanel, tabs;

    const params = useParams();

    const organizationId = params.organizationId;
    const workspaceId = params.workspaceId;

    const homeHref = Routes.workspaces.getUrl(organizationId, workspaceId);

    switch (currentView) {
        case View.WORKSPACES:
        case View.MARKETPLACE:
        case View.DEVKIT:
            tabs = <DefaultTabs currentView={currentView} />;
            leftPanel = <LogoIcon href={homeHref} />;
            break;
        case View.ORGANIZATION_SETTINGS:
            tabs = null;
            leftPanel = <LogoIcon href={homeHref} />;
            break;
        case View.PROJECT_OVERVIEW:
        case View.PROJECT_DATA:
        case View.PROJECT_ANALYSIS:
        case View.PROJECT_WORKFLOW:
        case View.PROJECT_DESIGN:
            tabs = <ProjectTabs currentView={currentView} />;
            leftPanel = <LogoIcon href={homeHref} />;
            break;
        default:
            break;
    }

    // dynamically add hamburger menu if tablet size

    return (
        <div className={Styles.header}>
            <img src={bgImg} className={Styles.bgImg} />
            <div className={Styles.leftPanelContainer}>{leftPanel}</div>

            {tabs}

            <div className={Styles.rightPanelContainer}>
                <NotificationsDrawer />
                <UserMenu />
            </div>
        </div>
    );
};
