import { api } from '@orbica/platform-sdk-dev';
import _isNil from 'lodash/isNil';
import { ISettingsUser } from '@Components/Settings';
import { IOrganization } from '@Data/organizations';
import { IOrgUserDetails } from '@Data/organizations/Interfaces';
import { IProject } from '@Data/projects';
import { IWorkspace } from '@Data/workspaces';

export const getCanCurrentUserEditSelectedOrg = (
    selectedOrganizationUserRoles: api.IUserOrganizationRole[],
    selectedOrganization: IOrganization
) => {
    if (_isNil(selectedOrganization) || _isNil(selectedOrganizationUserRoles)) {
        return false;
    }

    const userOrgRole = selectedOrganizationUserRoles.find(
        (roles) => roles.organization_id === selectedOrganization.id
    );

    if (_isNil(userOrgRole)) {
        return false;
    }

    const editingRoles = ['Admin'];
    return editingRoles.includes(userOrgRole.role.name);
};

export const getCanCurrentUserEditSelectedWorkspace = (
    selectedOrganizationUserRoles: api.IUserOrganizationRole[],
    selectedWorkspaceUserRoles: api.IUserWorkspaceRole[],
    selectedOrganization: IOrganization,
    selectedWorkspace: IWorkspace
): boolean => {
    if (
        _isNil(selectedOrganization) ||
        _isNil(selectedOrganizationUserRoles) ||
        _isNil(selectedWorkspace) ||
        _isNil(selectedWorkspaceUserRoles)
    ) {
        return false;
    }

    if (
        getCanCurrentUserEditSelectedOrg(
            selectedOrganizationUserRoles,
            selectedOrganization
        )
    ) {
        return true;
    }

    const userWorkspaceRole = selectedWorkspaceUserRoles.find(
        (roles) => roles.workspace_id === selectedWorkspace.id
    );

    if (_isNil(userWorkspaceRole)) {
        return false;
    }

    const editingRoles = ['Admin'];
    return editingRoles.includes(userWorkspaceRole.role.name);
};

export const getCanCurrentUserEditSelectedProject = (
    selectedOrganizationUserRoles: api.IUserOrganizationRole[],
    selectedWorkspaceUserRoles: api.IUserWorkspaceRole[],
    selectedProjectUserRoles: api.IUserProjectRole[],
    selectedOrganization: IOrganization,
    selectedWorkspace: IWorkspace,
    selectedProject: IProject
): boolean => {
    if (
        _isNil(selectedOrganization) ||
        _isNil(selectedOrganizationUserRoles) ||
        _isNil(selectedWorkspace) ||
        _isNil(selectedWorkspaceUserRoles) ||
        _isNil(selectedProject) ||
        _isNil(selectedProjectUserRoles)
    ) {
        return false;
    }

    if (
        getCanCurrentUserEditSelectedWorkspace(
            selectedOrganizationUserRoles,
            selectedWorkspaceUserRoles,
            selectedOrganization,
            selectedWorkspace
        )
    ) {
        return true;
    }

    const userProjectRole = selectedProjectUserRoles.find(
        (roles) => roles.project_id === selectedProject.id
    );

    if (_isNil(userProjectRole)) {
        return false;
    }

    const editingRoles = ['Admin'];
    return editingRoles.includes(userProjectRole.role.name);
};

export function mapUserDetails(
    entityUsers: api.IWorkspaceUserRole[] | api.IProjectUserRole[],
    orgUsers: IOrgUserDetails[]
): Array<ISettingsUser> {
    return entityUsers.map((wspUser) => {
        const orgUser = orgUsers.find(
            (orgUser) => orgUser.id === wspUser.user_id
        );
        return {
            id: wspUser.user_id,
            email: orgUser.email,
            firstName: orgUser.first_name,
            lastName: orgUser.last_name,
            role: wspUser.role,
        };
    });
}
