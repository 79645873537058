import { api } from '@orbica/platform-sdk-dev';

export interface IProject extends api.IProject {
    tags?: Array<string>;
    workspaceColor?: string;
    description?: string;
}

export enum ProjectDisplayType {
    CARD,
    LIST,
}

export enum ProjectSortType {
    CREATED,
    UPDATED,
    NAME,
}
