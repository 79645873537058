import React from 'react';
import { useIntl } from 'react-intl';
import { Tab, Tabs } from '@Components/Tabs';
import { ViewKeys } from '@Services/i18n/keys';
import { Routes, View } from '@Services/navigation';
import { IHeaderTabsProps } from '../Interfaces';

/**
 * Buttons that display the currently selected view, and provide options to navigate to other views in the Project views.
 * Intended to be used in the header.
 */
export const ProjectTabs: React.FC<IHeaderTabsProps> = ({ currentView }) => {
    const intl = useIntl();
    const overviewUrl = Routes.project.overview.getRelativeUrl();
    // const dataUrl = Routes.project.data.getRelativeUrl();

    return (
        <Tabs value={currentView}>
            <Tab
                label={intl.formatMessage({
                    id: ViewKeys.ProjectOverview,
                    defaultMessage: 'Overview',
                })}
                value={View.PROJECT_OVERVIEW}
                href={overviewUrl}
                relative="path"
            />
            {/* <Tab
                label={intl.formatMessage({ id: ViewKeys.ProjectData, defaultMessage: "Data" })}
                value={View.PROJECT_DATA}
                href={dataUrl}
                relative="path"
            />
            <Tab
                label={intl.formatMessage({ id: ViewKeys.ProjectAnalysis, defaultMessage: "Analysis" })}
                value={View.PROJECT_ANALYSIS}
                href={'../overview'}
                relative="path"
            />
            <Tab
                label={intl.formatMessage({ id: ViewKeys.ProjectWorkflow, defaultMessage: "Workflow" })}
                value={View.PROJECT_WORKFLOW}
                href={'../overview'}
                relative="path"
            />
            <Tab
                label={intl.formatMessage({ id: ViewKeys.ProjectDesign, defaultMessage: "Design" })}
                value={View.PROJECT_DESIGN}
                href={'../overview'}
                relative="path"
            /> */}
        </Tabs>
    );
};
