import { api } from '@orbica/platform-sdk-dev';
import { IOrgUserDetails } from '@orbica/platform-sdk-dev/dist/src/entities';
import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';
import { ISettingsUser } from '@Components/Settings';
import { ToastType } from '@Components/Toasts';
import { mapUserDetails } from '@Data/auth/Helpers';
import { createToast } from '@Services/notifications';
import store from '@Services/redux/reduxStore';
import { OrbicaSdkClient } from '@Services/sdk/OrbicaSdkClient';
import { handleFailedSdkResponse } from '@Services/sdk/helpers';
import { combineWorkspaceAndInfo, getWorkspaceColor } from './Helpers';
import { IWorkspace } from './Interfaces';
import { WorkspacesActions } from './Reducer';

const Api = {
    reset: () => {
        store.dispatch(WorkspacesActions.reset());
    },
    loadWorkspaces: async (organizationId: string): Promise<IWorkspace[]> => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const response = await workspaceApi.all(organizationId, true);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const workspaces = response.data as Array<{
            object: api.IWorkspace;
            info: { projectCount: number };
        }>;
        let mappedWorkspaces: Array<IWorkspace> = workspaces.map(
            (workspaceObj, i) => {
                return {
                    ...combineWorkspaceAndInfo(
                        workspaceObj.object,
                        workspaceObj.info
                    ),
                    color: getWorkspaceColor(i),
                    display_name:
                        workspaceObj.object.display_name &&
                        workspaceObj.object.display_name !== ''
                            ? workspaceObj.object.display_name
                            : workspaceObj.object.name,
                };
            }
        );

        mappedWorkspaces = _orderBy(
            mappedWorkspaces,
            (w) => new Date(w.created_at),
            'asc'
        );

        store.dispatch(WorkspacesActions.updateWorkspaces(mappedWorkspaces));

        return mappedWorkspaces;
    },
    setSelectedWorkspace: (
        workspace: IWorkspace,
        callback?: (selectedWorkspace: IWorkspace) => void
    ) => {
        store.dispatch(WorkspacesActions.setSelectedWorkspace(workspace));

        if (callback) {
            callback(workspace);
        }
    },
    loadWorkspaceById: async (workspaceId: string): Promise<IWorkspace> => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const response = await workspaceApi.get(workspaceId, true);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const { object, info } = response.data as {
            object: api.IWorkspace;
            info: { projectCount: number };
        };

        const parsedWorkspace = combineWorkspaceAndInfo(object, info);
        if (
            _isNil(parsedWorkspace.display_name) ||
            parsedWorkspace.display_name === ''
        ) {
            parsedWorkspace.display_name = parsedWorkspace.name;
        }

        store.dispatch(WorkspacesActions.setSelectedWorkspace(parsedWorkspace));

        return object;
    },
    createWorkspace: async (
        workspaceName: string,
        workspaceDisplayName: string,
        selectedOrganizationId: string
    ): Promise<IWorkspace> => {
        const createWorkspaceParams = {
            name: workspaceName,
            organization_id: selectedOrganizationId,
            display_name: workspaceDisplayName,
        };

        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const response = await workspaceApi.create(createWorkspaceParams);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const newWorkspace = response.data as IWorkspace;

        return newWorkspace;
    },
    editWorkspaceDisplayName: async (
        existingWorkspace: IWorkspace,
        displayName: string
    ): Promise<IWorkspace> => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();

        const updateWorkspaceBody = {
            ...existingWorkspace,
            display_name: displayName,
        } as api.IWorkspace;

        const response = await workspaceApi.update(
            updateWorkspaceBody,
            existingWorkspace.id
        );
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const editedWorkspace = response.data as IWorkspace;
        return editedWorkspace;
    },
    deleteWorkspace: async (workspace: IWorkspace): Promise<boolean> => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const result = await workspaceApi.delete(workspace);
        if (result.status === 'failed') {
            handleFailedSdkResponse(result.data);
            return false;
        }
        return true;
    },
    updateWorkspaceUserRole: async (
        workspace: IWorkspace,
        userId: string,
        role: api.IRole
    ) => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const result = await workspaceApi.updateUserRole({
            user_id: userId,
            workspace_id: workspace.id,
            role_id: role.id,
        });
        if (result.status === 'failed') {
            handleFailedSdkResponse(result.data);
            return false;
        }
        return true;
    },
    getWorkspaceUsers: async (
        workspaceId: string,
        organizationUsers: IOrgUserDetails[]
    ) => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const response = await workspaceApi.getWorkspaceUsers(workspaceId);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const mappedUsers = mapUserDetails(
            response.data as api.IWorkspaceUserRole[],
            organizationUsers
        );

        store.dispatch(WorkspacesActions.updateWorkspaceUsers(mappedUsers));
        return mappedUsers;
    },
    getWorkspaceRoles: async () => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const response = await workspaceApi.getWorkspaceRoles();
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }

        store.dispatch(
            WorkspacesActions.setRoles(response.data as api.IRole[])
        );
    },
    addUsersToWorkspace: async (
        users: ISettingsUser[],
        workspaceId: string
    ) => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();

        for (const user of users) {
            const resp = await workspaceApi.addUser({
                user_id: user.id,
                workspace_id: workspaceId,
                role_id: user.role.id,
            });

            if (resp.status === 'failed') {
                const data = resp.data as { message: string };
                createToast(data.message, ToastType.ERROR);
            }
        }
    },
    removeUserFromWorkspace: async (
        user: ISettingsUser,
        workspaceId: string
    ) => {
        const workspaceApi = await OrbicaSdkClient.instance.getWorkspaceApi();
        const response = await workspaceApi.deleteUser({
            user_id: user.id,
            workspace_id: workspaceId,
        });
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return false;
        }
        return true;
    },
};

export default Api;
