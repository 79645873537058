import React from 'react';
import { IProject } from '@Data/projects';
import { IWorkspace } from '@Data/workspaces';

/**
 * @noInheritDoc
 */
export interface IBreadcrumbsProps
    extends React.HTMLAttributes<HTMLDivElement> {
    selectedOrganizationId: string;
    selectedWorkspace: IWorkspace;
}

export interface IBreadcrumbsComponentProps extends IBreadcrumbsProps {
    type: BreadcrumbsType;
    displayRootWorkspace?: boolean;
}

export interface IBreadcrumbsTitleProps extends IBreadcrumbsProps {
    selectedProject: IProject;
    isLoading?: boolean;
}

export enum BreadcrumbsType {
    HEADER,
    BODY,
}
