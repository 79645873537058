const Projects = {
    CreateANewProject: 'projects.createANewProject',
    ProjectDetails: 'projects.projectDetails',
    ProjectName: 'projects.projectName',
    Location: 'projects.location',
    Description: 'projects.description',
    MetadataStandard: 'projects.metadataStandard',
    MetadataOrganizational: 'projects.metadataOrganizational',
    DefaultProjection: 'projects.defaultProjection',
    Name: 'projects.name',
    Tags: 'projects.tags',
    Updated: 'projects.updated',
    Created: 'projects.created',
    ProjectsList: 'projects.projectsList',
    Solutions: 'projects.solutions',
    AddASolution: 'projects.addASolution',
    AddSolution: 'projects.addSolution',
    DescriptionPlaceholder: 'projects.descriptionPlaceholder',
    AddMembers: 'projects.addMembers',
    SetProjectRole: 'projects.setProjectRole',
    ClusterLocation: 'projects.clusterLocation',
    SelectAClusterLocation: 'projects.selectAClusterLocation',
};

export default Projects;
