import BorderVariables from '@Styles/borders.scss';
import ColorVariables from '@Styles/colors.scss';
import ElevationVariables from '@Styles/elevations.scss';
import { createTheme } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
    interface Palette {
        matcha: Palette['primary'];
        mint: Palette['primary'];
        avocado: Palette['primary'];

        purple: Palette['secondary'];
        violet: Palette['secondary'];
        darkBlue: Palette['secondary'];
        blue: Palette['secondary'];
        darkBeige: Palette['secondary'];
        stone: Palette['secondary'];
        darkGreen: Palette['secondary'];
        teal: Palette['secondary'];
        wine: Palette['secondary'];
        red: Palette['secondary'];
        suntan: Palette['secondary'];
        mandarin: Palette['secondary'];
        currant: Palette['secondary'];
        salmon: Palette['secondary'];
        gold: Palette['secondary'];
        yellow: Palette['secondary'];

        greyscale40: Palette['secondary'];
    }
    interface PaletteOptions {
        matcha: PaletteOptions['primary'];
        mint: PaletteOptions['primary'];
        avocado: PaletteOptions['primary'];

        purple: PaletteOptions['secondary'];
        violet: PaletteOptions['secondary'];
        darkBlue: PaletteOptions['secondary'];
        blue: PaletteOptions['secondary'];
        darkBeige: PaletteOptions['secondary'];
        stone: PaletteOptions['secondary'];
        darkGreen: PaletteOptions['secondary'];
        teal: PaletteOptions['secondary'];
        wine: PaletteOptions['secondary'];
        red: PaletteOptions['secondary'];
        suntan: PaletteOptions['secondary'];
        mandarin: PaletteOptions['secondary'];
        currant: PaletteOptions['secondary'];
        salmon: PaletteOptions['secondary'];
        gold: PaletteOptions['secondary'];
        yellow: PaletteOptions['secondary'];

        greyscale40: PaletteOptions['secondary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        matcha: true;
        mint: true;
        avocado: true;

        purple: true;
        violet: true;
        darkBlue: true;
        blue: true;
        darkBeige: true;
        stone: true;
        darkGreen: true;
        teal: true;
        wine: true;
        red: true;
        suntan: true;
        mandarin: true;
        currant: true;
        salmon: true;
        gold: true;
        yellow: true;

        greyscale40: true;
    }
}

declare module '@mui/material/Tabs' {
    interface TabsPropsIndicatorColorOverrides {
        avocado: true;
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        matcha: true;
    }
}

declare module '@mui/base/SelectUnstyled' {
    interface SelectUnstyledComponentsPropsOverrides {
        icon?: React.ReactElement;
    }
}

const theme = createTheme({
    transitions: {
        duration: {
            standard: 150,
        },
    },
    typography: {
        fontFamily: 'Nunito Sans',
    },
    palette: {
        matcha: {
            main: ColorVariables.matcha,
            contrastText: '#fff',
        },
        mint: {
            main: ColorVariables.mint,
            contrastText: '#fff',
        },
        avocado: {
            main: ColorVariables.avocado,
            contrastText: '#fff',
        },
        purple: {
            main: ColorVariables.purple,
            contrastText: '#fff',
        },
        violet: {
            main: ColorVariables.violet,
            contrastText: '#fff',
        },
        darkBlue: {
            main: ColorVariables.darkBlue,
            contrastText: '#fff',
        },
        blue: {
            main: ColorVariables.blue,
            contrastText: '#fff',
        },
        darkBeige: {
            main: ColorVariables.darkBeige,
            contrastText: '#fff',
        },
        stone: {
            main: ColorVariables.stone,
            contrastText: ColorVariables.greyscale90,
        },
        darkGreen: {
            main: ColorVariables.darkGreen,
            contrastText: '#fff',
        },
        teal: {
            main: ColorVariables.teal,
            contrastText: '#fff',
        },
        wine: {
            main: ColorVariables.wine,
            contrastText: '#fff',
        },
        red: {
            main: ColorVariables.red,
            contrastText: '#fff',
        },
        suntan: {
            main: ColorVariables.suntan,
            contrastText: '#fff',
        },
        mandarin: {
            main: ColorVariables.mandarin,
            contrastText: ColorVariables.greyscale90,
        },
        currant: {
            main: ColorVariables.currant,
            contrastText: '#fff',
        },
        salmon: {
            main: ColorVariables.salmon,
            contrastText: ColorVariables.greyscale90,
        },
        gold: {
            main: ColorVariables.gold,
            contrastText: ColorVariables.greyscale90,
        },
        yellow: {
            main: ColorVariables.yellow,
            contrastText: ColorVariables.greyscale90,
        },
        greyscale40: {
            main: ColorVariables.greyscale40,
            contrastText: '#fff',
        },
    },
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: ElevationVariables.light8,
                    borderRadius: '6px',
                    border: BorderVariables.subtle,
                    background: '#fff',
                    padding: '6px 16px',
                },
                list: {
                    padding: 0,
                    margin: 0,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    padding: '8px 0',
                    margin: '4px 0',
                    color: ColorVariables.greyscale90,
                    textDecoration: 'underline',
                    textDecorationColor: 'transparent',
                    textUnderlineOffset: '4px',
                    textDecorationThickness: '1px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: ColorVariables.darkGreen,
                        textDecoration: 'underline',
                        textDecorationColor: ColorVariables.mint,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    boxShadow: 'none',
                    background: '#fff',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                    padding: '12px 16px',
                    boxShadow: 'none',
                    borderRadius: '6px',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    background: 'transparent',

                    '&.Mui-expanded': {
                        margin: 0,
                    },

                    '&:before': {
                        background: 'none',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: 0,
                    minHeight: 0,
                    background: 'transparent',
                    '&.Mui-expanded': {
                        margin: 0,
                        minHeight: 0,
                    },
                },
                content: {
                    margin: 0,
                    '&.Mui-expanded': {
                        margin: 0,
                        minHeight: 0,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0,
                    background: 'transparent',
                },
            },
        },

        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                        backgroundColor: ColorVariables.greyscale20,
                        borderRadius: '4px',
                        padding: '2px',

                        '&:vertical': {
                            width: '8px',
                        },

                        '&:horizontal': {
                            height: '8px',
                        },
                    },
                    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb':
                        {
                            borderRadius: '5px',
                            backgroundColor: ColorVariables.greyscale60,
                            border: `5px solid ${ColorVariables.greyscale60}`,
                        },
                    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
                        {
                            backgroundColor: ColorVariables.greyscale60,
                        },
                    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
                        {
                            backgroundColor: ColorVariables.greyscale60,
                        },
                    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
                        {
                            backgroundColor: ColorVariables.greyscale60,
                        },
                },
                textarea: {
                    fontFamily: 'Nunito Sans',
                },
            },
        },
    },
});

export default theme;
