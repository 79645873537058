import { ButtonUnstyled } from '@mui/base';
import { styled } from '@mui/material';
import _isNil from 'lodash/isNil';
import React from 'react';
import { RelativeRoutingType } from 'react-router-dom';
import { getRoleProps } from '@Components/MuiRouterLink';
import Styles from './Table.scss';

interface ITableTdButtonProps {
    rowData: any;
    onRowClick?: (data: any) => void;
    getRowHref?: (data: any) => string;
    rowHrefRelativity?: RelativeRoutingType;
    children?: React.ReactNode;
}

const getBtnRoleProps = (
    originalData: any,
    onRowClick?: (data: any) => void,
    getRowHref?: (data: any) => string,
    rowHrefRelativity?: RelativeRoutingType
) => {
    let href = null;
    let onClick = null;
    if (!_isNil(getRowHref)) {
        href = getRowHref(originalData);
    } else if (!_isNil(onRowClick)) {
        onClick = onRowClick(originalData);
    } else {
        return null;
    }

    return getRoleProps(onClick, href, rowHrefRelativity);
};

const TableButton = styled(ButtonUnstyled)`
    width: 100%;
    border: none;
    padding: ${Styles.tableCellPadding};
    background: transparent;
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    color: inherit;
    height: inherit;
    font-weight: inherit;

    &:hover {
        background: transparent;
    }

    ::after {
        content: '';
        line-height: inherit;
        padding: ${Styles.tableCellPadding} 0;
    }
`;

export const TableTdButton = (props: ITableTdButtonProps) => {
    return (
        <TableButton
            {...getBtnRoleProps(
                props.rowData,
                props.onRowClick,
                props.getRowHref,
                props.rowHrefRelativity
            )}
        >
            {props.children}
        </TableButton>
    );
};
