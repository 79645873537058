import LayoutStyles from '@Styles/layout.scss';
import React from 'react';
import { InputSize } from '@Components/Inputs';
import { LabelWithIconTooltip } from '@Components/Label';
import { Select, SelectOption, SelectType } from '@Components/Select';
import { IAnalyticsResource } from '@Data/solutions/Interfaces';
import Styles from './ServiceInformation.scss';

interface IAnalyticsResourceLimitsSelectorProps {
    options: Array<IAnalyticsResource>;
    selectedResourceLimit: IAnalyticsResource;
    onChange: (option: IAnalyticsResource) => void;
}

export const AnalyticsResourceLimitsSelector = (
    props: IAnalyticsResourceLimitsSelectorProps
) => {
    return (
        <div className={LayoutStyles.dialogInputMedium}>
            {/* 
            30/10/23 - limit resource options for easier costing
            <LabelWithIconTooltip
                label={'Resource Limits'}
                tooltipTitle={
                    <div className={Styles.resourceLimitsTooltipContainer}>
                        <span>
                            The maximum performance that your Solution can
                            achieve.
                        </span>
                        <span>
                            <b>General Purpose</b> options are ideal for
                            applications that use these resources in equal
                            proportions, such as web servers.
                        </span>
                        <span>
                            <b>Compute Optimised</b> options are ideal for
                            applications that benefit from high performance
                            processors, such as scientific modeling or machine
                            learning inference.
                        </span>
                        <span>
                            <b>Memory Optimised</b> options are designed to
                            deliver fast performance for workloads that process
                            large data sets in memory.
                        </span>
                    </div>
                }
                required
            /> */}
            <Select
                selectType={SelectType.PRIMARY}
                size={InputSize.LARGE}
                onChange={(e, v) => props.onChange(v)}
                value={
                    props.selectedResourceLimit
                        ? props.selectedResourceLimit.label
                        : 'Select Resource Limit'
                }
                label="Resource Limits"
            >
                {props.options.map((resource) => {
                    return (
                        <SelectOption key={resource.label} value={resource}>
                            {resource.label}
                        </SelectOption>
                    );
                })}
            </Select>
        </div>
    );
};
