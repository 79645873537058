import ColorsStyles from '@Styles/colors.scss';
import { Menu } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dialog, DialogFooter, DialogHeader } from '@Components/Dialog';
import { MenuItem } from '@Components/Menu';
import { canCurrentUserEditWorkspaceState } from '@Data/auth';
import { selectedOrganizationState } from '@Data/organizations';
import { selectedWorkspaceState } from '@Data/workspaces';
import { EntityKeys, FormKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';

interface IWorkspaceMenuProps {
    onClose: () => void;
    onDelete: () => void;
    anchorEl?: HTMLElement;
    workspaceName?: string;
}

const WorkspaceMenu = (props: IWorkspaceMenuProps) => {
    const intl = useIntl();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const canCurrentUserEditWorkspace = useSelector(
        canCurrentUserEditWorkspaceState
    );
    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);

    const handleDelete = async () => {
        setIsSubmitting(true);
        await props.onDelete();
        setIsDeleteDialogOpen(false);
        setIsSubmitting(false);
    };

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
        props.onClose();
    };

    const workspaceStr = intl.formatMessage({
        id: EntityKeys.Workspace,
        defaultMessage: 'workspace',
    });

    const workspaceSettingsUrl = Routes.settings.workspaceSettings.getUrl(
        selectedOrganization?.id,
        selectedWorkspace?.id
    );

    return (
        <React.Fragment>
            {canCurrentUserEditWorkspace && (
                <Menu
                    open={!_isNil(props.anchorEl)}
                    anchorEl={props.anchorEl}
                    onClose={props.onClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    autoFocus={false}
                >
                    <MenuItem href={workspaceSettingsUrl}>
                        {intl.formatMessage(
                            {
                                id: FormKeys.EditObject,
                                defaultMessage: 'Edit workspace',
                            },
                            { object: workspaceStr }
                        )}
                    </MenuItem>
                    <MenuItem
                        onClick={openDeleteDialog}
                        color={ColorsStyles.red}
                    >
                        {intl.formatMessage(
                            {
                                id: FormKeys.DeleteObject,
                                defaultMessage: 'Delete workspace',
                            },
                            { object: workspaceStr }
                        )}
                    </MenuItem>
                </Menu>
            )}
            <Dialog open={isDeleteDialogOpen}>
                <DialogHeader
                    onClose={() => setIsDeleteDialogOpen(false)}
                    title={intl.formatMessage(
                        {
                            id: FormKeys.ConfirmDelete,
                            defaultMessage: `Are you sure you want to delete ${props.workspaceName}?`,
                        },
                        {
                            objectName: props.workspaceName
                                ? props.workspaceName
                                : '',
                        }
                    )}
                />
                <DialogFooter
                    onCancel={() => setIsDeleteDialogOpen(false)}
                    submitText={intl.formatMessage({
                        id: FormKeys.Delete,
                        defaultMessage: 'Delete',
                    })}
                    onSubmit={handleDelete}
                    isSubmitting={isSubmitting}
                    submittingText={intl.formatMessage(
                        {
                            id: FormKeys.DeletingObject,
                            defaultMessage: 'Deleting workspace',
                        },
                        { object: workspaceStr }
                    )}
                />
            </Dialog>
        </React.Fragment>
    );
};

export default WorkspaceMenu;
