import { Skeleton } from '@mui/material';
import React from 'react';
import { RevealingTitle } from '@Components/Fancy';
import Styles from './Breadcrumbs.scss';
import { BreadcrumbsComponent } from './BreadcrumbsComponent';
import { BreadcrumbsType, IBreadcrumbsTitleProps } from './Interfaces';

/**
 * Displays the project's name, and the breadcrumbs on hover.
 * Intended to be used in the header for Project views.
 */
export const HeaderBreadcrumbsTitle = (props: IBreadcrumbsTitleProps) => {
    const {
        selectedOrganizationId,
        selectedWorkspace,
        selectedProject,
        isLoading,
        ...divProps
    } = props;

    return isLoading ? (
        <Skeleton variant="text" animation="wave" width={200} height={28} />
    ) : (
        <RevealingTitle
            hiddenContent={
                <BreadcrumbsComponent
                    type={BreadcrumbsType.HEADER}
                    displayRootWorkspace
                    selectedOrganizationId={selectedOrganizationId}
                    selectedWorkspace={selectedWorkspace}
                />
            }
            visibleContent={
                <span className={Styles.headerTitle}>
                    {selectedProject.display_name}
                </span>
            }
            {...divProps}
        />
    );
};
