import _isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { ToastType } from '@Components/Toasts';
import { ProjectsApi } from '@Data/projects';
import { IProject, ProjectDisplayType } from '@Data/projects/Interfaces';
import { WorkspacesApi } from '@Data/workspaces';
import { toTitleCase } from '@Services/helpers/Strings';
import { EntityKeys, FormKeys, OrganizationKeys } from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';
import ProjectsCardView from './ProjectsCardView';
import Styles from './ProjectsDisplay.scss';
import ProjectsListView from './ProjectsListView';

interface IProjectsDisplayProps {
    projects: Array<IProject>;
    selectedOrganizationId: string;
    selectedWorkspaceId: string;
    isLoading: boolean;
    projectDisplayType: ProjectDisplayType;
    onOpenCreateProject: () => void;
    onEditProject: (project: IProject) => void;
}

const ProjectsDisplay: React.FC<IProjectsDisplayProps> = (props) => {
    const intl = useIntl();

    const projectStr = toTitleCase(
        intl.formatMessage({
            id: EntityKeys.Project,
            defaultMessage: 'project',
        })
    );

    const handleDeleteProject = async (project: IProject) => {
        const wasSuccessful = await ProjectsApi.deleteProject(project);
        if (wasSuccessful) {
            await WorkspacesApi.loadWorkspaces(props.selectedOrganizationId);
            await ProjectsApi.loadProjects(props.selectedWorkspaceId);
            createToast(
                intl.formatMessage(
                    {
                        id: FormKeys.ObjectDeleted,
                        defaultMessage: 'Project deleted',
                    },
                    { object: projectStr }
                ),
                ToastType.SUCCESS
            );
        }
    };

    if (!props.isLoading && _isNil(props.selectedWorkspaceId)) {
        return (
            <div
                className={Styles.projectScrollContainer}
                style={{ gridColumn: '1/-1' }}
            >
                <span className={Styles.createWorkspace}>
                    {intl.formatMessage({
                        id: OrganizationKeys.CreateAWorkspace,
                        defaultMessage: 'Please create a workspace',
                    })}
                </span>
            </div>
        );
    }
    const projectsDisplay =
        props.projectDisplayType === ProjectDisplayType.CARD ? (
            <ProjectsCardView
                projects={props.projects}
                isLoading={props.isLoading}
                onOpenCreateProject={props.onOpenCreateProject}
                selectedOrganizationId={props.selectedOrganizationId}
                selectedWorkspaceId={props.selectedWorkspaceId}
                onEditProject={props.onEditProject}
                onDeleteProject={handleDeleteProject}
            />
        ) : (
            <ProjectsListView
                projects={props.projects}
                isLoading={props.isLoading}
                selectedOrganizationId={props.selectedOrganizationId}
                selectedWorkspaceId={props.selectedWorkspaceId}
                onEditProject={props.onEditProject}
                onDeleteProject={handleDeleteProject}
            />
        );

    return (
        <div className={Styles.projectDisplayContainer}>
            <div className={Styles.projectScrollContainer}>
                {projectsDisplay}
            </div>
        </div>
    );
};

export default ProjectsDisplay;
