import { ToastType } from '@Components/Toasts';
import {
    ICreatePersistentNotification,
    PersistentNotificationState,
    createToast,
} from '@Services/notifications';

export function handleFailedSdkResponse(
    data,
    persistentNotificationObj?: ICreatePersistentNotification
) {
    if (persistentNotificationObj) {
        persistentNotificationObj.state ===
            PersistentNotificationState.COMPLETED;
    }
    createToast(data.message, ToastType.ERROR, persistentNotificationObj);
}
