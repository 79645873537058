import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import { toTitleCase } from '@Services/helpers/Strings';
import { IAddServiceCatalogService, ISolution } from './Interfaces';
import crunchyLogo from './icons/crunchy.png';
import hasuraLogo from './icons/hasura.svg';
import jupyterLogo from './icons/jupyter.svg';
import jupyterAiLogo from './icons/jupyterAi.svg';
import jupyterGisLogo from './icons/jupyterGis.svg';

export function getPostgresSolution(
    serviceCatalogServices: IAddServiceCatalogService[]
) {
    return serviceCatalogServices.find((sc) => sc.chart === 'postgrescluster');
}

export function getSolutionIcon(service: ISolution): ISolution {
    const clonedService = _cloneDeep(service);
    switch (service.name) {
        case 'hasura':
            clonedService.logo = hasuraLogo;
            break;
        case 'tileserver':
        case 'featureserver':
            clonedService.logo = crunchyLogo;
            break;
        case 'analytics':
            clonedService.logo = jupyterLogo;
            break;
        case 'analytics-gis':
            clonedService.logo = jupyterGisLogo;
            break;
        case 'analytics-ai':
            clonedService.logo = jupyterAiLogo;
            break;
    }

    return clonedService;
}

export function tryFormatDisplayName(service: ISolution): ISolution {
    // format display names for legacy services

    if (!_isNil(service.display_name) && service.display_name !== '') {
        return service;
    }

    const clonedService = _cloneDeep(service);

    switch (clonedService.name) {
        case 'tileserver':
            clonedService.display_name = 'PostGIS Tile Server';
            break;
        case 'featureserver':
            clonedService.display_name = 'PostGIS Feature Server';
            break;
        default:
            clonedService.display_name = toTitleCase(service.name);
            break;
    }

    return clonedService;
}

export const protectedServiceNames = [
    'analytics',
    'analytics-gis',
    'analytics-ai',
    'hasura',
    'tileserver',
    'featureserver',
    'postgres',
];

/**
 * only allow users to edit the display name of custom solutions
 */
export function canEditSolutionDisplayName(service: ISolution): boolean {
    return !protectedServiceNames.includes(service.name);
}
