import { ReactComponent as CloseIconSmall } from '@Icons/lg/small-x.svg';
import { ReactComponent as CloseIcon } from '@Icons/lg/x.svg';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { IconButton } from '@Components/Buttons';
import Styles from './Dialog.scss';
import { IDialogHeaderProps } from './Interfaces';

export const DialogHeader: React.FC<IDialogHeaderProps> = (props) => {
    const { width, ref } = useResizeDetector<HTMLDivElement>();

    const icon = width && width < 550 ? <CloseIconSmall /> : <CloseIcon />;

    return (
        <div
            className={Styles.dialogHeader}
            id={'dialogHeader'}
            data-cy="dialog-header"
            ref={ref}
        >
            <div className={Styles.dialogHeaderSpacer} />
            <span>{props.title}</span>

            <IconButton
                icon={icon}
                className={Styles.dialogHeaderSpacer}
                onClick={props.onClose}
                disabled={props.isCloseDisabled}
                tooltip="Close Dialog"
            />
        </div>
    );
};
