import { ReactComponent as InfoIcon } from '@Icons/sm/circle-info.svg';
import { FormLabelProps, Tooltip, styled } from '@mui/material';
import React from 'react';
import { Label } from './Label';

interface ILabelWithIconTooltipProps extends FormLabelProps {
    label: string;
    icon?: React.ReactElement;
    tooltipTitle: string | React.ReactNode;
    tooltipPlacement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
}

const LabelWithIconTooltipContainer = styled('div')`
    display: flex;
    align-items: baseline;

    i {
        display: flex;
        align-items: center;
    }

    svg {
        margin-left: 8px;
    }
`;

export const LabelWithIconTooltip = (props: ILabelWithIconTooltipProps) => {
    const { icon, tooltipTitle, tooltipPlacement, label, ...labelProps } =
        props;

    const iconHtml = icon ?? <InfoIcon />;
    const tooltipPlacementStr = tooltipPlacement ?? 'right';

    return (
        <LabelWithIconTooltipContainer>
            <Label {...labelProps}>{label}</Label>
            <Tooltip title={tooltipTitle} placement={tooltipPlacementStr}>
                <i>{iconHtml}</i>
            </Tooltip>
        </LabelWithIconTooltipContainer>
    );
};
