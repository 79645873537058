import LayoutStyles from '@Styles/layout.scss';
import { RadioGroup } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import { RadioButton } from '@Components/FormControls/RadioButton';
import { TextInput } from '@Components/Inputs';
import { InputSize } from '@Components/Inputs/Interfaces';
import { ToastType } from '@Components/Toasts';
import {
    OrganizationsApi,
    selectedOrganizationState,
} from '@Data/organizations';
import { isAuthFieldMaxLengthValid } from '@Services/helpers/Auth/AuthHelpers';
import { isEmailAddressValid } from '@Services/helpers/Strings';
import { AuthKeys, OrganizationKeys } from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';

interface IAddUserWithRoleDialogProps {
    isOpen: boolean;
    onClose: () => void;
    getSelectedOrganizationUsers: (organizationId: string) => Promise<void>;
}

const AddUserWithRoleDialog = (props: IAddUserWithRoleDialogProps) => {
    const { isOpen, onClose, getSelectedOrganizationUsers } = props;

    const intl = useIntl();
    const selectedOrganization = useSelector(selectedOrganizationState);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [selectedRole, setSelectedRole] = useState<string>('member');
    const userToAdd = {
        org_id: selectedOrganization?.id,
        first_name: firstName,
        last_name: lastName,
        email: email,
        role: selectedRole,
    };

    const handleSetFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        if (isAuthFieldMaxLengthValid(newName)) {
            setFirstName(newName);
        }
    };

    const handleSetLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        if (isAuthFieldMaxLengthValid(newName)) {
            setLastName(newName);
        }
    };

    const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
    };

    const handleRoleSelectChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedRole(event.target.value);
    };

    const canSubmit =
        !_isNil(selectedOrganization?.id) &&
        firstName.length > 0 &&
        lastName.length > 0 &&
        isEmailAddressValid(email);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setSelectedRole('member');
    };

    const dialogCleanup = () => {
        resetForm();
        onClose();
    };

    const handleCancel = () => {
        dialogCleanup();
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const onCompletion = async () => {
            setIsSubmitting(false);
            await dialogCleanup();
            const toastMsg = intl.formatMessage({
                id: OrganizationKeys.UserAdded,
                defaultMessage: 'User Added',
            });
            createToast(toastMsg, ToastType.SUCCESS);
            getSelectedOrganizationUsers(selectedOrganization?.id);
        };

        const newUser = await OrganizationsApi.addUserToOrganization(userToAdd);
        if (!_isNil(newUser)) {
            onCompletion();
        } else {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={dialogCleanup}>
            <DialogHeader
                onClose={dialogCleanup}
                title={intl.formatMessage({
                    id: OrganizationKeys.AddUser,
                    defaultMessage: 'Add user to organization',
                })}
            />
            <DialogBody>
                <TextInput
                    id="addUser-firstName"
                    value={firstName}
                    onChange={handleSetFirstName}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: AuthKeys.FirstName,
                        defaultMessage: 'First Name',
                    })}
                    required
                    autoFocus
                    containerClassName={LayoutStyles.dialogInputMedium}
                />
                <TextInput
                    id="addUser-lastName"
                    value={lastName}
                    onChange={handleSetLastName}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: AuthKeys.LastName,
                        defaultMessage: 'Last Name',
                    })}
                    required
                    containerClassName={LayoutStyles.dialogInputSmall}
                />
                <TextInput
                    id="addUser-email"
                    value={email}
                    onChange={handleSetEmail}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: AuthKeys.Email,
                        defaultMessage: 'Email Address',
                    })}
                    required
                    containerClassName={LayoutStyles.dialogInputFull}
                />
                <RadioGroup
                    aria-label="roles"
                    value={selectedRole}
                    onChange={handleRoleSelectChange}
                    style={{
                        gridColumn: '1 / -1',
                        justifyContent: 'space-around',
                        flexDirection: 'row',
                    }}
                >
                    <RadioButton
                        label={intl.formatMessage({
                            id: OrganizationKeys.SetAsOrganizationAdmin,
                            defaultMessage: 'Set as organization admin',
                        })}
                        value="admin"
                    />
                    <RadioButton
                        label={intl.formatMessage({
                            id: OrganizationKeys.SetAsOrganizationMember,
                            defaultMessage: 'Set as organization member',
                        })}
                        value="member"
                    />
                </RadioGroup>
            </DialogBody>
            <DialogFooter
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                isSubmitDisabled={!canSubmit}
                isSubmitting={isSubmitting}
                submitText={intl.formatMessage({
                    id: OrganizationKeys.AddUser,
                    defaultMessage: 'Add User',
                })}
                submittingText={intl.formatMessage({
                    id: AuthKeys.AddingUser,
                    defaultMessage: 'Adding new user...',
                })}
            />
        </Dialog>
    );
};

export default AddUserWithRoleDialog;
