import { Dialog as MuiDialog } from '@mui/material';
import React from 'react';
import Styles from './Dialog.scss';
import { IDialogProps } from './Interfaces';
import { StyledDialogCard } from './StyledDialogCard';

export const Dialog = (props: IDialogProps) => {
    const { children, disableBackdropClick, ...dialogProps } = props;

    const handleOnClose = (
        event: object,
        reason: 'backdropClick' | 'escapeKeyDown'
    ) => {
        if (reason === 'backdropClick' && disableBackdropClick) {
            // do nothing
        } else if (props.onClose) {
            props.onClose(event, reason);
        }
    };

    return (
        <MuiDialog
            {...dialogProps}
            onClose={handleOnClose}
            scroll="paper"
            maxWidth={false}
            PaperComponent={StyledDialogCard}
            PaperProps={{
                className: Styles.dialog,
            }}
        >
            {children}
        </MuiDialog>
    );
};
