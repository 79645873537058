import { Skeleton } from '@mui/material';
import React from 'react';
import { InputSize } from '@Components/Inputs';
import { OrganizationOption } from '@Components/Organizations';
import { Select, SelectOption, SelectType } from '@Components/Select';
import { IOrganization } from '@Data/organizations';

interface OrganizationSelectorProps {
    organizations: Array<IOrganization>;
    selectedOrganizationId: string;
    isLoading: boolean;
    selectClassname?: string;
    onChange: (newOrgId: string) => void;
}

const OrganizationSelector: React.FC<OrganizationSelectorProps> = (props) => {
    const handleOrganizationChange = (orgId: string) => {
        props.onChange(orgId);
    };

    const selectedOrgOption = props.organizations?.find(
        (o) => o.id === props.selectedOrganizationId
    );

    return props.isLoading ? (
        <div className={props.selectClassname}>
            <Skeleton width={'100%'} height={50} />
        </div>
    ) : (
        <Select
            id="orgSelect"
            size={InputSize.LARGE}
            selectType={SelectType.SECONDARY}
            value={
                <OrganizationOption
                    name={selectedOrgOption?.name}
                    type={selectedOrgOption?.type}
                />
            }
            onChange={(e, v) => handleOrganizationChange(v)}
            containerClassName={props.selectClassname}
        >
            {props.organizations?.map((org) => {
                return (
                    <SelectOption key={`orgBtn${org.id}`} value={org.id}>
                        <OrganizationOption name={org.name} type={org.type} />
                    </SelectOption>
                );
            })}
        </Select>
    );
};

export default OrganizationSelector;
