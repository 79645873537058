import { ReactComponent as ChevronIcon } from '@Icons/lg/chevron-right.svg';
import ColorVariables from '@Styles/colors.scss';
import { styled } from '@mui/material/styles';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { Spinner } from '@Components/LoadingIndicators';
import { IPrimaryButtonProps } from './Interfaces';
import {
    ChevronContainer,
    LoadingButton,
    SpinnerContainer,
    StyledHoverButton,
} from './StyledButton';

// without the bgcolor attribute, the transition will flicker
const PrimaryStyledButton = styled(StyledHoverButton)(
    ({ theme, color }) => `
    background: ${theme.palette[color].main};
    color: ${theme.palette[color].contrastText};

    &:disabled {
        color: #fff;
        background-color: ${ColorVariables.greyscale30};
    }

    &:hover, &:focus {
        background-color: ${theme.palette[color].main};

        .button-icon {
            stroke: #fff;
            fill: #fff;
        }

        .trailing-icon {
            stroke: #fff;
        }
    }

    .leading-icon {
        path {
            fill: ${theme.palette[color].contrastText};
            stroke: ${theme.palette[color].contrastText}; 
        }                
    }

    ${ChevronContainer} {            
        svg {
            path {
                fill: ${theme.palette[color].contrastText};
            }
        }
    } 
`
);

/**
 * Button with a solid background color that displays a radial gradient on hover (from avocado to teal - see Design System).
 * Can be augmented with a leading icon, and/or a trailing chevron that moves on hover.
 */
export const PrimaryButton: React.FC<IPrimaryButtonProps> = ({
    text,
    size,
    icon,
    color = 'matcha',
    displayChevron = false,
    isLoading,
    loadingText = text,
    ...props
}) => {
    if (isLoading) {
        return (
            <LoadingButton variant="contained" size={size} {...props} disabled>
                <SpinnerContainer>
                    <Spinner className="leading-icon" />
                </SpinnerContainer>
                {loadingText}
                <SpinnerContainer />
            </LoadingButton>
        );
    }

    return (
        <PrimaryStyledButton
            variant="contained"
            size={size}
            color={color}
            {...props}
        >
            {!_isNil(icon) && (
                <React.Fragment>
                    {React.cloneElement(icon, {
                        className: cx('button-icon', 'leading-icon'),
                    })}
                </React.Fragment>
            )}
            {text}
            {displayChevron && (
                <ChevronContainer>
                    <ChevronIcon />
                </ChevronContainer>
            )}
        </PrimaryStyledButton>
    );
};
