import { api } from '@orbica/platform-sdk-dev';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { ISettingsUser } from '@Components/Settings';
import { RootState } from '@Services/redux/reduxStore';
import { IWorkspace } from './Interfaces';
import { IWorkspacesState, initialWorkspacesState } from './State';

const WorkspacesSlice = createSlice({
    name: 'Workspaces',
    initialState: initialWorkspacesState,
    reducers: {
        reset() {
            return initialWorkspacesState;
        },
        setSelectedWorkspace(
            state: IWorkspacesState,
            action: PayloadAction<IWorkspace>
        ) {
            state.selectedWorkspace = action.payload;
        },
        updateWorkspaces(
            state: IWorkspacesState,
            action: PayloadAction<Array<IWorkspace>>
        ) {
            state.workspaces = action.payload;
        },
        updateWorkspaceUsers(
            state: IWorkspacesState,
            action: PayloadAction<ISettingsUser[]>
        ) {
            state.selectedWorkspaceUsers = action.payload;
        },
        setRoles(state: IWorkspacesState, action: PayloadAction<api.IRole[]>) {
            state.roles = action.payload;
        },
    },
});

export const WorkspacesActions = WorkspacesSlice.actions;

export const selectedWorkspaceState = (state: RootState): IWorkspace =>
    state.workspaces.selectedWorkspace;
export const workspacesState = (state: RootState): Array<IWorkspace> =>
    state.workspaces.workspaces;
export const selectedWorkspaceUsersState = (state: RootState) =>
    state.workspaces.selectedWorkspaceUsers;
export const workspacesRolesState = (state: RootState) =>
    state.workspaces.roles;
export const unusableWorkspaceNamesState = createSelector(
    workspacesState,
    (workspaces) => {
        return Array.isArray(workspaces)
            ? workspaces.map((s) => s.name.toLowerCase().trim())
            : [];
    }
);

export default WorkspacesSlice.reducer;
