import ColorStyles from '@Styles/colors.scss';
import ElevationStyles from '@Styles/elevations.scss';
import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import cx from 'classnames';
import React from 'react';
import { getRoleProps } from '@Components/MuiRouterLink';
import Styles from './Cards.scss';
import { IDisplayCardProps } from './Interfaces';
import StyledCard from './StyledCard';

const StyledDisplayCard = styled(StyledCard)`
    background: transparent;
    position: relative;
    overflow: visible;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    &:hover {
        box-shadow: ${ElevationStyles.light16};
        background-color: ${ColorStyles.avocado};
    }
`;

const DisplayCard = (props: IDisplayCardProps) => {
    const {
        cardClasses,
        onClick,
        href,
        relative,
        children,
        className,
        style,
        ...buttonProps
    } = props;

    const roleClasses = getRoleProps(onClick, href, relative);

    return (
        <StyledDisplayCard className={className} style={style}>
            <div className={cx(Styles.displayCard, cardClasses)}>
                <CardActionArea
                    {...roleClasses}
                    {...buttonProps}
                    className={Styles.displayCardActionArea}
                >
                    {children}
                </CardActionArea>
            </div>
        </StyledDisplayCard>
    );
};

export default DisplayCard;
