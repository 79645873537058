export function toTitleCase(input: string): string {
    return input
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
}

export function splitCamelCase(
    input: string,
    forceTitleCase?: boolean
): string {
    const strArray = input.split(/([A-Z][a-z]+)/).filter(function (e) {
        return e;
    });
    const splitArrStr = strArray.join(' ');
    return forceTitleCase ? toTitleCase(splitArrStr) : splitArrStr;
}

export const isEmailAddressValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const cleanName = (name: string) => {
    // This will keep both lowercase and uppercase letters and numbers only.
    let cleanName = name.replace(/[^a-zA-Z0-9]/g, '');

    // Truncate to 63 characters
    if (cleanName.length > 63) {
        cleanName = cleanName.slice(0, 63);
    }

    return cleanName;
};

export const isNameClean = (
    checkName: string,
    setName: (value: string) => void
): { wasClean: boolean; newName?: string } => {
    const cleanedName = cleanName(checkName);
    if (checkName !== cleanedName) {
        setName(cleanedName);
        return { wasClean: false, newName: cleanedName };
    } else {
        return { wasClean: true };
    }
};
