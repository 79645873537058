import { ReactComponent as MenuIcon } from '@Icons/lg/more-vertical.svg';
import Flex from '@Styles/flex.scss';
import TypographyStyles from '@Styles/typography.scss';
import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@Components/Buttons';
import { canCurrentUserEditProjectState } from '@Data/auth';
import { IProject } from '@Data/projects';
import Styles from '@Views/WorkspacesView/components/WorkspaceController/WorkspaceTitle/WorkspaceTitle.scss';
import ProjectMenu from './ProjectMenu';

interface IProjectTitleProps {
    project: IProject;
    onDelete: () => void;
}

const ProjectTitle = (props: IProjectTitleProps) => {
    const canUserEditProject = useSelector(canCurrentUserEditProjectState);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleMenuClick = (e) => {
        setAnchorEl(e.target);
    };

    return (
        <React.Fragment>
            <div className={cx(Flex.flex, Flex.alignItemsCenter)}>
                <span className={TypographyStyles.h3}>
                    {props.project?.display_name}
                </span>
                {canUserEditProject && (
                    <>
                        <div className={Styles.divider}>
                            <div />
                        </div>
                        <IconButton
                            containerProps={{
                                className: Styles.pcMenuContainer,
                            }}
                            onClick={handleMenuClick}
                            icon={<MenuIcon />}
                        />
                    </>
                )}
            </div>
            <ProjectMenu
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onDelete={props.onDelete}
                projectName={props.project?.display_name}
            />
        </React.Fragment>
    );
};

export default ProjectTitle;
