export interface IToastProps {
    message: string;
    type: ToastType;
    id: string;
}

export enum ToastType {
    SUCCESS,
    ERROR,
    WARNING,
    INFO,
}
