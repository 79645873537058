import { ReactComponent as UserIcon } from '@Icons/lg/user.svg';
import { ReactComponent as GlobeIcon } from '@Icons/md/globe.svg';
import { ReactComponent as HelpIcon } from '@Icons/md/help.svg';
import { ReactComponent as LogoutIcon } from '@Icons/md/log-out.svg';
import { ReactComponent as SettingsIcon } from '@Icons/md/settings.svg';
import { ReactComponent as OrganizationIcon } from '@Icons/md/sitemap.svg';
import { Menu } from '@mui/material';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { IconButton } from '@Components/Buttons';
import { MenuItem } from '@Components/Menu';
import { canCurrentUserEditOrgState } from '@Data/auth';
import { selectedOrganizationState } from '@Data/organizations';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlagsList';
import { parseVersion } from '@Services/helpers/Misc';
import LocaleContext from '@Services/i18n/LocaleContext';
import { AuthKeys, SettingsKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';
import CreateOrganizationDialog from '@Views/Settings/OrganizationSettingsView/components/CreateOrganizationDialog/CreateOrganizationDialog';
import LanguageSelectionDialog from './LanguageSelectionDialog';
import UserDetails from './UserDetails';
import Styles from './UserMenu.scss';

export const UserMenu = () => {
    const auth = useAuth();
    const intl = useIntl();
    const localeCtx = useContext(LocaleContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const iconButtonContainerId = 'UserMenuBtnContainer';
    const selectedOrganization = useSelector(selectedOrganizationState);
    const { width, ref } = useResizeDetector();

    const organizationSettingsUrl = Routes.settings.organizationSettings.getUrl(
        selectedOrganization?.id
    );

    const [isCreateOrganizationDialogOpen, setIsCreateOrganizationDialogOpen] =
        useState<boolean>(false);
    const [isLanguageDialogOpen, setIsLanguageDialogOpen] =
        useState<boolean>(false);
    const canCurrentUserEditOrg = useSelector(canCurrentUserEditOrgState);

    // allow user to see the create org button
    const FLAG_canUserCreateOrg = useFeatureFlagEnabled(
        FeatureFlags.CanUserCreateOrganization
    );

    const handleLogout = () => {
        setIsOpen(false);
        auth.signoutRedirect();
    };

    const handleCreateOrganization = () => {
        setIsOpen(false);
        setIsCreateOrganizationDialogOpen(true);
    };
    const openLanguageDialog = () => {
        setIsOpen(false);
        setIsLanguageDialogOpen(true);
    };

    const openUserDocumentation = () => {
        window.open(process.env.DOCUMENTATION_URL, '_blank').focus();
    };

    const handleOpenReleaseNotes = () => {
        window
            .open(
                `${process.env.DOCUMENTATION_URL}docs/Release Notes/`,
                '_blank'
            )
            .focus();
    };

    const formattedVersion = parseVersion(process.env.VERSION);

    return (
        <React.Fragment>
            <div id={iconButtonContainerId} data-cy="open-menu-button">
                <IconButton
                    className={Styles.userMenu}
                    icon={<UserIcon />}
                    onClick={() => setIsOpen(true)}
                />
            </div>

            <Menu
                open={isOpen}
                anchorEl={document.getElementById(iconButtonContainerId)}
                onClose={() => setIsOpen(false)}
                sx={{
                    '.MuiMenu-paper': {
                        padding: 0,
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <UserDetails
                    firstName={auth.user?.profile.given_name}
                    lastName={auth.user?.profile.family_name}
                    email={auth.user?.profile.email.toLowerCase()}
                />
                <div
                    className={Styles.menuItemsContainer}
                    data-cy="menu-items-container"
                    ref={ref}
                >
                    {FLAG_canUserCreateOrg && (
                        <MenuItem onClick={handleCreateOrganization}>
                            <div
                                className={Styles.menuItemContainer}
                                data-cy="menu-creat-organization"
                            >
                                <div>
                                    <OrganizationIcon />
                                </div>
                                {intl.formatMessage({
                                    id: AuthKeys.CreateOrganization,
                                    defaultMessage: 'Create Organization',
                                })}
                            </div>
                        </MenuItem>
                    )}
                    {canCurrentUserEditOrg && (
                        <MenuItem href={organizationSettingsUrl}>
                            <div
                                className={Styles.menuItemContainer}
                                data-cy="menu-organization-settings"
                            >
                                <div>
                                    <SettingsIcon />
                                </div>
                                {intl.formatMessage({
                                    id: SettingsKeys.OrganizationSettings,
                                    defaultMessage: 'Organization Settings',
                                })}
                            </div>
                        </MenuItem>
                    )}
                    <MenuItem onClick={openLanguageDialog}>
                        <div
                            className={Styles.menuItemContainer}
                            data-cy="menu-set-language"
                        >
                            <div>
                                <GlobeIcon />
                            </div>

                            {localeCtx.selectedLocale.displayName}
                        </div>
                    </MenuItem>

                    <MenuItem onClick={openUserDocumentation}>
                        <div
                            className={Styles.menuItemContainer}
                            data-cy="menu-open-documentation"
                        >
                            <div>
                                <HelpIcon />
                            </div>
                            {intl.formatMessage({
                                id: AuthKeys.UserDocumentation,
                                defaultMessage: 'User Documentation',
                            })}
                        </div>
                    </MenuItem>

                    <MenuItem onClick={handleLogout}>
                        <div
                            className={Styles.menuItemContainer}
                            data-cy="menu-logout"
                        >
                            <div>
                                <LogoutIcon />
                            </div>
                            {intl.formatMessage({
                                id: AuthKeys.SignOut,
                                defaultMessage: 'Sign out',
                            })}
                        </div>
                    </MenuItem>

                    {width && (
                        <div
                            className={Styles.releaseNotesBtn}
                            onClick={handleOpenReleaseNotes}
                            title={formattedVersion}
                            style={{
                                width: width,
                            }}
                        >
                            {formattedVersion}
                        </div>
                    )}
                </div>
            </Menu>

            <CreateOrganizationDialog
                isOpen={isCreateOrganizationDialogOpen}
                onClose={() => setIsCreateOrganizationDialogOpen(false)}
            />

            <LanguageSelectionDialog
                isOpen={isLanguageDialogOpen}
                onClose={() => setIsLanguageDialogOpen(false)}
            />
        </React.Fragment>
    );
};
