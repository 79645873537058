import { ReactComponent as MenuIcon } from '@Icons/lg/more-vertical.svg';
import Flex from '@Styles/flex.scss';
import TypographyStyles from '@Styles/typography.scss';
import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@Components/Buttons';
import { canCurrentUserEditWorkspaceState } from '@Data/auth';
import { IWorkspace } from '@Data/workspaces';
import WorkspaceMenu from './WorkspaceMenu';
import Styles from './WorkspaceTitle.scss';

interface IWorkspaceTitleProps {
    workspace: IWorkspace;
    onDelete: () => void;
}

const WorkspaceTitle = (props: IWorkspaceTitleProps) => {
    const canUserEditWorkspace = useSelector(canCurrentUserEditWorkspaceState);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleMenuClick = (e) => {
        setAnchorEl(e.target);
    };

    return (
        <React.Fragment>
            <div className={cx(Flex.flex, Flex.alignItemsCenter)}>
                <span className={TypographyStyles.h3}>
                    {props.workspace?.display_name}
                </span>
                {canUserEditWorkspace && (
                    <>
                        <div className={Styles.divider}>
                            <div />
                        </div>
                        <IconButton
                            containerProps={{
                                className: Styles.pcMenuContainer,
                            }}
                            onClick={handleMenuClick}
                            icon={<MenuIcon />}
                        />
                    </>
                )}
            </div>
            <WorkspaceMenu
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onDelete={props.onDelete}
                workspaceName={props.workspace?.display_name}
            />
        </React.Fragment>
    );
};

export default WorkspaceTitle;
