import ColorsStyles from '@Styles/colors.scss';
import { styled } from '@mui/material';
import React, { ReactElement, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import DisplayCard from '@Components/Cards/DisplayCard';
import { Dialog, DialogBody, DialogHeader } from '@Components/Dialog';
import LocaleContext from '@Services/i18n/LocaleContext';
import { ILocale, Locales } from '@Services/i18n/Locales';
import { I18nKeys } from '@Services/i18n/keys';

const StyledDisplayCard = styled(DisplayCard)`
    grid-column: span 2;

    @media (min-width: 550px) {
        grid-column: span 3;
    }

    @media (min-width: 768px) {
        grid-column: span 2;
    }

    div {
        display: flex;
        flex-direction: column;
        padding: 8px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);

        &hover {
            background: #fff;
        }

        span:nth-child(2n - 1) {
            font-weight: 700;
            margin-bottom: 8px;
        }

        span:nth-child(2n) {
            color: ${ColorsStyles.greyscale60};
        }
    }
`;

export interface ILanguageSelectionDialogOptionProps {
    onClick: () => void;
    language: string;
    region: string;
}

const LanguageSelectionDialogOption = (
    props: ILanguageSelectionDialogOptionProps
) => {
    return (
        <StyledDisplayCard onClick={props.onClick}>
            <div>
                <span>{props.language}</span>
                <span>{props.region}</span>
            </div>
        </StyledDisplayCard>
    );
};

export interface ILanguageSelectionDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const LanugageSelectionDialog = (props: ILanguageSelectionDialogProps) => {
    const intl = useIntl();
    const localeCtx = useContext(LocaleContext);

    const handleLocaleSelection = (newLocale: ILocale) => {
        localeCtx.changeLocale(newLocale);
        props.onClose();
    };

    const localeOptions = useMemo(() => {
        const output: ReactElement[] = [];
        for (const localeOpt in Locales) {
            const locale = Locales[localeOpt];

            output.push(
                <LanguageSelectionDialogOption
                    language={locale.language}
                    region={locale.region}
                    onClick={() => handleLocaleSelection(locale)}
                    key={locale.code}
                />
            );
        }

        return output;
    }, []);

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogHeader
                onClose={props.onClose}
                title={intl.formatMessage({
                    id: I18nKeys.ChooseALanguageAndRegion,
                    defaultMessage: 'Choose a language and region',
                })}
            />
            <DialogBody>{localeOptions}</DialogBody>
        </Dialog>
    );
};

export default LanugageSelectionDialog;
