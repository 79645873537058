import ColorStyles from '@Styles/colors.scss';
import OptionUnstyled from '@mui/base/OptionUnstyled';
import { styled } from '@mui/system';
import React from 'react';
import { ISelectOptionProps } from './Interfaces';

const StyledOption = styled(OptionUnstyled)(() => ({
    listStyle: 'none',
    userSelect: 'none',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    padding: '8px 0',
    margin: '4px 0',
    color: ColorStyles.greyscale90,
    textDecoration: 'underline',
    textDecorationColor: 'transparent',
    textUnderlineOffset: '4px',
    textDecorationThickness: '1px',
    '&:hover': {
        backgroundColor: 'transparent',
        color: ColorStyles.darkGreen,
        textDecoration: 'underline',
        textDecorationColor: ColorStyles.mint,
    },
}));

export const SelectOption = (props: ISelectOptionProps<any>) => {
    return <StyledOption {...props}>{props.children}</StyledOption>;
};
