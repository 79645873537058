import React from 'react';
import Styles from './Page.scss';

export interface IPageProps {
    children?: React.ReactNode;
    parentRef?: React.Ref<HTMLDivElement>;
}

export const Page: React.FC<IPageProps> = ({ children, parentRef }) => {
    return (
        <div className={Styles.page} ref={parentRef}>
            {children}
        </div>
    );
};
