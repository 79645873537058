import { ReactComponent as DevkitIcon } from '@Icons/lg/devkit.svg';
import { ReactComponent as MarketplaceIcon } from '@Icons/lg/marketplace.svg';
import { ReactComponent as WorkspaceIcon } from '@Icons/lg/workspace.svg';
import IconsStyles from '@Styles/icons.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { MenuItem } from '@Components/Menu';
import { MenuTab, Tab, Tabs } from '@Components/Tabs';
import { ViewKeys } from '@Services/i18n/keys';
import { Routes, View } from '@Services/navigation';
import { IHeaderTabsProps } from '../Interfaces';

/**
 * Buttons that display the currently selected view, and provide options to navigate to other views in the Workspaces, Marketplace, and Devkit views.
 * Intended to be used in the header.
 */
export const DefaultTabs: React.FC<IHeaderTabsProps> = ({ currentView }) => {
    const intl = useIntl();

    const marketplaceMenuItems = [
        <MenuItem key={'mi-datalibrary'} href={'/'}>
            {intl.formatMessage({
                id: ViewKeys.DataLibrary,
                defaultMessage: 'Data Library',
            })}
        </MenuItem>,
        <MenuItem key={'mi-models'} href={'/'}>
            {intl.formatMessage({
                id: ViewKeys.ModelsAlgorithms,
                defaultMessage: 'Models & Algorithms',
            })}
        </MenuItem>,
        <MenuItem key={'mi-templates'} href={'/'}>
            {intl.formatMessage({
                id: ViewKeys.ProjectTemplates,
                defaultMessage: 'Project Templates',
            })}
        </MenuItem>,
        <MenuItem key={'mi-plugins'} href={'/'}>
            {intl.formatMessage({
                id: ViewKeys.PluginsApis,
                defaultMessage: 'Plugins & Apis',
            })}
        </MenuItem>,
        <MenuItem key={'mi-visualization'} href={'/'}>
            {intl.formatMessage({
                id: ViewKeys.VisualizationApps,
                defaultMessage: 'Visualization Apps',
            })}
        </MenuItem>,
    ];

    const workspacesUrl = Routes.workspaces.getUrl();

    return (
        <Tabs value={currentView}>
            <Tab
                label={intl.formatMessage({
                    id: ViewKeys.Workspaces,
                    defaultMessage: 'Workspaces',
                })}
                icon={<WorkspaceIcon className={IconsStyles.fillWhite} />}
                value={View.WORKSPACES}
                href={workspacesUrl}
            />
            {/* <MenuTab
                id={'mktplaceMenuBtn'}
                label={intl.formatMessage({
                    id: ViewKeys.Marketplace,
                    defaultMessage: 'Marketplace',
                })}
                icon={<MarketplaceIcon className={IconsStyles.fillWhite} />}
                value={View.MARKETPLACE}
                menuItems={marketplaceMenuItems}
            />
            <Tab
                label={intl.formatMessage({
                    id: ViewKeys.Devkit,
                    defaultMessage: 'Devkit',
                })}
                value={View.DEVKIT}
                icon={<DevkitIcon className={IconsStyles.fillWhite} />}
                href={'/'}
            /> */}
        </Tabs>
    );
};
