import React from 'react';
import Styles from './TemporaryProjectServiceView.scss';

interface IProjectServiceIframeProps {
    src: string;
    id: string;
}

export const ProjectServiceIframe = (props: IProjectServiceIframeProps) => {
    return <iframe id={props.id} className={Styles.iframe} src={props.src} />;
};
