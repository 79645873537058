const Solutions = {
    Details: 'solutions.details',
    Contents: 'solutions.contents',
    Settings: 'solutions.settings',
    DatabaseName: 'solutions.databaseName',
    AddANewSolution: 'solutions.addANewSolution',
    AddingSolutionNotice: 'solutions.addingSolutionNotice',
    SolutionAdded: 'solutions.solutionAdded',
    ManageSolutionInstanceSize: 'solutions.manageSolutionInstanceSize',
    ManageSolutionPassword: 'solutions.manageSolutionPassword',
    ManageSolutionReenterPassword: 'solutions.manageSolutionReenterPassword',
    ManageSolutionConnectToDatabase:
        'solutions.manageSolutionConnectToDatabase',
    ManageSolutionName: 'solutions.manageSolutionName',
    ManageSolutionDisplayName: 'solutions.manageSolutionDisplayName',
    ManageSolutionCustomSettings: 'solutions.manageSolutionCustomSettings',
    DatastoreRequired: 'solutions.datastoreRequired',
    ManageSolutionHasuraSecretKey: 'solutions.manageSolutionHasuraSecretKey',
    InvalidJson: 'solutions.invalidJson',
    DataStore: 'solutions.dataStore',
    Analytics: 'solutions.analytics',
    CustomSolution: 'solutions.customSolution',
    CustomSolutionDisplayName: 'solutions.customSolutionDisplayName',
    DataStoreDescription: 'solutions.dataStoreDescription',
    AnalyticsDescription: 'solutions.analyticsDescription',
    CustomSolutionDescription: 'solutions.customSolutionDescription',
    ViewDocumentation: 'solutions.viewDocumentation',
};

export default Solutions;
