import { ReactComponent as EditIcon } from '@Icons/lg/edit-2.svg';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconButton } from '@Components/Buttons';
import { LabelWithIconButton } from '@Components/Label/LabelWithIconButton';
import { EntityKeys } from '@Services/i18n/keys';
import { EditEntityDisplayNameDialog } from './EditEntityDisplayNameDialog';
import Styles from './Settings.scss';

export interface IEntityNameDisplayNameProps {
    onSubmitDisplayName: (newName: string) => void;
    entityName: string;
    entityDisplayName: string;
    entityType: string;
    parentEntityType?: string;
}

export const EntityNameDisplayName = (props: IEntityNameDisplayNameProps) => {
    const intl = useIntl();

    const [isEditDisplayNameDialogOpen, setIsEditDisplayNameDialogOpen] =
        useState<boolean>(false);

    const label = `${props.entityType} Name`;

    return (
        <>
            <div className={Styles.entityNameDisplayNameContainer}>
                <LabelWithIconButton
                    label={label}
                    iconButton={
                        <IconButton
                            icon={<EditIcon />}
                            onClick={() => setIsEditDisplayNameDialogOpen(true)}
                            tooltip={intl.formatMessage(
                                {
                                    id: EntityKeys.UpdateEntityDisplayName,
                                    defaultMessage: `Update ${props.entityType} Name`,
                                },
                                { entity: props.entityType }
                            )}
                            tooltipProps={{
                                placement: 'right',
                            }}
                        />
                    }
                />
                <span className={Styles.entityDisplayNameTitle}>
                    {props.entityDisplayName}
                </span>
            </div>
            <EditEntityDisplayNameDialog
                {...props}
                isOpen={isEditDisplayNameDialogOpen}
                onClose={() => setIsEditDisplayNameDialogOpen(false)}
            />
        </>
    );
};
