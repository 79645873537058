import { ReactComponent as ChevronLarge } from '@Icons/opinionated/chevron-down-large.svg';
import { ReactComponent as ChevronMedium } from '@Icons/opinionated/chevron-down-med.svg';
import { ReactComponent as ChevronSmall } from '@Icons/opinionated/chevron-down-small.svg';
import { ReactComponent as LockLarge } from '@Icons/opinionated/lock-large.svg';
import { ReactComponent as LockMedium } from '@Icons/opinionated/lock-med.svg';
import { ReactComponent as LockSmall } from '@Icons/opinionated/lock-small.svg';
import BorderStyles from '@Styles/borders.scss';
import ColorStyles from '@Styles/colors.scss';
import ElevationStyles from '@Styles/elevations.scss';
import Flex from '@Styles/flex.scss';
import SelectUnstyled, {
    SelectUnstyledRootSlotProps,
} from '@mui/base/SelectUnstyled';
import { styled } from '@mui/system';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { InputSize } from '@Components/Inputs';
import { Label } from '@Components/Label';
import { getListboxClasses, getRootClasses } from './Helpers';
import { ISelectProps, SelectType } from './Interfaces';

const getRootId = (otherId: string): string => {
    const baseId = otherId.split('-')[0];
    return `${baseId}-root`;
};

const Button = React.forwardRef(function Button<TValue extends object>(
    props: SelectUnstyledRootSlotProps<TValue> | any,
    ref: React.ForwardedRef<HTMLButtonElement>
) {
    const { ownerState, ...other } = props;
    const { selectType, size, icon, id, ...defaultProps } = other;

    const value =
        !_isNil(ownerState.value) && ownerState.value !== ''
            ? ownerState.value
            : ownerState.placeholder;

    return (
        <button
            type="button"
            {...defaultProps}
            ref={ref}
            id={`${id}-root`}
            style={{
                color:
                    value === ownerState.placeholder
                        ? ColorStyles.greyscale60
                        : null,
            }}
        >
            {value}
            {icon}
        </button>
    );
});

const StyledContainedButton = styled(Button, { shouldForwardProp: () => true })(
    () => ({
        backgroundColor: '#FFF',
        borderRadius: '6px',
        border: BorderStyles.subtle,
        '&:disabled': {
            backgroundColor: ColorStyles.greyscale30,
        },
    })
);

const StyledTextButton = styled(Button, { shouldForwardProp: () => true })(
    () => ({
        backgroundColor: 'transparent',
        borderRadius: '6px',
        border: 'none',
    })
);

const StyledListbox = styled('ul')((props) => ({
    background: '#fff',
    border: BorderStyles.subtle,
    boxShadow: ElevationStyles.light8,
    borderRadius: '6px',
    maxHeight: '50vh',
    overflowY: 'auto',
    minWidth: document.getElementById(getRootId(props.id)).offsetWidth,
}));

export const Select: React.FC<ISelectProps<any>> = (props) => {
    const getIcon = (
        size: InputSize,
        isDisabled = false
    ): React.ReactElement => {
        let icon;
        if (isDisabled) {
            switch (size) {
                case InputSize.LARGE:
                    icon = <LockLarge />;
                    break;
                case InputSize.MEDIUM:
                    icon = <LockMedium />;
                    break;
                case InputSize.SMALL:
                    icon = <LockSmall />;
                    break;
            }
        } else {
            switch (size) {
                case InputSize.LARGE:
                    icon = <ChevronLarge />;
                    break;
                case InputSize.MEDIUM:
                    icon = <ChevronMedium />;
                    break;
                case InputSize.SMALL:
                    icon = <ChevronSmall />;
                    break;
            }
        }

        return (
            <div style={{ height: 0, display: 'flex', alignItems: 'center' }}>
                {icon}
            </div>
        );
    };

    const CustomSelect = React.forwardRef(function CustomSelect<
        TValue extends object
    >(props: ISelectProps<TValue>, ref: React.ForwardedRef<HTMLButtonElement>) {
        const slots = {
            root:
                props.selectType === SelectType.PRIMARY
                    ? StyledContainedButton
                    : StyledTextButton,
            listbox: StyledListbox,
            ...props.slots,
        };

        return <SelectUnstyled {...props} ref={ref} slots={slots} />;
    }) as <TValue>(
        props: ISelectProps<TValue> & React.RefAttributes<any>
    ) => JSX.Element;

    const { id, containerClassName, containerStyle, children, ...selectProps } =
        props;

    const selectId = id ? id : Math.random().toString(32);

    return (
        <div
            className={cx(Flex.flex, Flex.column, containerClassName)}
            style={containerStyle}
            data-cy={props.id}
        >
            {props.label && (
                <Label htmlFor={selectId} required={props.required}>
                    {props.label}
                </Label>
            )}

            <CustomSelect
                id={selectId}
                value={props.value}
                slotProps={{
                    root: {
                        className: getRootClasses(props.size),
                        icon: getIcon(props.size, props.disabled),
                    },
                    listbox: {
                        className: getListboxClasses(
                            props.size,
                            props.selectType
                        ),
                        id: `${selectId}-listbox`,
                    },
                    popper: {
                        style: { zIndex: 10 },
                    },
                }}
                onChange={props.onChange}
                {...selectProps}
            >
                {children}
            </CustomSelect>
        </div>
    );
};
