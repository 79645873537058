const Auth = {
    LogIn: 'auth.logIn',
    LogInPrompt: 'auth.logInPrompt',
    SignOut: 'auth.signOut',
    SignIn: 'auth.signIn',
    Redirecting: 'auth.redirecting',
    Register: 'auth.register',
    RegisterPrompt: 'auth.registerPrompt',
    RegisterTitle: 'auth.registerTitle',
    TermsAndConditions: 'auth.termsAndConditions',
    CreateAcct: 'auth.createAcct',
    FirstName: 'auth.firstName',
    LastName: 'auth.lastName',
    Email: 'auth.email',
    OrganizationName: 'auth.organizationName',
    CreateOrganization: 'auth.createOrganization',
    Loading: 'auth.loading',
    AddingUser: 'auth.addingUser',
    UserDocumentation: 'auth.userDocumentation',
};

export default Auth;
