import { api } from '@orbica/platform-sdk-dev';
import {
    IOrgUserDetails,
    IProjectNew,
} from '@orbica/platform-sdk-dev/dist/src/entities';
import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import { ISettingsUser } from '@Components/Settings';
import { ToastType } from '@Components/Toasts';
import { mapUserDetails } from '@Data/auth/Helpers';
import { asyncTimeout } from '@Services/helpers/Misc';
import { createToast } from '@Services/notifications';
import store from '@Services/redux/reduxStore';
import { OrbicaSdkClient } from '@Services/sdk/OrbicaSdkClient';
import { handleFailedSdkResponse } from '@Services/sdk/helpers';
import { IProject, ProjectDisplayType, ProjectSortType } from './Interfaces';
import { ProjectsActions } from './Reducer';

const Api = {
    reset: () => {
        store.dispatch(ProjectsActions.reset());
    },
    loadProjects: async (
        workspaceId: string,
        color?: string
    ): Promise<IProject[]> => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const response = await projectApi.all(workspaceId);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const projects = response.data as Array<api.IProject>;
        const mappedProjects: Array<IProject> = projects.map((p) => {
            return {
                ...p,
                workspaceColor: color,
                display_name:
                    !_isNil(p.display_name) && p.display_name !== ''
                        ? p.display_name
                        : p.name,
            };
        });

        store.dispatch(ProjectsActions.updateProjects(mappedProjects));

        return mappedProjects;
    },
    setSelectedProject: (project: IProject, callback?: () => void) => {
        store.dispatch(ProjectsActions.setSelectedProject(project));

        if (callback) {
            callback();
        }
    },
    loadProjectById: async (projectId: string): Promise<IProject> => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const response = await projectApi.get(projectId);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const project = _cloneDeep(response.data) as api.IProject;
        if (_isNil(project.display_name) || project.display_name === '') {
            project.display_name = project.name;
        }
        store.dispatch(ProjectsActions.setSelectedProject(project));
        return project;
    },
    setProjectDisplayType: (
        displayType: ProjectDisplayType,
        callback?: () => void
    ) => {
        store.dispatch(ProjectsActions.updateDisplayType(displayType));

        if (callback) {
            callback;
        }
    },
    setProjectSortType: (sortType: ProjectSortType, callback?: () => void) => {
        store.dispatch(ProjectsActions.updateSortType(sortType));
        if (callback) {
            callback();
        }
    },
    toggleSortOrder: (callback?: () => void) => {
        store.dispatch(ProjectsActions.toggleSortType());
        if (callback) {
            callback();
        }
    },
    createProject: async (
        name: string,
        displayName: string,
        workspaceId: string,
        clusterId: string,
        description?: string
    ): Promise<IProject> => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const createProjectObject: IProjectNew = {
            name: name,
            display_name: displayName,
            workspace_id: workspaceId,
            description: description,
            cluster_id: clusterId,
        };

        const response = await projectApi.create(createProjectObject);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const newProject = response.data as api.IProject;

        //06/07/23: set timeout hack
        await asyncTimeout(5000);

        return newProject;
    },
    editProjectDisplayName: async (
        existingProject: IProject,
        displayName: string,
        workspaceId: string
    ): Promise<IProject> => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const editProjectPayload: api.IProject = {
            ...existingProject,
            display_name: displayName,
            workspace_id: workspaceId,
        };
        const response = await projectApi.update(
            editProjectPayload,
            existingProject.id
        );
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        const updatedProject = response.data as api.IProject;

        return updatedProject;
    },
    deleteProject: async (project: IProject): Promise<boolean> => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const response = await projectApi.delete(project);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return false;
        }
        return true;
    },
    getProjectUsers: async (
        projectId: string,
        organizationUsers: IOrgUserDetails[]
    ) => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const response = await projectApi.getProjectUsers(projectId);
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return false;
        }
        const mappedUsers = mapUserDetails(
            response.data as api.IProjectUserRole[],
            organizationUsers
        );

        store.dispatch(ProjectsActions.updateProjectUsers(mappedUsers));
    },
    getProjectRoles: async () => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const response = await projectApi.getProjectRoles();
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return false;
        }

        store.dispatch(ProjectsActions.setRoles(response.data as api.IRole[]));
    },
    addUsersToProject: async (users: ISettingsUser[], projectId: string) => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();

        for (const user of users) {
            const resp = await projectApi.upsertUserRole({
                user_id: user.id,
                project_id: projectId,
                role_id: user.role.id,
            });

            if (resp.status === 'failed') {
                const data = resp.data as { message: string };
                createToast(data.message, ToastType.ERROR);
            }
        }
    },
    upsertProjectUserRole: async (
        project: IProject,
        userId: string,
        role: api.IRole
    ) => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const result = await projectApi.upsertUserRole({
            user_id: userId,
            project_id: project.id,
            role_id: role.id,
        });
        if (result.status === 'failed') {
            handleFailedSdkResponse(result.data);
            return false;
        }
        return true;
    },
    removeUserFromProject: async (user: ISettingsUser, projectId: string) => {
        const projectApi = await OrbicaSdkClient.instance.getProjectApi();
        const response = await projectApi.deleteUser({
            user_id: user.id,
            project_id: projectId,
        });
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return false;
        }
        return true;
    },
};

export default Api;
