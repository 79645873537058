import ColorStyles from '@Styles/colors.scss';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const getCssBySize = (
    size: 'large' | 'medium' | 'small'
): { fontSize; padding; minHeight; maxHeight } => {
    switch (size) {
        case 'large':
            return {
                fontSize: '18px',
                padding: '12px 16px',
                minHeight: '48px',
                maxHeight: '48px',
            };
        case 'medium':
            return {
                fontSize: '16px',
                padding: '10px 14px',
                minHeight: '40px',
                maxHeight: '40px',
            };
        case 'small':
            return {
                fontSize: '14px',
                padding: '8px 12px',
                minHeight: '32px',
                maxHeight: '32px',
            };
    }
};

export const StyledButton = styled(Button)(({ size, theme }) => ({
    ...getCssBySize(size),
    fontWeight: 600,
    lineHeight: '22px',
    [`${ChevronContainer}`]: {
        svg: {
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.standard,
            }),
        },
    },
    '.leading-icon': {
        marginRight: '10px',
        transition: theme.transitions.create(['fill, stroke'], {
            duration: theme.transitions.duration.standard,
        }),
    },
    '&:hover, &:focus': {
        [`${ChevronContainer}`]: {
            svg: {
                transform: 'translate(10px, 0px)',
            },
        },
    },
}));

export const StyledHoverButton = styled(StyledButton)(({ theme }) => ({
    transition: theme.transitions.create(
        ['background', 'color', 'background-color, border, fill, stroke'],
        {
            duration: theme.transitions.duration.standard,
        }
    ),

    '&:hover, &:focus': {
        background: ColorStyles.radialAvocadoTeal,
        color: '#fff',
    },
}));

export const ChevronContainer = styled('div')(() => ({
    width: '34px',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
}));

export const LoadingButton = styled(StyledButton)(() => ({
    background: '#000',
    color: '#fff',
    justifyContent: 'space-between',

    '&:hover, &:focus, &:disabled': {
        background: '#000',
        color: '#fff',
    },
}));

export const SpinnerContainer = styled('div')`
    display: flex;
    flex: 1 1 0;
    justify-content: center;
`;
