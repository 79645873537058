import { MessageFormatElement } from 'react-intl';
import GermanMessages from './languages/de-de.json';
import EnglishUkMessages from './languages/en-uk.json';

export const Locales = {
    ENGLISH_UK: {
        code: 'en-UK',
        language: 'English',
        region: 'United Kingdom',
        displayName: 'English (UK)',
        messages: EnglishUkMessages,
    },
    ENGLISH_US: {
        code: 'en-US',
        language: 'English',
        region: 'United States',
        displayName: 'English (US)',
        messages: null,
    },
    GERMAN_DE: {
        code: 'de-DE',
        language: 'German',
        region: 'Germany',
        displayName: 'Germany (DE)',
        messages: GermanMessages,
    },
};

export interface ILocale {
    code: string;
    language: string;
    region: string;
    displayName: string;
    messages:
        | Record<string, string>
        | Record<string, MessageFormatElement[]>
        | null;
}
