import { ReactComponent as MenuIcon } from '@Icons/lg/more-horizontal.svg';
import { ReactComponent as TimeUpdated } from '@Icons/sm/time-updated.svg';
import defaultImg from '@Images/taranaki.png';
import ColorStyles from '@Styles/colors.scss';
import Flex from '@Styles/flex.scss';
import TypographyStyles from '@Styles/typography.scss';
import cx from 'classnames';
import { DateTime } from 'luxon';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { IconButton } from '@Components/Buttons';
import { canCurrentUserEditWorkspaceState } from '@Data/auth';
import DisplayCard from '../DisplayCard';
import { ICardProps } from '../Interfaces';
import Styles from './ProjectCards.scss';

export interface IProjectCardProps extends ICardProps {
    tags?: Array<string>;
    workspaceColor?: string;
    date: string;
    onOpenMenu: (anchorEl: HTMLElement) => void;
}

export const ProjectCard: React.FC<IProjectCardProps> = (props) => {
    const { width, ref } = useResizeDetector<HTMLDivElement>();
    const canUserEditProject = useSelector(canCurrentUserEditWorkspaceState);
    const { title, date, tags, workspaceColor, onOpenMenu, ...cardProps } =
        props;
    const bannerColor = workspaceColor ? workspaceColor : ColorStyles.matcha;
    const [imageContainerHeight, setImageContainerHeight] = useState<
        number | null
    >(null);

    useLayoutEffect(() => {
        setImageContainerHeight((width * 2) / 3);
    }, [width]);

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onOpenMenu(e.currentTarget);
    };

    return (
        <React.Fragment>
            <DisplayCard
                className={Styles.projectCardBase}
                cardClasses={Styles.projectCard}
                {...cardProps}
            >
                <div ref={ref}>
                    <div
                        className={Styles.pcWorkspaceIndicator}
                        style={{ backgroundColor: bannerColor }}
                    />
                    <div
                        className={Styles.pcImgContainer}
                        style={{
                            height: imageContainerHeight
                                ? imageContainerHeight
                                : 'auto',
                            width: width,
                        }}
                    >
                        <img src={defaultImg}></img>
                    </div>
                    <div className={Styles.pcDescriptionContainer}>
                        <span className={Styles.pcDescriptionTitle}>
                            {title}
                        </span>
                        {tags && (
                            <div className={Styles.pcDescriptionTags}>
                                {tags.map((t) => (
                                    <span
                                        className={TypographyStyles.bodySmallAc}
                                        key={`pcTag${t}`}
                                    >
                                        {t}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={Styles.pcBottomContainer}>
                        <div className={cx(Flex.flex, Flex.alignItemsCenter)}>
                            <i className={Styles.pcUpdatedIcon}>
                                <TimeUpdated fill={ColorStyles.greyscale60} />
                            </i>
                            <span className={TypographyStyles.bodySmall}>
                                {DateTime.fromISO(date).toFormat('dd LLL yyyy')}
                            </span>
                        </div>
                        {canUserEditProject && (
                            <IconButton
                                containerProps={{
                                    className: Styles.pcMenuContainer,
                                }}
                                onClick={handleMenuClick}
                                icon={<MenuIcon />}
                                as="div"
                            />
                        )}
                    </div>
                </div>
            </DisplayCard>
        </React.Fragment>
    );
};
