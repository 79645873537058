import { FormLabelProps, styled } from '@mui/material';
import React from 'react';
import { Label } from './Label';

interface ILabelWithIconButtonProps extends FormLabelProps {
    iconButton: React.ReactNode;
    label: string;
    containerStyle?: React.CSSProperties;
    containerClassname?: string;
}

const LabelWithIconButtonContainer = styled('div')`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    align-items: center;
`;

export const LabelWithIconButton = (props: ILabelWithIconButtonProps) => {
    const {
        iconButton,
        label,
        containerClassname,
        containerStyle,
        ...labelProps
    } = props;
    return (
        <LabelWithIconButtonContainer
            style={containerStyle}
            className={containerClassname}
        >
            <Label disableMargin {...labelProps}>
                {label}
            </Label>
            <div style={{ marginLeft: '32px' }}>{iconButton}</div>
        </LabelWithIconButtonContainer>
    );
};
