import React from 'react';
import Styles from './Page.scss';

interface IPageContentProps {
    children: React.ReactNode;
}

export const PageContent = (props: IPageContentProps) => {
    return <div className={Styles.content}>{props.children}</div>;
};
