import ColorStyles from '@Styles/colors.scss';
import { api } from '@orbica/platform-sdk-dev';
import { IWorkspace } from './Interfaces';

export function getWorkspaceColor(index: number) {
    const remainder = index % 8;

    switch (remainder) {
        case 0:
            return ColorStyles.darkBlue;
        case 1:
            return ColorStyles.suntan;
        case 2:
            return ColorStyles.stone;
        case 3:
            return ColorStyles.salmon;
        case 4:
            return ColorStyles.teal;
        case 5:
            return ColorStyles.gold;
        case 6:
            return ColorStyles.violet;
        case 7:
            return ColorStyles.wine;
    }
}

export function combineWorkspaceAndInfo(
    workspaceObject: api.IWorkspace,
    info: { projectCount: number }
): IWorkspace {
    return {
        ...workspaceObject,
        projectCount: info.projectCount,
    };
}
