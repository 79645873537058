import React from 'react';
import { useIntl } from 'react-intl';
import { WorkspaceKeys } from '@Services/i18n/keys';
import CreateCard from '../CreateCard';
import { IImplementedCreateCardProps } from '../Interfaces';
import Styles from './ProjectCards.scss';

export const CreateProjectCard = (props: IImplementedCreateCardProps) => {
    const intl = useIntl();

    return (
        <CreateCard
            className={Styles.createProjectCardBase}
            cardClasses={Styles.createProjectCard}
            title={intl.formatMessage({
                id: WorkspaceKeys.CreateProject,
                defaultMessage: 'Create New Project',
            })}
            onClick={props.onClick}
        />
    );
};
