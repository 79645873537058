import _orderBy from 'lodash/orderBy';
import { useIntl } from 'react-intl';
import { SortOrder } from '@Services/helpers/Sorting';
import { SortingKeys } from '@Services/i18n/keys';
import { IProject, ProjectSortType } from './Interfaces';

export function getSortTypeString(type: ProjectSortType): string {
    const intl = useIntl();

    switch (type) {
        case ProjectSortType.CREATED:
            return intl.formatMessage({
                id: SortingKeys.DateCreated,
                defaultMessage: 'Date created',
            });
        case ProjectSortType.UPDATED:
            return intl.formatMessage({
                id: SortingKeys.DateUpdated,
                defaultMessage: 'Last modified',
            });
        case ProjectSortType.NAME:
            return intl.formatMessage({
                id: SortingKeys.Alphabetical,
                defaultMessage: 'Alphabetical',
            });
    }
}

export function getSortedProjects(
    order: SortOrder,
    type: ProjectSortType,
    projects: Array<IProject>
): Array<IProject> {
    const sortOrderStr = order === SortOrder.ASCENDING ? 'asc' : 'desc';

    switch (type) {
        case ProjectSortType.CREATED:
            return _orderBy(
                projects,
                (p: IProject) => {
                    return new Date(p.created_at);
                },
                sortOrderStr
            );
        case ProjectSortType.UPDATED:
            return _orderBy(
                projects,
                (p: IProject) => {
                    return new Date(p.updated_at);
                },
                sortOrderStr
            );
        case ProjectSortType.NAME:
            return _orderBy(
                projects,
                (p) => {
                    return p.display_name.toLowerCase();
                },
                sortOrderStr
            );
    }
}
