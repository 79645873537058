import { api } from '@orbica/platform-sdk-dev';

export interface ISolution extends api.IService {
    logo?: any;
    outputs: {
        endpoint?: string;
        type?: string;
    };
}

export enum AddSolutionType {
    DATA_STORE,
    ANALYTICS,
    CUSTOM,
}

export interface IAddServiceCatalogService extends api.IServiceCatalogService {
    id: string;
    name: string;
    displayName: string;
    logo: any;
    info: string;
    documentationUrl: string;
    contents: IServiceContent[];
    settings: IAddServiceSettings;
}

export interface IServiceContent {
    name: string;
    description: string;
    documentationUrl: string;
}

export interface IAddServiceSettings {
    addServiceType: AddSolutionType;
    inputs: IAddServiceInput;
}

interface IAddServiceInput {}

export interface IAddDatastoreInputs extends IAddServiceInput {
    instanceSize: string;
    hasuraSecretKey: string;
}

export interface IAddAnalyticsInputs extends IAddServiceInput {
    password: string;
    useDatabase: boolean;
    resourceLimit: IAnalyticsResourceLimitInput;
    analyticsVersion: IAnalyticsVersionInput;
}

export interface IAnalyticsResource {
    cpu: string;
    memory: string;
    label: string;
}

export interface IAnalyticsResourceLimitInput {
    options: Array<IAnalyticsResource>;
    selectedResourceLimit: IAnalyticsResource;
}

export interface IAnalyticsVersion {
    label: string;
    id: 'gis' | 'ai';
}

export interface IAnalyticsVersionInput {
    options: Array<IAnalyticsVersion>;
    selectedVersion: IAnalyticsVersion;
}

export interface IAddCustomServiceInputs extends IAddServiceInput {
    name: string;
    displayName: string;
    customServiceSettings: string;
}

export interface IOrbicaEntity {
    name: string;
    id: string;
}

export interface ICurrentUserInformation {
    organization: IOrbicaEntity;
    workspace: IOrbicaEntity;
    project: IOrbicaEntity;
}
