import CheckedRadio from '@Icons/opinionated/radio.svg';
import ColorStyles from '@Styles/colors.scss';
import { Radio } from '@mui/material';
import { styled } from '@mui/system';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { InputSize } from '@Components/Inputs';
import { FormControlLabel } from './FormControlLabel';
import Styles from './FormControls.scss';
import { IRadioProps } from './Interfaces';

const RadioIcon = styled('span')(() => ({
    borderRadius: '50%',
    border: `1px solid ${ColorStyles.greyscale40}`,
    backgroundColor: '#fff',
    height: '20px',
    width: '20px',
    'input:hover ~ &': {
        backgroundColor: `${ColorStyles.greyscale10}`,
    },
}));

const CheckedRadioIcon = styled('span')(() => ({
    borderRadius: '50%',
    backgroundImage: `url(${CheckedRadio})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '20px',
    width: '20px',
}));

export const RadioButton = (props: IRadioProps) => {
    const {
        label,
        id,
        onClick,
        containerStyle,
        containerClassName,
        ...radioProps
    } = props;
    const radioId = id ? id : `radio${Math.random().toString(16)}`;

    return (
        <div
            className={cx(Styles.formControlsContainer, containerClassName)}
            style={containerStyle}
        >
            <Radio
                id={radioId}
                disableRipple
                icon={<RadioIcon />}
                checkedIcon={<CheckedRadioIcon />}
                {...radioProps}
                onClick={onClick}
            />
            {!_isNil(label) && (
                <FormControlLabel
                    htmlFor={radioId}
                    focused={props.checked}
                    value={label}
                    size={InputSize.MEDIUM}
                    onClick={onClick}
                    style={{
                        marginLeft: '12px',
                    }}
                />
            )}
        </div>
    );
};
