import { RadioGroup } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import { RadioButton } from '@Components/FormControls/RadioButton';
import { FormKeys, SettingsKeys } from '@Services/i18n/keys';
import { ISettingsUser } from './Interfaces';
import Styles from './Settings.scss';

interface IManageUserRoleDialogProps {
    isOpen: boolean;
    onClose: () => void;
    user: ISettingsUser;
    availableRoles: any[];
    onUpdateRole: (user: ISettingsUser, role: any) => void;
    title: string;
}

export const ManageUserRoleDialog = (props: IManageUserRoleDialogProps) => {
    const { isOpen, onClose, user, availableRoles, onUpdateRole, title } =
        props;

    const intl = useIntl();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<any>(null);

    useEffect(() => {
        if (!_isNil(user) && !_isNil(availableRoles)) {
            setSelectedRole(user.role);
        }
    }, [user, availableRoles]);

    const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRole = availableRoles.find((r) => r.id === event.target.value);
        setSelectedRole(newRole);
    };

    const handleUpdateRole = async () => {
        setIsSubmitting(true);
        await onUpdateRole(user, selectedRole);
        setIsSubmitting(false);
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogHeader title={title} onClose={onClose} />
            <DialogBody>
                <RadioGroup
                    aria-label="roles"
                    value={selectedRole ? selectedRole.id : null}
                    onChange={handleChangeRole}
                    className={Styles.rolesRadioGroup}
                >
                    {availableRoles.map((role) => (
                        <RadioButton
                            label={role.name}
                            value={role.id}
                            key={role.id}
                        />
                    ))}
                </RadioGroup>
            </DialogBody>
            <DialogFooter
                onCancel={onClose}
                onSubmit={handleUpdateRole}
                submitText={intl.formatMessage({
                    id: SettingsKeys.UpdateRole,
                    defaultMessage: 'Update Role',
                })}
                cancelText={intl.formatMessage({
                    id: FormKeys.Cancel,
                    defaultMessage: 'Cancel',
                })}
                isSubmitting={isSubmitting}
                submittingText={intl.formatMessage({
                    id: SettingsKeys.UpdatingRole,
                    defaultMessage: 'Updating Role',
                })}
            />
        </Dialog>
    );
};
