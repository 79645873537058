import { ReactComponent as PlusIcon } from '@Icons/opinionated/plus-large.svg';
import Flex from '@Styles/flex.scss';
import LayoutStyles from '@Styles/layout.scss';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ButtonSize, PrimaryButton } from '@Components/Buttons';
import { ToastType } from '@Components/Toasts';
import { canCurrentUserEditWorkspaceState } from '@Data/auth';
import {
    ProjectDisplayType,
    projectSortOrderState,
    projectSortTypeState,
} from '@Data/projects';
import { IWorkspace, WorkspacesApi } from '@Data/workspaces';
import { toTitleCase } from '@Services/helpers/Strings';
import { EntityKeys, FormKeys, WorkspaceKeys } from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';
import { NoWorkspaces } from '../NoEntityMessages';
import { BtnSkeleton, SortingSkeleton, TitleSkeleton } from './Skeletons';
import { SortOrderSelector, ViewTypeSelector } from './Sorting';
import Styles from './WorkspaceController.scss';
import WorkspaceTitle from './WorkspaceTitle/WorkspaceTitle';

interface IWorkspaceControllerProps {
    isLoading: boolean;
    selectedWorkspace: IWorkspace;
    projectDisplayType: ProjectDisplayType;
    onOpenCreateProject: () => void;
    organizationId: string;
}

const WorkspaceController: React.FC<IWorkspaceControllerProps> = (props) => {
    const intl = useIntl();
    const projectSortOrder = useSelector(projectSortOrderState);
    const projectSortType = useSelector(projectSortTypeState);
    const canCurrentUserEditWorkspace = useSelector(
        canCurrentUserEditWorkspaceState
    );

    const handleDelete = async (): Promise<boolean> => {
        const wasSuccessful = await WorkspacesApi.deleteWorkspace(
            props.selectedWorkspace
        );

        if (wasSuccessful) {
            const workspaces = await WorkspacesApi.loadWorkspaces(
                props.organizationId
            );
            WorkspacesApi.setSelectedWorkspace(workspaces[0]);
            const workspaceStr = toTitleCase(
                intl.formatMessage({
                    id: EntityKeys.Workspace,
                    defaultMessage: 'workspace',
                })
            );
            createToast(
                intl.formatMessage(
                    {
                        id: FormKeys.ObjectDeleted,
                        defaultMessage: 'Workspace deleted',
                    },
                    { object: workspaceStr }
                ),
                ToastType.SUCCESS
            );
        }

        return wasSuccessful;
    };

    if (!props.isLoading && _isNil(props.selectedWorkspace)) {
        return <NoWorkspaces />;
    } else {
        let topSection, bottomSection;
        if (props.isLoading) {
            topSection = (
                <React.Fragment>
                    <TitleSkeleton />
                    <BtnSkeleton />
                </React.Fragment>
            );

            bottomSection = <SortingSkeleton />;
        } else if (!_isNil(props.selectedWorkspace)) {
            topSection = (
                <>
                    <WorkspaceTitle
                        workspace={props.selectedWorkspace}
                        onDelete={handleDelete}
                    />
                    {canCurrentUserEditWorkspace && (
                        <PrimaryButton
                            text={intl.formatMessage({
                                id: WorkspaceKeys.NewProject,
                                defaultMessage: 'New Project',
                            })}
                            icon={<PlusIcon />}
                            size={ButtonSize.LARGE}
                            onClick={props.onOpenCreateProject}
                        />
                    )}
                </>
            );

            bottomSection = (
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            visibility:
                                props.projectDisplayType ===
                                ProjectDisplayType.CARD
                                    ? 'visible'
                                    : 'hidden',
                        }}
                    >
                        <SortOrderSelector
                            selectedSortOrder={projectSortOrder}
                            selectedSortType={projectSortType}
                        />
                        <div
                            className={cx(
                                Styles.sortingDivider,
                                LayoutStyles.dividerHorizontal
                            )}
                        />
                    </div>

                    <ViewTypeSelector
                        selectedDisplayType={props.projectDisplayType}
                    />
                </>
            );
        }

        return (
            <div className={Styles.controllerContainer}>
                <div className={Styles.titleContainer}>{topSection}</div>
                <div className={cx(LayoutStyles.dividerVertical, Flex.flex)} />
                <div className={Styles.sortingContainer}>{bottomSection}</div>
            </div>
        );
    }
};

export default WorkspaceController;
