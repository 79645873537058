import { api } from '@orbica/platform-sdk-dev';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@Services/redux/reduxStore';
import { IOrgUserDetails, IOrganization } from './Interfaces';
import { IOrganizationsState, initialOrganizationsState } from './State';

const OrganizationsSlice = createSlice({
    name: 'Organizations',
    initialState: initialOrganizationsState,
    reducers: {
        updateOrganizations(
            state: IOrganizationsState,
            action: PayloadAction<Array<IOrganization>>
        ) {
            state.organizations = action.payload;
        },
        setSelectedOrganization(
            state: IOrganizationsState,
            action: PayloadAction<IOrganization>
        ) {
            state.selectedOrganization = action.payload;
        },
        setSelectedOrganizationUsers(
            state: IOrganizationsState,
            action: PayloadAction<IOrgUserDetails[]>
        ) {
            state.selectedOrganizationUsers = action.payload;
        },
        setRoles(
            state: IOrganizationsState,
            action: PayloadAction<api.IRole[]>
        ) {
            state.roles = action.payload;
        },
    },
});

export const OrganizationsActions = OrganizationsSlice.actions;

export const organizationsState = (state: RootState) =>
    state.organizations.organizations;
export const selectedOrganizationState = (state: RootState) =>
    state.organizations.selectedOrganization;
export const selectedOrganizationUsersState = (state: RootState) =>
    state.organizations.selectedOrganizationUsers;
export const organizationRolesState = (state: RootState) =>
    state.organizations.roles;

export default OrganizationsSlice.reducer;
