import ColorsStyles from '@Styles/colors.scss';
import ShadowsStyles from '@Styles/shadows.scss';
import { styled } from '@mui/material';

export const StyledDialogCard = styled('div')`
    background-color: ${ColorsStyles.neutral3};
    border-radius: 8px;
    max-height: 80vh;
    overflow: hidden;
    box-shadow: ${ShadowsStyles.shadowLarge};
`;
