import { Skeleton } from '@mui/material';
import React from 'react';

export interface IWorkspaceButtonSkeletonProps {
    className?: string;
    isStretched?: boolean;
}

export const WorkspaceButtonSkeleton = (
    props: IWorkspaceButtonSkeletonProps
) => {
    return (
        <Skeleton
            animation="wave"
            variant="text"
            sx={{
                height: '22px',
                width: props.isStretched ? '175px' : '125px',
                margin: '8px 0',
            }}
            className={props.className}
        />
    );
};
