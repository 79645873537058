import TypographyStyles from '@Styles/typography.scss';
import cx from 'classnames';
import { InputSize } from '@Components/Inputs';
import { SelectType } from './Interfaces';
import Styles from './Select.scss';

export const getPadding = (size?: InputSize): string => {
    switch (size) {
        case InputSize.LARGE:
            return '13px 16px';
        case InputSize.MEDIUM:
            return '10px 12px';
        case InputSize.SMALL:
        default:
            return '6px 12px';
    }
};

export const getRootClasses = (size: InputSize): string => {
    switch (size) {
        case InputSize.LARGE:
            return cx(TypographyStyles.bodyNormal, Styles.selectLarge);
        case InputSize.MEDIUM:
            return cx(TypographyStyles.bodyMedium, Styles.selectMedium);
        case InputSize.SMALL:
            return cx(TypographyStyles.bodySmall, Styles.selectSmall);
    }
};

export const getListboxClasses = (
    size: InputSize,
    type: SelectType
): string => {
    let output;
    switch (size) {
        case InputSize.LARGE:
            output = Styles.selectListboxLarge;
            break;
        case InputSize.MEDIUM:
            output = Styles.selectListboxMedium;
            break;
        case InputSize.SMALL:
            output = Styles.selectListboxSmall;
            break;
    }
    return cx(
        output,
        type === SelectType.PRIMARY
            ? Styles.selectListboxPrimary
            : Styles.selectListboxSecondary
    );
};
