import _isNil from 'lodash/isNil';
import React from 'react';
import { toast } from 'react-toastify';
import { Toast, ToastStyles, ToastType } from '@Components/Toasts';
import { NotificationsApi } from '@Data/notifications';
import { ICreatePersistentNotification } from './Interfaces';

export function createToast(
    message: string,
    type: ToastType,
    persistentNotificationData?: ICreatePersistentNotification
) {
    const toastId = _isNil(persistentNotificationData)
        ? Math.random().toString(32)
        : persistentNotificationData.toastId;

    toast(<Toast id={toastId} message={message} type={type} />, {
        bodyClassName: ToastStyles.toastBody,
        className: ToastStyles.toastRoot,
        closeOnClick: false,
        toastId: toastId,
        data: persistentNotificationData,
    });

    if (!_isNil(persistentNotificationData)) {
        NotificationsApi.upsertPersistentNotification({
            ...persistentNotificationData,
            type: type,
            message: message,
            updated: Date.now(),
        });
    }
}
