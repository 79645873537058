import { ButtonProps, TooltipProps as MuiTooltipProps } from '@mui/material';
import { ReactElement } from 'react';
import { ButtonRouterProps } from '@Components/MuiRouterLink';

export enum ButtonSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export interface IButtonProps extends ButtonProps, ButtonRouterProps {
    text: string;
    size: ButtonSize;
    icon?: ReactElement;
    displayChevron?: boolean;
}

export interface IPrimaryButtonProps extends IButtonProps {
    isLoading?: boolean;
    loadingText?: string;
}

type TooltipProps = Omit<MuiTooltipProps, 'children' | 'title'>;

/**
 * @noInheritDoc
 */
export interface IIconButtonProps
    extends React.HTMLAttributes<HTMLButtonElement> {
    icon: ReactElement;
    fill?: string;
    stroke?: string;
    tooltip?: string;
    /**
     * Material UI props
     */
    tooltipProps?: TooltipProps;
    disabled?: boolean;
    containerProps?: any;
    /**
     * used to render the icon button as another HTML element
     */
    as?: React.ElementType<any>;
}
