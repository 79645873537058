import InputUnstyled from '@mui/base/InputUnstyled';
import { inputUnstyledClasses } from '@mui/base/InputUnstyled';
import { styled } from '@mui/system';
import { Rect } from '@popperjs/core';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useId } from 'react';
import { Label } from '@Components/Label';
import { getRootInputClass, getTextboxClass } from './Helpers';
import { ITextInputProps } from './Interfaces';
import RootUnstyled from './Root';
import Styles from './TextInput.scss';

const StyledInput = styled('input')`
    &.${inputUnstyledClasses.focused}, &:focus-visible {
        border: none;
        outline: none;
    }
`;

const StyledTextArea = styled('textarea', {
    shouldForwardProp: (prop) =>
        !['ownerState', 'minRows', 'maxRows'].includes(prop.toString()),
})`
    &.${inputUnstyledClasses.focused}, &:focus-visible {
        border: none;
        outline: none;
    }
`;

const CustomInput = React.forwardRef(function TextInput(
    props: ITextInputProps,
    ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
) {
    const { size, className, onBlur, useMonospace, rows, ...inputProps } =
        props;

    return (
        <InputUnstyled
            ref={ref}
            data-cy={props.id}
            slots={{
                input: StyledInput,
                root: RootUnstyled,
                textarea: StyledTextArea,
            }}
            slotProps={{
                root: {
                    className: getRootInputClass(
                        size,
                        props.multiline,
                        props.error,
                        useMonospace
                    ),
                },
                input: {
                    className: cx(getTextboxClass(size), className),
                    onBlur: onBlur,
                    //@ts-ignore
                    rows: rows,
                },
            }}
            {...inputProps}
        />
    );
});

/**
 * A styled Material UI unstyled text input/textarea
 * NB: autofocus in a dialog may not work in development mode when React.StrictMode is enforced.
 * To test forms you may need to edit the code in src/index.tsx
 */
export const TextInput = React.forwardRef(function TextInputComponent(
    props: ITextInputProps,
    ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
) {
    const {
        containerStyle,
        containerClassName,
        id,
        error,
        helperText,
        ...inputProps
    } = props;

    const isError = error ?? false;
    const inputId = id ?? useId();
    const helperTextStr = helperText ?? '';

    return (
        <div
            className={cx(Styles.inputContainer, containerClassName, {
                [Styles.error]: error,
            })}
            style={containerStyle}
        >
            {!_isNil(props.label) && (
                <Label htmlFor={inputId} required={props.required}>
                    {props.label}
                </Label>
            )}
            <CustomInput {...inputProps} error={error} id={inputId} ref={ref} />
            {isError && (
                <div className={Styles.helperText}>{helperTextStr}</div>
            )}
        </div>
    );
});
