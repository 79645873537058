import logoImg from '@Images/logo.svg';
import TypographyStyles from '@Styles/typography.scss';
import { Card } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { ButtonSize, PrimaryButton } from '@Components/Buttons';
import { AuthKeys } from '@Services/i18n/keys';
import Styles from './LoginView.scss';

const LoginView = () => {
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
    const auth = useAuth();
    const intl = useIntl();

    const handleRedirect = () => {
        setIsRedirecting(true);
        auth.signinRedirect();
    };

    return (
        <div className={Styles.loginContainer}>
            <div className={Styles.header}>
                <div className={Styles.headerBackground} />
                <a href="https://www.orbica.com/" className={Styles.logo}>
                    <img src={logoImg} className={Styles.logoImg} />
                </a>
            </div>
            <div className={Styles.loginCardContainer}>
                <Card>
                    <div className={Styles.cardBody}>
                        <span className={TypographyStyles.h1}>
                            {intl.formatMessage({
                                id: AuthKeys.SignIn,
                                defaultMessage: 'Sign in',
                            })}
                        </span>
                        <PrimaryButton
                            id="login-button"
                            size={ButtonSize.LARGE}
                            text={intl.formatMessage({
                                id: AuthKeys.LogIn,
                                defaultMessage: 'Log in',
                            })}
                            onClick={handleRedirect}
                            displayChevron
                            className={Styles.loginButton}
                            isLoading={isRedirecting}
                            loadingText={intl.formatMessage({
                                id: AuthKeys.Redirecting,
                                defaultMessage: 'Redirecting',
                            })}
                        />
                    </div>
                </Card>
                <div />
            </div>
        </div>
    );
};

export default LoginView;
