const Workspaces = {
    CreateProject: 'workspaces.createProject',
    NewProject: 'workspaces.newProject',
    CreateNewWorkspace: 'workspaces.createNewWorkspace',
    WorkspaceName: 'workspaces.workspaceName',
    NoProjectsAdded: 'workspaces.noProjectsAdded',
    NoOrganizations: 'workspaces.noOrganizations',
    AddMembers: 'workspaces.addMembers',
    setWorkspaceRole: 'workspaces.setWorkspaceRole',
};

export default Workspaces;
