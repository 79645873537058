import LayoutStyles from '@Styles/layout.scss';
import _cloneDeep from 'lodash/cloneDeep';
import _isNil from 'lodash/isNil';
import React, { useRef } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { InputSize, TextInput } from '@Components/Inputs';
import { LabelWithIconTooltip } from '@Components/Label';
import {
    IIntlMessageDescriptor,
    isEntityDisplayNameValid,
    isEntityNameValid,
    transformDisplayNameToName,
} from '@Services/helpers/Entities/EntityHelpers';
import useOnEnter from '@Services/hooks/useOnEnter';
import { EntityKeys, FormKeys, SolutionKeys } from '@Services/i18n/keys';
import { RemainingCharactersAdornment } from './RemainingCharactersAdornment/RemainingCharactersAdornment';

export type EntityNameDisplayNameValues = {
    displayName?: string;
    name?: string;
};

export interface IEntityDisplayNameAndNameInputsProps {
    nameValue: string;
    displayNameValue: string;
    onChange: (newValues: EntityNameDisplayNameValues) => void;
    protectedNames?: string[];
    entity: string;
    parentEntity?: string;
    /**
     * the className applied to the container of the text inputs.
     * default is grid-column: 1/-1;
     */
    inputClassNames?: string;
    isEditing?: boolean;
    onEnter?: () => void;
}

function parseErrorMessages(
    errorMessages: IIntlMessageDescriptor[],
    intl: IntlShape
): string {
    if (_isNil(errorMessages)) {
        return null;
    }

    return `${errorMessages
        .map((message) => intl.formatMessage(message.message, message.options))
        .join(', ')}.`;
}

export const EntityDisplayNameAndNameInputs = (
    props: IEntityDisplayNameAndNameInputsProps
) => {
    const intl = useIntl();

    const displayNameInputRef = useRef(null);
    const nameInputRef = useRef(null);

    useOnEnter([displayNameInputRef, nameInputRef], () => {
        if (!_isNil(props.onEnter)) {
            props.onEnter();
        }
    });

    const inputClassNames =
        props.inputClassNames ?? LayoutStyles.dialogInputFull;

    const handleDisplayNameChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const displayNameValue = e.target.value;
        const parsedName = transformDisplayNameToName(displayNameValue);

        props.onChange({
            displayName: displayNameValue,
            name: parsedName,
        });
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange({ name: e.target.value });
    };

    const isDisplayNameValid = isEntityDisplayNameValid(props.displayNameValue);

    const isNameValid = isEntityNameValid(
        props.nameValue,
        props.protectedNames,
        props.parentEntity
    );

    return (
        <>
            <TextInput
                size={InputSize.LARGE}
                value={props.displayNameValue}
                label={intl.formatMessage(
                    {
                        id: EntityKeys.EntityDisplayName,
                        defaultMessage: `${props.entity} Name`,
                    },
                    {
                        entity: props.entity,
                    }
                )}
                containerClassName={inputClassNames}
                required
                onChange={handleDisplayNameChange}
                error={!isDisplayNameValid.isValid}
                helperText={parseErrorMessages(
                    isDisplayNameValid.errorMessages,
                    intl
                )}
                endAdornment={
                    <RemainingCharactersAdornment
                        value={props.displayNameValue}
                    />
                }
                placeholder={intl.formatMessage({
                    id: FormKeys.NamePlaceholder,
                    defaultMessage: 'Write a consise name.',
                })}
                autoFocus
                ref={displayNameInputRef}
            />

            {!props.isEditing && (
                <>
                    <div className={inputClassNames}>
                        <LabelWithIconTooltip
                            label={intl.formatMessage(
                                {
                                    id: EntityKeys.EntityName,
                                    defaultMessage: `${props.entity} Id`,
                                },
                                {
                                    entity: props.entity,
                                }
                            )}
                            tooltipTitle={intl.formatMessage(
                                {
                                    id: EntityKeys.NameInputTooltip,
                                    defaultMessage: `The name that will be used as an identifier for your new ${props.entity}. This must be unique, start with a lowercase letter, and only contain lowercase letters, numbers, and dashes. This cannot be edited.`,
                                },
                                { entity: props.entity }
                            )}
                            required
                        />
                        <TextInput
                            size={InputSize.LARGE}
                            value={props.nameValue}
                            onChange={handleNameChange}
                            error={!isNameValid.isValid}
                            helperText={parseErrorMessages(
                                isNameValid.errorMessages,
                                intl
                            )}
                            endAdornment={
                                <RemainingCharactersAdornment
                                    value={props.nameValue}
                                />
                            }
                            placeholder={intl.formatMessage(
                                {
                                    id: EntityKeys.NameInputPlaceholder,
                                    defaultMessage: `Our internal id for your ${props.entity}`,
                                },
                                { entity: props.entity }
                            )}
                            disabled={props.isEditing}
                            ref={nameInputRef}
                        />
                    </div>
                </>
            )}
        </>
    );
};
