import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

/**
 * A wrapper around a React Router <Link/> Component.
 * Implementation is intended to be used by setting a component's props using ./Exports/getRoleProps().
 */

// eslint-disable-next-line react/display-name
export const RouterLink = React.forwardRef<
    HTMLAnchorElement,
    Omit<LinkProps, 'to'> & { href: LinkProps['to'] } & { ownerState }
>((props, ref) => {
    const { href, relative, ownerState, ...other } = props;
    return <Link ref={ref} to={href} relative={relative} {...other} />;
});
