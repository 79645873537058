import React from 'react';
import { useIntl } from 'react-intl';
import { WorkspaceKeys } from '@Services/i18n/keys';
import Styles from './NoEntities.scss';

export const NoOrganizations = () => {
    const intl = useIntl();

    return (
        <div className={Styles.noEntitiesContainer}>
            <span>
                {intl.formatMessage({
                    id: WorkspaceKeys.NoOrganizations,
                    defaultMessage:
                        'You do not belong to any Organizations, please contact your Organization Administrator',
                })}
            </span>
        </div>
    );
};
