import _isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import {
    ButtonSize,
    PrimaryButton,
    SecondaryButton,
} from '@Components/Buttons';
import { FormKeys } from '@Services/i18n/keys';
import Styles from './Dialog.scss';
import { IDialogFooterProps } from './Interfaces';

export const DialogFooter = (props: IDialogFooterProps) => {
    const intl = useIntl();

    const cancelMsg = _isNil(props.cancelText)
        ? intl.formatMessage({ id: FormKeys.Cancel, defaultMessage: 'Cancel' })
        : props.cancelText;
    const submitMsg = _isNil(props.submitText)
        ? intl.formatMessage({ id: FormKeys.Submit, defaultMessage: 'Submit' })
        : props.submitText;

    return (
        <div className={Styles.dialogFooter} data-cy="dialog-footer">
            {!props.hideCancelBtn && (
                <SecondaryButton
                    text={cancelMsg}
                    size={ButtonSize.LARGE}
                    color={'greyscale40'}
                    disabled={props.isSubmitting}
                    onClick={props.onCancel}
                    className={Styles.footerCancelBtn}
                />
            )}
            {!props.hideSubmitBtn && (
                <PrimaryButton
                    text={submitMsg}
                    size={ButtonSize.LARGE}
                    displayChevron
                    disabled={props.isSubmitDisabled}
                    onClick={props.onSubmit}
                    isLoading={props.isSubmitting}
                    loadingText={props.submittingText}
                    className={Styles.footerSubmitBtn}
                />
            )}
        </div>
    );
};
