import LayoutStyles from '@Styles/layout.scss';
import { api } from '@orbica/platform-sdk-dev';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import {
    EntityDisplayNameAndNameInputs,
    EntityNameDisplayNameValues,
} from '@Components/FormFields';
import { InputSize, TextInput } from '@Components/Inputs';
import { Select, SelectOption, SelectType } from '@Components/Select';
import { ToastType } from '@Components/Toasts';
import { ClustersApi, clustersState } from '@Data/clusters';
import { selectedOrganizationState } from '@Data/organizations';
import { IProject, ProjectsApi } from '@Data/projects';
import { unusableProjectNamesState } from '@Data/projects/Reducer';
import { IWorkspace, WorkspacesApi, workspacesState } from '@Data/workspaces';
import {
    isEntityDisplayNameValid,
    isEntityNameValid,
} from '@Services/helpers/Entities/EntityHelpers';
import { toTitleCase } from '@Services/helpers/Strings';
import {
    EntityKeys,
    FormKeys,
    ProjectKeys,
    WorkspaceKeys,
} from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';
import { createToast } from '@Services/notifications';
import Styles from './CreateProject.scss';

interface ICreateProjectDialogProps {
    currentWorkspace: IWorkspace;
    isOpen: boolean;
    onClose: () => void;
    selectedWorkspaceId: string;
    selectedOrganizationId: string;
}

const CreateProjectDialog = (props: ICreateProjectDialogProps) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [name, setName] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');
    const [workspace, setWorkspace] = useState<IWorkspace>(null);
    const [description, setDescription] = useState<string>('');
    const [selectedCluster, setSelectedCluster] = useState<api.ICluster>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const workspaces = useSelector(workspacesState);
    const selectedOrganization = useSelector(selectedOrganizationState);
    const clusters = useSelector(clustersState);
    const existingProjectNames = useSelector(unusableProjectNamesState);

    useEffect(() => {
        ClustersApi.getClusters();
    }, []);

    useEffect(() => {
        setWorkspace(props.currentWorkspace);
    }, [props.currentWorkspace, props.isOpen]);

    const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        if (isEntityDisplayNameValid(newName)) {
            setName(newName);
        }
    };

    const handleSetLocation = (workspaceId: string) => {
        const workspace = workspaces?.find((w) => w.id === workspaceId);
        setWorkspace(workspace);
    };

    const handleSetCluster = (clusterId: string) => {
        const cluster = clusters?.find((c) => c.id === clusterId);
        setSelectedCluster(cluster);
    };

    const resetForm = () => {
        setName('');
        setDisplayName('');
        setWorkspace(null);
        setSelectedCluster(null);
        setDescription('');
        setIsSubmitting(false);
    };

    const handleClose = async () => {
        if (!isSubmitting) {
            await props.onClose();
            resetForm();
        }
    };

    const projectStr = intl.formatMessage({
        id: EntityKeys.Project,
        defaultMessage: 'project',
    });

    const handleUpdateInputFields = (
        newValues: EntityNameDisplayNameValues
    ) => {
        if (!_isNil(newValues.displayName)) {
            setDisplayName(newValues.displayName);
        }

        if (!_isNil(newValues.name)) {
            setName(newValues.name);
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const onCompletion = async (project: IProject) => {
            await props.onClose();
            const capitalizedStr = toTitleCase(projectStr);

            const toastMsg = intl.formatMessage(
                {
                    id: FormKeys.ObjectCreated,
                    defaultMessage: 'Project created',
                },
                { object: capitalizedStr }
            );

            createToast(toastMsg, ToastType.SUCCESS);

            const url = Routes.project.overview.getUrl(
                selectedOrganization.id,
                workspace.id,
                project.id
            );
            navigate(url);

            resetForm();
        };

        const newProject = await ProjectsApi.createProject(
            name,
            displayName,
            workspace.id,
            selectedCluster.id,
            description
        );
        await WorkspacesApi.loadWorkspaces(props.selectedOrganizationId);
        if (!_isNil(newProject)) {
            onCompletion(newProject);
        } else {
            setIsSubmitting(false);
        }
    };

    const displayNameValidation = isEntityDisplayNameValid(displayName);
    const nameValidation = isEntityNameValid(name);
    const canSubmit =
        displayNameValidation.isValid &&
        nameValidation.isValid &&
        name !== '' &&
        displayName !== '' &&
        !_isNil(workspace) &&
        !_isNil(selectedCluster);

    const title = intl.formatMessage({
        id: ProjectKeys.CreateANewProject,
        defaultMessage: 'Create a new project',
    });

    const submitText = intl.formatMessage({
        id: WorkspaceKeys.CreateProject,
        defaultMessage: 'Create Project',
    });

    const submittingText = intl.formatMessage(
        {
            id: FormKeys.CreatingObject,
            defaultMessage: 'Creating Project',
        },
        { object: projectStr }
    );

    return (
        <Dialog open={props.isOpen} onClose={handleClose}>
            <DialogHeader
                title={title}
                onClose={handleClose}
                isCloseDisabled={isSubmitting}
            />

            <DialogBody>
                <span className={Styles.title}>
                    {intl.formatMessage({
                        id: ProjectKeys.ProjectDetails,
                        defaultMessage: 'Project Details',
                    })}
                </span>
                <EntityDisplayNameAndNameInputs
                    nameValue={name}
                    displayNameValue={displayName}
                    onChange={handleUpdateInputFields}
                    protectedNames={existingProjectNames}
                    parentEntity={intl.formatMessage({
                        id: EntityKeys.Workspace,
                        defaultMessage: 'workspace',
                    })}
                    entity={intl.formatMessage({
                        id: EntityKeys.Project,
                        defaultMessage: 'project',
                    })}
                />
                <Select
                    selectType={SelectType.PRIMARY}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: EntityKeys.Workspace,
                        defaultMessage: 'Workspace',
                    })}
                    required
                    value={workspace ? workspace.display_name : ''}
                    containerClassName={LayoutStyles.dialogInputMediumSmall}
                    onChange={(e, v) => handleSetLocation(v)}
                >
                    {workspaces.map((workspace) => (
                        <SelectOption key={workspace.id} value={workspace.id}>
                            {workspace.display_name}
                        </SelectOption>
                    ))}
                </Select>
                <Select
                    selectType={SelectType.PRIMARY}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: ProjectKeys.ClusterLocation,
                        defaultMessage: 'Cluster Location',
                    })}
                    required
                    value={
                        !_isNil(selectedCluster)
                            ? toTitleCase(selectedCluster.name)
                            : undefined
                    }
                    containerClassName={LayoutStyles.dialogInputMediumSmall}
                    onChange={(e, v) => handleSetCluster(v)}
                    placeholder={intl.formatMessage({
                        id: ProjectKeys.SelectAClusterLocation,
                        defaultMessage: 'Select a cluster location',
                    })}
                >
                    {clusters.map((cluster) => (
                        <SelectOption key={cluster.id} value={cluster.id}>
                            {toTitleCase(cluster.name)}
                        </SelectOption>
                    ))}
                </Select>
                <TextInput
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    size={InputSize.LARGE}
                    label={intl.formatMessage({
                        id: ProjectKeys.Description,
                        defaultMessage: 'Description',
                    })}
                    containerClassName={LayoutStyles.dialogInputFull}
                    multiline
                    className={Styles.cpTextArea}
                    placeholder={intl.formatMessage({
                        id: ProjectKeys.DescriptionPlaceholder,
                        defaultMessage:
                            'Write a clear description about your project - goals, problems, target audience, etc.',
                    })}
                    rows={4}
                />
            </DialogBody>
            <DialogFooter
                onCancel={handleClose}
                onSubmit={handleSubmit}
                isSubmitDisabled={!canSubmit}
                isSubmitting={isSubmitting}
                submitText={submitText}
                submittingText={submittingText}
            />
        </Dialog>
    );
};

export default CreateProjectDialog;
