import { ReactComponent as CreateWorkspaceIcon } from '@Icons/lg/folder-plus.svg';
import GridStyles from '@Styles/grid.scss';
import TypographyStyles from '@Styles/typography.scss';
import { Skeleton } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IconButton } from '@Components/Buttons';
import {
    WorkspaceButton,
    WorkspaceButtonSkeleton,
} from '@Components/Workspaces';
import { canCurrentUserEditOrgState } from '@Data/auth';
import { IWorkspace } from '@Data/workspaces';
import { toTitleCase } from '@Services/helpers/Strings';
import { EntityKeys, FormKeys, ViewKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';
import Styles from './WorkspacesList.scss';

interface IWorkspacesListProps {
    workspaces: Array<IWorkspace>;
    selectedOrganizationId: string;
    selectedWorkspaceId: string;
    isLoading: boolean;
    onCreateWorkspace: () => void;
}

const WorkspacesList: React.FC<IWorkspacesListProps> = (props) => {
    const intl = useIntl();
    const canCurrentUserEditOrg = useSelector(canCurrentUserEditOrgState);

    const children = props.isLoading ? (
        <React.Fragment>
            <WorkspaceButtonSkeleton
                className={GridStyles.fullWidthGridChild}
            />
            <WorkspaceButtonSkeleton
                isStretched
                className={GridStyles.fullWidthGridChild}
            />
            <WorkspaceButtonSkeleton
                className={GridStyles.fullWidthGridChild}
            />
        </React.Fragment>
    ) : (
        props.workspaces.map((workspace) => {
            const url = Routes.workspaces.getUrl(
                props.selectedOrganizationId,
                workspace.id
            );
            return (
                <WorkspaceButton
                    key={workspace.id}
                    workspace={workspace}
                    isSelected={workspace.id === props.selectedWorkspaceId}
                    href={url}
                    className={GridStyles.fullWidthGridChild}
                />
            );
        })
    );

    const workspaceStr = toTitleCase(
        intl.formatMessage({
            id: EntityKeys.Workspace,
            defaultMessage: 'workspace',
        })
    );

    const title = props.isLoading ? (
        <Skeleton variant="text" height={28} width={105} />
    ) : (
        <React.Fragment>
            <span className={TypographyStyles.h4}>
                {intl.formatMessage({
                    id: ViewKeys.Workspaces,
                    defaultMessage: 'Workspaces',
                })}
            </span>
            {canCurrentUserEditOrg && (
                <IconButton
                    icon={<CreateWorkspaceIcon />}
                    onClick={props.onCreateWorkspace}
                    tooltip={intl.formatMessage(
                        {
                            id: FormKeys.CreateObject,
                            defaultMessage: 'Create Workspace',
                        },
                        { object: workspaceStr }
                    )}
                />
            )}
        </React.Fragment>
    );

    return (
        <div className={Styles.workspacesList}>
            <div className={Styles.gridContainer}>
                <div className={Styles.titleContainer}>{title}</div>
            </div>

            <div
                className={Styles.workspaceBtnContainer}
                data-cy="drawers-menu-workspace"
            >
                {children}
            </div>
        </div>
    );
};

export default WorkspacesList;
