import { ReactComponent as OrganizationIcon } from '@Icons/md/account-company.svg';
import { ReactComponent as PersonalIcon } from '@Icons/md/account-personal.svg';
import TypographyStyles from '@Styles/typography.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { OrganizationType } from '@Data/organizations';
import { OrganizationKeys } from '@Services/i18n/keys';
import { IOrganizationOptionProps } from './Interfaces';
import Styles from './Organizations.scss';

/**
 * A styled div, intended to be used as the body of a SelectOption for an Organization Selector.
 */
export const OrganizationOption: React.FC<IOrganizationOptionProps> = (
    props
) => {
    const intl = useIntl();

    const icon =
        props.type === OrganizationType.ORGANIZATION ? (
            <OrganizationIcon />
        ) : (
            <PersonalIcon />
        );

    return (
        <div className={Styles.orgSelectorOption}>
            {icon}
            <div className={Styles.orgSelectorText}>
                <span className={TypographyStyles.bodyNormal}>
                    {props.name}
                </span>
                {props.type === OrganizationType.PERSONAL && (
                    <span className={Styles.personalTag}>
                        (
                        {intl.formatMessage({
                            id: OrganizationKeys.Personal,
                            defaultMessage: 'personal',
                        })}
                        )
                    </span>
                )}
            </div>
        </div>
    );
};
