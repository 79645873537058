import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import LocaleContext from './LocaleContext';
import { ILocale, Locales } from './Locales';

const DEFAULT_LOCALE = Locales.ENGLISH_UK;

const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedLocale, setSelectedLocale] =
        useState<ILocale>(DEFAULT_LOCALE);

    const handleLocaleSelection = (newLocale: ILocale) => {
        setSelectedLocale(newLocale);
    };

    return (
        <LocaleContext.Provider
            value={{
                changeLocale: (locale: ILocale) =>
                    handleLocaleSelection(locale),
                selectedLocale,
            }}
        >
            <IntlProvider
                locale={selectedLocale.code}
                messages={selectedLocale.messages}
                defaultLocale={DEFAULT_LOCALE.code}
            >
                {children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
};

export default LocalizationProvider;
