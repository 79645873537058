import React from 'react';
import { ILocale } from './Locales';

interface ILocaleContext {
    changeLocale: (language: ILocale) => void;
    selectedLocale: ILocale;
}

const LocaleContext = React.createContext<ILocaleContext>({
    selectedLocale: null,
    changeLocale: () => {
        console.warn('LocaleContext.changeLocale not implemented');
    },
});

export default LocaleContext;
