import { api } from '@orbica/platform-sdk-dev';

export interface IAuthState {
    hasUser: boolean;
    organizationRoles: api.IUserOrganizationRole[];
    workspaceRoles: api.IUserWorkspaceRole[];
    projectRoles: api.IUserProjectRole[];
}

export const initialAuthState: IAuthState = {
    hasUser: false,
    organizationRoles: null,
    workspaceRoles: null,
    projectRoles: null,
};
