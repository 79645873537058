import { useEffect } from 'react';

export default function useOnEnter(
    refs: React.MutableRefObject<HTMLInputElement>[],
    action: () => void
) {
    useEffect(() => {
        function onEnter(e: KeyboardEvent) {
            if (e.key === 'Enter') {
                action();
            }
        }

        refs.forEach((ref) => {
            if (ref.current) {
                ref.current.addEventListener('keyup', onEnter);
            }
        });

        return () => {
            refs.forEach((ref) => {
                if (ref.current) {
                    ref.current.removeEventListener('keyup', onEnter);
                }
            });
        };
    }, [refs]);
}
