import React, { InputHTMLAttributes } from 'react';

export enum InputSize {
    SMALL,
    MEDIUM,
    LARGE,
}

export interface IBaseInputProps {
    containerStyle?: object;
    containerClassName?: string;
    label?: string;
}

/**
 * @noInheritDoc
 */
export interface ITextInputProps
    extends InputHTMLAttributes<HTMLInputElement>,
        IBaseInputProps {
    size: InputSize;
    /**
     * Allows for adding a prefix to an input, eg. an icon to show the currency type.
     */
    startAdornment?: React.ReactElement;
    /**
     * Allows for adding a suffix to an input, eg. an icon to show/hide a password input's value.
     */
    endAdornment?: React.ReactElement;
    /**
     * If true, changes the input component to a textarea.
     */
    multiline?: any;
    /**
     * if true, sets font family to monospace font
     */
    /**
     * if multiline used, sets the inital row height of the textarea
     */
    rows?: number;
    useMonospace?: boolean;
    error?: boolean;
    helperText?: string;
    /**
     * Invoked when the input loses focus.
     */
    // eslint-disable-next-line -- we can't be certain of the shape of the function
    onBlur?: React.FocusEventHandler<HTMLInputElement>;

    ref?: any;
}
