import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    transition: theme.transitions.create(
        ['transform', 'border', 'box-shadow'],
        {
            duration: theme.transitions.duration.standard,
        }
    ),
    '&:hover': {
        transform: 'translateY(-10px)',
    },
}));

export default StyledCard;
