import theme from '@Styles/MuiTheming';
import _isNil from 'lodash/isNil';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import {
    EntityDisplayNameAndNameInputs,
    EntityNameDisplayNameValues,
} from '@Components/FormFields';
import { isEntityDisplayNameValid } from '@Services/helpers/Entities/EntityHelpers';
import { EntityKeys } from '@Services/i18n/keys';
import { IEntityNameDisplayNameProps } from './EntityNameDisplayName';

export interface IEditEntityDisplayNameDialogProps
    extends IEntityNameDisplayNameProps {
    isOpen: boolean;
    onClose: () => void;
}

export const EditEntityDisplayNameDialog = (
    props: IEditEntityDisplayNameDialogProps
) => {
    const intl = useIntl();

    const [displayName, setDisplayName] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (!_isNil(props.entityDisplayName)) {
            setDisplayName(props.entityDisplayName);
        }
    }, [props.entityDisplayName]);

    const handleClose = () => {
        props.onClose();
        setTimeout(() => {
            setDisplayName('');
            setIsSubmitting(false);
        }, theme.transitions.duration.standard);
    };

    const handleSubmitDisplayName = async () => {
        setIsSubmitting(true);
        await props.onSubmitDisplayName(displayName);
        handleClose();
    };

    const handleUpdateDisplayName = (newName: EntityNameDisplayNameValues) => {
        setDisplayName(newName.displayName);
    };

    const canSubmit =
        displayName !== '' &&
        displayName !== props.entityDisplayName &&
        isEntityDisplayNameValid(displayName).isValid;

    const title = intl.formatMessage(
        {
            id: EntityKeys.UpdateEntityDisplayName,
            defaultMessage: `Update ${props.entityType} Name`,
        },
        {
            entity: props.entityType,
        }
    );

    const handleEnter = () => {
        if (canSubmit) {
            handleSubmitDisplayName();
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogHeader title={title} onClose={handleClose} />
            <DialogBody>
                <EntityDisplayNameAndNameInputs
                    nameValue={props.entityName}
                    displayNameValue={displayName}
                    isEditing
                    entity={props.entityType}
                    parentEntity={props.parentEntityType}
                    onChange={handleUpdateDisplayName}
                    onEnter={handleEnter}
                />
            </DialogBody>
            <DialogFooter
                onCancel={handleClose}
                onSubmit={handleSubmitDisplayName}
                isSubmitDisabled={!canSubmit}
                submitText={title}
                isSubmitting={isSubmitting}
                submittingText={intl.formatMessage(
                    {
                        id: EntityKeys.UpdatingEntityDisplayName,
                        defaultMessage: `Updating ${props.entityType} Name`,
                    },
                    {
                        entity: props.entityType,
                    }
                )}
            />
        </Dialog>
    );
};
