import { ReactComponent as MenuIcon } from '@Icons/lg/more-horizontal.svg';
import defaultImg from '@Images/taranaki.png';
import ColorsStyles from '@Styles/colors.scss';
import cx from 'classnames';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { IconButton } from '@Components/Buttons';
import { Spinner } from '@Components/LoadingIndicators';
import { canCurrentUserEditProjectState } from '@Data/auth';
import { SolutionsApi } from '@Data/solutions';
import DisplayCard from '../DisplayCard';
import { ICardProps } from '../Interfaces';
import { getServiceStatusString } from './Helpers';
import { ServiceCardImageContainer } from './ServiceCardImageContainer';
import Styles from './ServiceCards.scss';

export interface IServiceCardProps extends ICardProps {
    name: string;
    logo?: string;
    status: string;
    serviceId: string;
    onOpenMenu: (anchorEl: HTMLElement) => void;
}

let hasLoaded = false;

export const ServiceCard = (props: IServiceCardProps) => {
    const { width, ref } = useResizeDetector<HTMLDivElement>();
    const canUserEditSolution = useSelector(canCurrentUserEditProjectState);
    const { logo, name, status, serviceId, onClick, onOpenMenu, ...cardProps } =
        props;
    const [imageContainerHeight, setImageContainerHeight] = useState<
        number | null
    >(null);

    useLayoutEffect(() => {
        setImageContainerHeight((width * 2) / 3);
    }, [width]);

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onOpenMenu(e.currentTarget);
    };

    useEffect(() => {
        if (!hasLoaded && status !== 'up' && status !== 'down') {
            hasLoaded = true;
            SolutionsApi.tryUpdatePendingSolution(serviceId);
        }
    }, [hasLoaded]);

    let serviceCardLogoHtml, baseCardClass;
    if (status !== 'up' && status !== 'down') {
        serviceCardLogoHtml = (
            <Spinner
                size={imageContainerHeight ? imageContainerHeight * 0.5 : 15}
                indicatorColor={ColorsStyles.darkBeige}
                thickness={2}
            />
        );
        baseCardClass = cx(
            Styles.serviceCardBase,
            status === 'down'
                ? Styles.serviceCardDown
                : Styles.serviceCardPending
        );
    } else {
        serviceCardLogoHtml = (
            <img src={logo ?? defaultImg} className={Styles.serviceCardLogo} />
        );
        baseCardClass = Styles.serviceCardBase;
    }

    return (
        <DisplayCard
            cardClasses={Styles.serviceCard}
            className={baseCardClass}
            {...cardProps}
        >
            <div ref={ref} className={Styles.refContainer}>
                <ServiceCardImageContainer
                    className={Styles.serviceCardLogoContainer}
                    image={serviceCardLogoHtml}
                />
                <div className={Styles.serviceCardDetailsContainer}>
                    <span className={Styles.serviceCardDetailsTitle}>
                        {name}
                    </span>
                    <div className={Styles.serviceCardDetailsLowerContainer}>
                        <span
                            className={
                                Styles.serviceCardDetailsLowerContainerText
                            }
                        >
                            {getServiceStatusString(status)}
                        </span>
                        {canUserEditSolution && (
                            <IconButton
                                icon={<MenuIcon />}
                                containerProps={{
                                    className: Styles.solutionMenuContainer,
                                }}
                                onClick={handleMenuClick}
                                as="div"
                            />
                        )}
                    </div>
                </div>
            </div>
        </DisplayCard>
    );
};
