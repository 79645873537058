import { IAddServiceCatalogService, ISolution } from './Interfaces';

export interface ISolutionsState {
    projectSolutions: Array<ISolution>;
    serviceCatalog: Array<IAddServiceCatalogService>;
    knativeServiceId: string;
    hasuraServiceId: string;
    selectedProjectService: ISolution;
}

export const initialSolutionsState: ISolutionsState = {
    projectSolutions: [],
    serviceCatalog: [],
    knativeServiceId: '',
    hasuraServiceId: '',
    selectedProjectService: null,
};
