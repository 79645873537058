import ColorStyles from '@Styles/colors.scss';
import { styled } from '@mui/material/styles';
import React from 'react';
import { IButtonProps } from './Interfaces';
import { StyledButton } from './StyledButton';

const getTextUnderlineOffset = (size: string): string => {
    switch (size) {
        case 'large':
            return '4px';
        case 'medium':
            return '3px';
        case 'small':
        default:
            return '2px';
    }
};

/**
 * Button with a transparent background and no border.
 * On hover, text darkens and is underlined.
 * Can be augmented with a leading icon, and/or a trailing chevron that moves on hover.
 */
const TertiaryStyledButton = styled(StyledButton)(({ theme, size, color }) => ({
    color: theme.palette[color].main,

    transition: theme.transitions.create(['color', 'text-decoration'], {
        duration: theme.transitions.duration.standard,
    }),

    '&:hover, &:focus': {
        color: ColorStyles.greyscale90,
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        textUnderlineOffset: getTextUnderlineOffset(size),
    },
}));

export const TertiaryButton: React.FC<IButtonProps> = (props) => {
    const color = props.color ?? 'matcha';
    return (
        <TertiaryStyledButton
            variant="text"
            size={props.size}
            color={color}
            {...props}
        >
            {props.text}
        </TertiaryStyledButton>
    );
};
