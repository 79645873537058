import _isNil from 'lodash/isNil';
import { MessageDescriptor } from 'react-intl';
import { EntityKeys } from '@Services/i18n/keys';

export const ENTITY_NAME_MAX_CHARACTERS = 63;

export interface IIntlMessageDescriptor {
    message: MessageDescriptor;
    options?: any;
}

export interface IEntityNameValidation {
    isValid: boolean;
    errorMessages?: IIntlMessageDescriptor[];
}

/**
 * tests whether a display name conforms to our standards
 *  - contain at most 63 characters
 */
export function isEntityDisplayNameValid(name: string): IEntityNameValidation {
    const isValid = name.length <= ENTITY_NAME_MAX_CHARACTERS;
    const errorMessages = isValid
        ? null
        : [
              {
                  message: {
                      id: EntityKeys.NameLengthErrorMessage,
                      defaultMessage: 'The maximum length is 63 characters',
                  },
              },
          ];

    return {
        isValid,
        errorMessages,
    };
}

/**
 * tests whether a name conforms to the RFC 1035 standard:
 *  - contain at most 63 characters
 *  - contain only lowercase alphanumeric characters or '-'
 *  - start with an alphabetic character
 *  - end with an alphanumeric character
 */
export function isEntityNameValid(
    name: string,
    protectedNames?: string[],
    parentEntity?: string
): IEntityNameValidation {
    if (name === '')
        return {
            isValid: true,
        };

    const errorMessages: IIntlMessageDescriptor[] = [];

    if (!_isNil(protectedNames) && protectedNames.includes(name)) {
        const errorMessage = !_isNil(parentEntity)
            ? {
                  message: {
                      id: EntityKeys.NameUniquenessErrorMessageWithParent,
                      defaultMessage: `The id is already in use within this ${parentEntity}`,
                  },
                  options: {
                      parentEntity: parentEntity,
                  },
              }
            : {
                  message: {
                      id: EntityKeys.NameUniquenessErrorMessage,
                      defaultMessage: 'The id is already in use',
                  },
              };

        errorMessages.push(errorMessage);
    }

    if (name.length > ENTITY_NAME_MAX_CHARACTERS) {
        errorMessages.push({
            message: {
                id: EntityKeys.NameLengthErrorMessage,
                defaultMessage: 'The maximum length is 63 characters',
            },
        });
    }

    if (/[^a-z0-9-]/g.test(name)) {
        errorMessages.push({
            message: {
                id: EntityKeys.NameIllegalCharactersErrorMessage,
                defaultMessage:
                    'Only lowercase letters, numbers, and dashes are allowed',
            },
        });
    }

    if (/[^a-z]/g.test(name.substring(0, 1))) {
        errorMessages.push({
            message: {
                id: EntityKeys.NameIllegalFirstCharacterErrorMessage,
                defaultMessage:
                    'The first character must be a lowercase letter',
            },
        });
    }

    if (/[^a-z0-9]/g.test(name.substring(name.length - 1, name.length))) {
        errorMessages.push({
            message: {
                id: EntityKeys.NameIllegalLastCharacterErrorMessage,
                defaultMessage:
                    'The last character must be a lowercase letter or number',
            },
        });
    }

    return errorMessages.length === 0
        ? { isValid: true }
        : {
              isValid: false,
              errorMessages: errorMessages,
          };
}

/**
 * formats a string to be RFC 1035 compliant by:
 *  - replacing spaces with dashes
 *  - stripping characters that are not lowercase alphanumeric or '-'
 *  - transforming upper case to lower case
 *  - forcing the length to be equal to or less than 63 characters
 *  - stripping leading numbers and dashes
 */
export function transformDisplayNameToName(displayName: string): string {
    let transformedName = displayName.toLowerCase();
    transformedName = transformedName.replace(/[\s]/g, '-');
    transformedName = transformedName.replace(/[^a-z0-9-]/g, '');
    if (transformedName.length > ENTITY_NAME_MAX_CHARACTERS) {
        transformedName = transformedName.slice(0, ENTITY_NAME_MAX_CHARACTERS);
    }

    while (/[0-9-]/.test(transformedName[0])) {
        transformedName = transformedName.substring(1);
    }

    while (/-/.test(transformedName[transformedName.length - 1])) {
        transformedName = transformedName.substring(
            0,
            transformedName.length - 1
        );
    }

    return transformedName;
}
