import { Skeleton } from '@mui/material';
import cx from 'classnames';
import React from 'react';
import Styles from './Workspaces.scss';

export interface IWorkspaceProjectButtonSkeletonProps {
    className?: string;
}

export const WorkspaceProjectButtonSkeleton = (
    props: IWorkspaceProjectButtonSkeletonProps
) => {
    const width = Math.floor(Math.random() * (230 - 50) + 50);

    return (
        <div className={cx(Styles.wpbSkeletonContainer, props.className)}>
            <Skeleton
                animation="wave"
                variant="text"
                height={19}
                width={width}
            />
        </div>
    );
};
