import { Skeleton } from '@mui/material';
import React from 'react';
import Styles from './Table.scss';
import { TableTdButton } from './TableTdButton';

interface ITableSkeletonProps {
    columns: Array<any> | number;
}

const EVEN_ROW_WIDTH = 55;
const ODD_ROW_WIDTH = 75;

export const TableSkeleton = (props: ITableSkeletonProps) => {
    const getCellSkeletons = (isHeader = false) => {
        const colAmount = Array.isArray(props.columns)
            ? props.columns.length
            : props.columns;

        const output = [];
        const getWidth = (i) => (i % 2 === 0 ? EVEN_ROW_WIDTH : ODD_ROW_WIDTH);

        const getSkeleton = (index: number) => (
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={isHeader ? 18 : 15}
                width={`${isHeader ? getWidth(index) / 2 : getWidth(index)}%`}
            />
        );

        for (let i = 0; i < colAmount; i += 1) {
            output.push(
                isHeader ? (
                    <th key={(Math.random() * i).toString(16)}>
                        {getSkeleton(i)}
                    </th>
                ) : (
                    <td key={(Math.random() * i).toString(16)}>
                        <TableTdButton rowData={null}>
                            {getSkeleton(i)}
                        </TableTdButton>
                    </td>
                )
            );
        }

        return output;
    };

    return (
        <table className={Styles.table}>
            <thead>
                <tr>{getCellSkeletons(true)}</tr>
            </thead>
            <tbody>
                <tr>{getCellSkeletons()}</tr>
                <tr>{getCellSkeletons()}</tr>
                <tr>{getCellSkeletons()}</tr>
                <tr>{getCellSkeletons()}</tr>
            </tbody>
        </table>
    );
};
