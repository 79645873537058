import { Skeleton } from '@mui/material';
import React from 'react';

const TitleSkeleton = () => {
    return (
        <Skeleton
            animation="wave"
            variant="text"
            sx={{
                height: '35px',
                width: '400px',
            }}
        />
    );
};

export default TitleSkeleton;
