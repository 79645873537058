import { ToastType } from '@Components/Toasts';

export interface ICreatePersistentNotification {
    toastId: string;
    state: PersistentNotificationState;
}

export interface IPersistentNotification extends ICreatePersistentNotification {
    type: ToastType;
    message: string;
    updated: number;
}

export interface IPersistentNotificationData {
    state: PersistentNotificationState;
}

export enum PersistentNotificationState {
    PENDING,
    COMPLETED,
}
