import logo from '@Images/logo.svg';
import React from 'react';
import Styles from './LoadingLogo.scss';

/**
 * Intended use is when a User has authenticated and has redirected, but we haven't received their auth token yet.
 * See src/services/authentication/RequireAuth
 */
export const LoadingLogo = () => {
    return (
        <div className={Styles.loadingLogoContainer}>
            <img src={logo} className={Styles.loadingLogo} />
        </div>
    );
};
