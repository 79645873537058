import { ReactComponent as DescendingIcon } from '@Icons/sm/arrow-down.svg';
import { ReactComponent as AscendingIcon } from '@Icons/sm/arrow-up.svg';
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { ITableProps } from './Interfaces';
import Styles from './Table.scss';
import { TableTdButton } from './TableTdButton';

export const Table: React.FC<ITableProps> = (props) => {
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <table className={Styles.table} data-cy={props.id}>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                onClick={header.column.getToggleSortingHandler()}
                            >
                                <div>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                          )}
                                    {{
                                        asc: (
                                            <AscendingIcon
                                                className={Styles.ascDescIcon}
                                            />
                                        ),
                                        desc: (
                                            <DescendingIcon
                                                className={Styles.ascDescIcon}
                                            />
                                        ),
                                    }[header.column.getIsSorted() as string] ??
                                        null}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        // onClick={() => {
                        //     if (!_isNil(props.onRowClick)) {
                        //         props.onRowClick(row.original);
                        //     }
                        // }}
                        className={
                            _isNil(props.onRowClick) && _isNil(props.getRowHref)
                                ? ''
                                : Styles.trSelectable
                        }
                        // {...getTableRowRoles(row.original)}
                    >
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                                {/* <button {...getTableRowRoles(row)} className={Styles}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </button> */}
                                <TableTdButton
                                    rowData={row.original}
                                    onRowClick={props.onRowClick}
                                    getRowHref={props.getRowHref}
                                    rowHrefRelativity={props.rowHrefRelativity}
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </TableTdButton>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            {/* <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.footer,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </tfoot> */}
        </table>
    );
};
