import GridStyles from '@Styles/grid.scss';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import { FormKeys, SettingsKeys } from '@Services/i18n/keys';
import { ISettingsUser, getFullName } from './Interfaces';

interface IRemoveUserFromEntityDialogProps {
    user: ISettingsUser;
    entityName: string;
    onRemove: (user: ISettingsUser) => void;
    onClose: () => void;
    isOpen: boolean;
}

export const RemoveUserFromEntityDialog = (
    props: IRemoveUserFromEntityDialogProps
) => {
    const { isOpen, onClose, user, entityName, onRemove } = props;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const intl = useIntl();

    const title = intl.formatMessage({
        id: SettingsKeys.RemoveUser,
        defaultMessage: 'Remove User',
    });
    const userName = !_isNil(user) ? getFullName(user) : '';

    const handleRemove = async () => {
        setIsSubmitting(true);
        await onRemove(user);
        setIsSubmitting(false);
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogHeader title={title} onClose={onClose} />
            <DialogBody>
                <div className={GridStyles.fullWidthGridChild}>
                    <span>
                        {intl.formatMessage(
                            {
                                id: SettingsKeys.RemoveUserFromEntity,
                                defaultMessage: `Are you sure you want to remove ${userName} from ${entityName}?`,
                            },
                            { userName: userName, entityName: entityName }
                        )}
                    </span>
                </div>
            </DialogBody>
            <DialogFooter
                onCancel={onClose}
                onSubmit={handleRemove}
                submitText={title}
                cancelText={intl.formatMessage({
                    id: FormKeys.Cancel,
                    defaultMessage: 'Cancel',
                })}
                isSubmitting={isSubmitting}
                submittingText={intl.formatMessage({
                    id: SettingsKeys.RemovingUser,
                    defaultMessage: 'Removing User',
                })}
            />
        </Dialog>
    );
};
