const Views = {
    Workspaces: 'views.workspaces',
    Marketplace: 'views.marketplace',
    Devkit: 'views.devkit',
    DataLibrary: 'views.dataLibrary',
    ModelsAlgorithms: 'views.modelsAlgorithms',
    ProjectTemplates: 'views.projectTemplates',
    PluginsApis: 'views.pluginsApis',
    VisualizationApps: 'views.visualizationApps',
    Projects: 'views.projects',
    ProjectOverview: 'views.projectOverview',
    ProjectData: 'views.projectData',
    ProjectAnalysis: 'views.projectAnalysis',
    ProjectWorkflow: 'views.projectWorkflow',
    ProjectDesign: 'views.projectDesign',
};

export default Views;
