import { IProject } from '@Data/projects';
import { IWorkspace } from '@Data/workspaces';
import { View } from '@Services/navigation';

export interface IHeaderProps {
    /**
     * The group of views that will use this header
     */
    headerType: HeaderType;
    selectedOrganizationId?: string;
    selectedProject?: IProject;
    selectedWorkspace?: IWorkspace;
    isLoading?: boolean;
}

export enum HeaderType {
    /**
     * Workspaces, Marketplace, and DevKit views
     */
    DEFAULT,
    PROJECT,
    SETTINGS,
}

export interface IHeaderTabsProps {
    currentView: View;
}
