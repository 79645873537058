import LayoutStyles from '@Styles/layout.scss';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
    StaticDrawer,
    WorkspaceProjectsDrawerContent,
} from '@Components/Drawers';
import { Header, HeaderType } from '@Components/Header';
import { MainContainer, Page, PageContent } from '@Components/Page';
import {
    OrganizationsApi,
    organizationsState,
    selectedOrganizationState,
} from '@Data/organizations';
import { ProjectsApi, selectedProjectState } from '@Data/projects';
import { WorkspacesApi, selectedWorkspaceState } from '@Data/workspaces';
import { Routes } from '@Services/navigation';
import OrganizationSelector from '@Views/WorkspacesView/components/WorkspacesDisplay/OrganizationSelector/OrganizationSelector';

// used to override the intial waterfall load when using the org selector
let ensureLoad = true;

const ProjectView = () => {
    const params = useParams();
    const navigate = useNavigate();

    const organizationId = params.organizationId;
    const workspaceId = params.workspaceId;
    const projectId = params.projectId;

    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);
    const selectedProject = useSelector(selectedProjectState);
    const organizations = useSelector(organizationsState);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        return () => {
            ensureLoad = true;
        };
    }, []);

    useEffect(() => {
        if (_isNil(workspaceId) || _isNil(projectId)) {
            navigate('/');
        }
    }, [workspaceId, projectId]);

    useEffect(() => {
        if (organizations.length === 0) {
            OrganizationsApi.loadOrganizations();
        }
    }, [organizations, ensureLoad]);

    useEffect(() => {
        const getSelectedOrganization = async () => {
            await OrganizationsApi.loadOrganizationById(organizationId);
        };

        if (
            ensureLoad &&
            !_isNil(organizationId) &&
            (_isNil(selectedOrganization) ||
                selectedOrganization.id !== organizationId)
        ) {
            getSelectedOrganization();
        }
    }, [selectedOrganization, organizationId, ensureLoad]);

    useEffect(() => {
        const getSelectedWorkspace = async () => {
            await WorkspacesApi.loadWorkspaceById(workspaceId);
        };

        if (
            ensureLoad &&
            !_isNil(selectedOrganization) &&
            !_isNil(workspaceId) &&
            (_isNil(selectedWorkspace) || selectedWorkspace.id !== workspaceId)
        ) {
            getSelectedWorkspace();
        }
    }, [selectedOrganization, selectedWorkspace, workspaceId, ensureLoad]);

    useEffect(() => {
        const getSelectedProject = async () => {
            await ProjectsApi.loadProjectById(projectId);
            setIsLoading(false);
            ensureLoad = true;
        };

        if (
            ensureLoad &&
            !_isNil(selectedOrganization) &&
            !_isNil(selectedWorkspace) &&
            !_isNil(projectId) &&
            (_isNil(selectedProject) || selectedProject.id !== projectId)
        ) {
            getSelectedProject();
        }
    }, [
        selectedOrganization,
        selectedWorkspace,
        selectedProject,
        projectId,
        ensureLoad,
    ]);

    const handleOrganizationChange = async (organizationId: string) => {
        ensureLoad = false;
        setIsLoading(true);
        const org = organizations.find((o) => o.id === organizationId);
        OrganizationsApi.setSelectedOrganization(org);
        const workspaces = await WorkspacesApi.loadWorkspaces(organizationId);
        if (workspaces.length === 0) {
            navigate(Routes.workspaces.getUrl(organizationId));
            // toast
            return;
        }
        const defaultWorkspace = workspaces[0];
        WorkspacesApi.setSelectedWorkspace(defaultWorkspace);

        const projects = await ProjectsApi.loadProjects(defaultWorkspace.id);
        if (projects.length === 0) {
            navigate(
                Routes.workspaces.getUrl(organizationId, defaultWorkspace.id)
            );
            // toast
            return;
        }
        const defaultProject = projects[0];
        ProjectsApi.setSelectedProject(defaultProject);

        navigate(
            Routes.project.overview.getUrl(
                organizationId,
                defaultWorkspace.id,
                defaultProject.id
            )
        );

        setIsLoading(false);
        ensureLoad = true;
    };

    return (
        <Page>
            {/* todo: pass in width */}
            <Header
                headerType={HeaderType.PROJECT}
                selectedOrganizationId={selectedOrganization?.id}
                selectedWorkspace={selectedWorkspace}
                selectedProject={selectedProject}
                isLoading={isLoading || _isNil(selectedProject)}
            />
            <PageContent>
                <StaticDrawer fullWidth>
                    <OrganizationSelector
                        selectedOrganizationId={selectedOrganization?.id}
                        organizations={organizations}
                        isLoading={organizations.length === 0}
                        selectClassname={
                            LayoutStyles.sidebarOrganizationSelectorContainer
                        }
                        onChange={handleOrganizationChange}
                    />

                    <div className={LayoutStyles.sidebarDividerVertical} />

                    {/* display projects list if desktop */}
                    <WorkspaceProjectsDrawerContent
                        isLoading={isLoading}
                        selectedOrganizationId={organizationId}
                        selectedProject={selectedProject}
                        selectedWorkspace={selectedWorkspace}
                    />
                </StaticDrawer>
                <MainContainer alignStart hasStaticDrawer fullWidth>
                    <div className={LayoutStyles.mainContainerContent}>
                        <Outlet />
                    </div>
                </MainContainer>
            </PageContent>
        </Page>
    );
};

export default ProjectView;
