import { OptionUnstyledProps } from '@mui/base';
import { SelectUnstyledProps } from '@mui/base/SelectUnstyled';
import { IBaseInputProps, InputSize } from '@Components/Inputs/Interfaces';

export enum SelectType {
    PRIMARY,
    SECONDARY,
}

/**
 * @noInheritDoc
 */
export interface ISelectProps<TValue>
    extends SelectUnstyledProps<TValue>,
        IBaseInputProps {
    selectType: SelectType;
    size: InputSize;
    id?: string;
    minWidth?: string;
    required?: boolean;
}

export function getSelectSizeString(
    size: InputSize
): 'small' | 'medium' | 'large' {
    switch (size) {
        case InputSize.SMALL:
            return 'small';
        case InputSize.MEDIUM:
            return 'medium';
        case InputSize.LARGE:
            return 'large';
    }
}

export interface ISelectOptionProps<TValue>
    extends OptionUnstyledProps<TValue> {
    children: React.ReactNode;
}
