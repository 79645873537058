import cx from 'classnames';
import React from 'react';
import Styles from './Dialog.scss';
import { IDialogBodyProps } from './Interfaces';

export const DialogBody = (props: IDialogBodyProps) => {
    const {
        containerClassName,
        containerStyles,
        contentClassName,
        contentStyles,
        children,
        ...divProps
    } = props;

    return (
        <div
            className={cx(Styles.dialogBodyContainer, containerClassName)}
            style={containerStyles}
            data-cy="dialog-body"
            {...divProps}
        >
            <div
                className={cx(Styles.dialogBodyContent, contentClassName)}
                style={contentStyles}
            >
                {children}
            </div>
        </div>
    );
};
