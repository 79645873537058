import { ReactComponent as HelpIcon } from '@Icons/xs/help.svg';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { IServiceContent } from '@Data/solutions/Interfaces';
import { SolutionKeys } from '@Services/i18n/keys';
import Styles from './ServiceInformation.scss';

interface IServiceInformationContentProps {
    content: IServiceContent;
}

export const ServiceInformationContent = (
    props: IServiceInformationContentProps
) => {
    const intl = useIntl();

    const displayDocumentation = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(props.content.documentationUrl);
    };

    return (
        <div>
            <li>
                &#8226; &ensp;
                {props.content.name}{' '}
                <Tooltip
                    title={intl.formatMessage({
                        id: SolutionKeys.ViewDocumentation,
                        defaultMessage: 'View documentation',
                    })}
                    placement="right"
                >
                    <i>
                        <HelpIcon onClick={displayDocumentation} />
                    </i>
                </Tooltip>
            </li>
            <div className={Styles.serviceContentDescription}>
                <span>{props.content.description}</span>
            </div>
        </div>
    );
};
