import { api } from '@orbica/platform-sdk-dev';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { ISettingsUser } from '@Components/Settings';
import { SortOrder } from '@Services/helpers/Sorting';
import { RootState } from '@Services/redux/reduxStore';
import { getSortedProjects } from './Helpers';
import { IProject, ProjectDisplayType, ProjectSortType } from './Interfaces';
import { IProjectsState, initialProjectsState } from './State';

const ProjectsSlice = createSlice({
    name: 'Projects',
    initialState: initialProjectsState,
    reducers: {
        reset() {
            return initialProjectsState;
        },
        updateProjects(
            state: IProjectsState,
            action: PayloadAction<Array<IProject>>
        ) {
            state.projects = action.payload;
        },
        setSelectedProject(
            state: IProjectsState,
            action: PayloadAction<IProject>
        ) {
            state.selectedProject = action.payload;
        },
        updateDisplayType(
            state: IProjectsState,
            action: PayloadAction<ProjectDisplayType>
        ) {
            state.projectDisplayType = action.payload;
        },
        updateSortType(
            state: IProjectsState,
            action: PayloadAction<ProjectSortType>
        ) {
            state.sortType = action.payload;
        },
        updateSortOrder(
            state: IProjectsState,
            action: PayloadAction<SortOrder>
        ) {
            state.sortOrder = action.payload;
        },
        toggleSortType(state: IProjectsState) {
            state.sortOrder =
                state.sortOrder === SortOrder.ASCENDING
                    ? SortOrder.DESCENDING
                    : SortOrder.ASCENDING;
        },
        updateProjectUsers(
            state: IProjectsState,
            action: PayloadAction<ISettingsUser[]>
        ) {
            state.selectedProjectUsers = action.payload;
        },
        setRoles(state: IProjectsState, action: PayloadAction<api.IRole[]>) {
            state.roles = action.payload;
        },
    },
});

export const ProjectsActions = ProjectsSlice.actions;

export const selectedProjectState = (state: RootState) =>
    state.projects.selectedProject;
export const projectDisplayTypeState = (state: RootState) =>
    state.projects.projectDisplayType;
export const projectSortOrderState = (state: RootState) =>
    state.projects.sortOrder;
export const projectSortTypeState = (state: RootState) =>
    state.projects.sortType;

const projects = (state: RootState) => state.projects.projects;
export const projectsState = createSelector(
    projectSortOrderState,
    projectSortTypeState,
    projects,
    (sortOrder, sortType, projects) =>
        getSortedProjects(sortOrder, sortType, projects)
);
export const selectedProjectUsersState = (state: RootState) =>
    state.projects.selectedProjectUsers;
export const projectsRolesState = (state: RootState) => state.projects.roles;

export const unusableProjectNamesState = createSelector(
    projects,
    (existingProjects) => {
        return Array.isArray(existingProjects)
            ? existingProjects.map((s) => s.name.toLowerCase().trim())
            : [];
    }
);

export default ProjectsSlice.reducer;
