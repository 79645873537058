import React from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadingLogo } from '@Components/LoadingIndicators';
import { hasUserState } from '../../data/auth/Reducer';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const auth = useAuth();
    const hasUserData = useSelector(hasUserState);

    if (
        auth.isLoading ||
        (auth.activeNavigator && auth.activeNavigator === 'signinSilent')
    )
        return <LoadingLogo />;

    if (!hasAuthParams() && !auth.isAuthenticated)
        return <Navigate to="/login" state={{ from: location }} replace />;

    if (!hasUserData) return <LoadingLogo />;

    return children;
};
export default RequireAuth;
