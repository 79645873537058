import GridStyles from '@Styles/grid.scss';
import { Skeleton } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    WorkspaceAccordion,
    WorkspaceProjectButtonSkeleton,
} from '@Components/Workspaces';
import { IProject } from '@Data/projects';
import { IWorkspace, WorkspacesApi, workspacesState } from '@Data/workspaces';
import { ProjectKeys } from '@Services/i18n/keys';
import Styles from './WorkspaceProjectsDrawerContent.scss';

export interface IWorkspaceProjectsDrawerContentProps {
    selectedOrganizationId: string;
    selectedProject: IProject;
    selectedWorkspace: IWorkspace;
    isLoading?: boolean;
}

export const WorkspaceProjectsDrawerContent = (
    props: IWorkspaceProjectsDrawerContentProps
) => {
    const intl = useIntl();
    const workspaces = useSelector(workspacesState);
    const [expandedAccordionIndex, setExpandedAccordionIndex] =
        useState<number>(-1);

    useEffect(() => {
        const loadWorkspaces = async () => {
            await WorkspacesApi.loadWorkspaces(props.selectedOrganizationId);
        };

        if (!props.isLoading && props.selectedOrganizationId) {
            if (_isNil(workspaces) || workspaces.length === 0) {
                loadWorkspaces();
            } else {
                setExpandedAccordionIndex(
                    workspaces.findIndex(
                        (w) => w.id === props.selectedWorkspace.id
                    )
                );
            }
        }
    }, [props.isLoading, props.selectedOrganizationId, workspaces]);

    const title = props.isLoading ? (
        <Skeleton
            variant="text"
            animation="wave"
            height={28}
            width={135}
            className={Styles.projectsTitle}
        />
    ) : (
        <span className={Styles.projectsTitle}>
            {intl.formatMessage({
                id: ProjectKeys.ProjectsList,
                defaultMessage: 'Projects list',
            })}
        </span>
    );

    const workspacesHtml = props.isLoading ? (
        <>
            <WorkspaceProjectButtonSkeleton
                className={GridStyles.fullWidthGridChild}
            />
            <WorkspaceProjectButtonSkeleton
                className={GridStyles.fullWidthGridChild}
            />
            <WorkspaceProjectButtonSkeleton
                className={GridStyles.fullWidthGridChild}
            />
        </>
    ) : (
        workspaces.map((w, i) => (
            <WorkspaceAccordion
                key={`workspaceAccordion${w.id}`}
                workspace={w}
                selectedOrganizationId={props.selectedOrganizationId}
                selectedProjectId={props.selectedProject.id}
                isSelected={props.selectedWorkspace.id === w.id}
                expanded={i === expandedAccordionIndex}
                onClick={() => setExpandedAccordionIndex(i)}
                className={GridStyles.fullWidthGridChild}
            />
        ))
    );

    return (
        <div className={Styles.drawerContainer}>
            <div className={Styles.workspacesTitleContainer}>{title}</div>
            <div className={Styles.workspacesContainer}>
                <div
                    className={Styles.workspacesScrollContainer}
                    data-cy="drawers-menu-project"
                >
                    {workspacesHtml}
                </div>
            </div>
        </div>
    );
};
