import React from 'react';
import { useIntl } from 'react-intl';
import { ENTITY_NAME_MAX_CHARACTERS } from '@Services/helpers/Entities/EntityHelpers';
import { FormKeys } from '@Services/i18n/keys';
import Styles from './RemainingCharactersAdornment.scss';

export interface IRemainingCharactersAdornmentProps {
    value: string;
    maxCharacters?: number;
}

function getRemainingCharacters(value: string, maxCharacters: number): number {
    const actualCharsRemaining = maxCharacters - value.length;
    return actualCharsRemaining > 0 ? actualCharsRemaining : 0;
}

export const RemainingCharactersAdornment = (
    props: IRemainingCharactersAdornmentProps
) => {
    const intl = useIntl();
    const maxCharacters = props.maxCharacters ?? ENTITY_NAME_MAX_CHARACTERS;

    const remainingCharacters = getRemainingCharacters(
        props.value,
        maxCharacters
    );

    return (
        <span className={Styles.charactersRemainingAdornment}>
            {intl.formatMessage(
                {
                    id: FormKeys.CharactersRemaining,
                    defaultMessage: `${remainingCharacters} characters remaining`,
                },
                {
                    remaining: remainingCharacters,
                }
            )}
        </span>
    );
};
