import { api } from '@orbica/platform-sdk-dev';
import { ISettingsUser } from '@Components/Settings';
import { IWorkspace } from './Interfaces';

export interface IWorkspacesState {
    selectedWorkspace: IWorkspace | null;
    workspaces: Array<IWorkspace>;
    selectedWorkspaceUsers: Array<ISettingsUser>;
    roles: api.IRole[];
}

export const initialWorkspacesState: IWorkspacesState = {
    selectedWorkspace: null,
    workspaces: [],
    selectedWorkspaceUsers: [],
    roles: [],
};
