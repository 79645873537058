import { ReactComponent as CloseIcon } from '@Icons/lg/x.svg';
import cx from 'classnames';
import React from 'react';
import { toast } from 'react-toastify';
import { ToastType } from '@Components/Toasts';
import { IToastProps } from './Interfaces';
import Styles from './Toasts.scss';

export const Toast: React.FC<IToastProps> = (props) => {
    const dismiss = () => {
        toast.dismiss(props.id);
    };

    const getClassName = (type: ToastType): string => {
        switch (type) {
            case ToastType.SUCCESS:
                return Styles.success;
            case ToastType.WARNING:
                return Styles.warning;
            case ToastType.ERROR:
                return Styles.error;
            case ToastType.INFO:
                return Styles.info;
        }
    };

    return (
        <div
            className={cx(Styles.toastContainer, getClassName(props.type))}
            data-cy="Toastify"
        >
            <span>{props.message}</span>
            <div className={Styles.closeContainer} onClick={dismiss}>
                <CloseIcon />
            </div>
        </div>
    );
};
