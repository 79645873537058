import React from 'react';
import Styles from './ProjectDataDisplay.scss';
import ProjectDataDisplayTitleSkeleton from './ProjectDataDisplayTitleSkeleton';

interface IProjectDataDisplayProps {
    title: string;
    children: React.ReactNode;
    isLoading?: boolean;
}

const ProjectDataDisplay = (props: IProjectDataDisplayProps) => {
    const title = props.isLoading ? (
        <ProjectDataDisplayTitleSkeleton />
    ) : (
        <span className={Styles.dataContainerTitle}>{props.title}</span>
    );

    return (
        <div className={Styles.dataContainer}>
            <div className={Styles.dataItemsContainer}>
                <div className={Styles.dataItemsTitleContainer}>{title}</div>

                <div
                    className={Styles.dataItemsScrollContainer}
                    data-cy="card-container-service"
                >
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default ProjectDataDisplay;
