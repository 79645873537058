import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import AuthReducer from '@Data/auth/Reducer';
import ClustersReducer from '@Data/clusters/Reducer';
import NotificationReducer from '@Data/notifications/Reducer';
import OrganizationsReducer from '@Data/organizations/Reducer';
import ProjectsReducer from '@Data/projects/Reducer';
import ServicesReducer from '@Data/solutions/Reducer';
import WorkspacesReducer from '@Data/workspaces/Reducer';

export const store = configureStore({
    reducer: {
        workspaces: WorkspacesReducer,
        projects: ProjectsReducer,
        organizations: OrganizationsReducer,
        services: ServicesReducer,
        auth: AuthReducer,
        notifications: NotificationReducer,
        clusters: ClustersReducer,
    },
    // middleware: (getDefaultMiddleware) => {
    //     return getDefaultMiddleware({
    //         serializableCheck: false
    //     })
    // }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;
