import Flex from '@Styles/flex.scss';
import GridStyles from '@Styles/grid.scss';
import { api } from '@orbica/platform-sdk-dev';
import { createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import { Checkbox } from '@Components/FormControls';
import { RadioButton } from '@Components/FormControls/RadioButton';
import { Table, TableSkeleton } from '@Components/Table';
import { FormKeys, SettingsKeys } from '@Services/i18n/keys';
import { ISettingsUser, getFullName } from './Interfaces';
import Styles from './Settings.scss';

interface IAddUsersToEntityDialogProps {
    availableUsers: ISettingsUser[];
    roles: api.IRole[];
    entityName: string;
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    onAddUsers: (users: ISettingsUser[]) => void;
    defaultRole?: api.IRole;
    helperText: string;
}

export const AddUsersToEntityDialog = (props: IAddUsersToEntityDialogProps) => {
    const intl = useIntl();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [usersToAdd, setUsersToAdd] = useState<ISettingsUser[]>([]);

    const title = intl.formatMessage(
        {
            id: SettingsKeys.AddUsersToEntity,
            defaultMessage: `Add Users to ${props.entityName}`,
        },
        { entityName: props.entityName }
    );

    const handleAddUsers = async () => {
        setIsSubmitting(true);
        await props.onAddUsers(usersToAdd);
        setIsSubmitting(false);
        props.onClose();
        setUsersToAdd([]);
    };

    const handleCancel = () => {
        props.onClose();
        setUsersToAdd([]);
    };

    const isUserSelected = (user: ISettingsUser): boolean => {
        return usersToAdd.findIndex((u) => u.id === user.id) > -1;
    };

    const toggleUserSelected = (
        event: React.ChangeEvent<HTMLInputElement>,
        user: ISettingsUser
    ) => {
        const usersArr = [...usersToAdd];
        if (event.target.checked) {
            // set the default role
            const role = _isNil(props.defaultRole)
                ? props.roles[0]
                : props.defaultRole;
            user.role = role;
            usersArr.push(user);
            setUsersToAdd(usersArr);
        } else {
            const userIndex = usersArr.findIndex((u) => u.id === user.id);
            usersArr[userIndex].role = undefined;
            usersArr.splice(userIndex, 1);
            setUsersToAdd(usersArr);
        }
    };

    const toggleAllUsersSelected = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            // select all users who aren't selected
            const unselectedUsers = props.availableUsers.filter(
                (availableUser) => {
                    return (
                        usersToAdd.findIndex(
                            (addedUser) => addedUser.id === availableUser.id
                        ) === -1
                    );
                }
            );

            const usersArray = [...usersToAdd];

            unselectedUsers.forEach((u) => usersArray.push(u));
            setUsersToAdd(usersArray);
        } else {
            setUsersToAdd([]);
        }
    };

    const handleSetUserRole = (user: ISettingsUser, role: api.IRole) => {
        const usersArr = [...usersToAdd];

        const updatedUser = usersArr.find((u) => u.id === user.id);

        if (updatedUser) {
            updatedUser.role = role;
        }
        setUsersToAdd(usersArr);
    };

    const submitText =
        usersToAdd.length > 1
            ? intl.formatMessage({
                  id: SettingsKeys.AddUsers,
                  defaultMessage: 'Add Users',
              })
            : intl.formatMessage({
                  id: SettingsKeys.AddUser,
                  defaultMessage: 'Add User',
              });

    const submittingText =
        usersToAdd.length > 1
            ? intl.formatMessage({
                  id: SettingsKeys.AddingUsers,
                  defaultMessage: 'Adding Users',
              })
            : intl.formatMessage({
                  id: SettingsKeys.AddingUser,
                  defaultMessage: 'Adding User',
              });

    const columnHelper = createColumnHelper<ISettingsUser>();
    const columns = [
        columnHelper.accessor('id', {
            cell: (info) => {
                const user = info.row.original;
                const isSelected = isUserSelected(user);
                return (
                    <Checkbox
                        checked={isSelected}
                        onChange={(e) => toggleUserSelected(e, user)}
                    />
                );
            },
            header: () => (
                <Checkbox
                    checked={usersToAdd.length === props.availableUsers.length}
                    onChange={toggleAllUsersSelected}
                />
            ),
            id: 'checkbox',
            enableSorting: false,
            size: 25,
        }),
        columnHelper.accessor('id', {
            header: intl.formatMessage({
                id: FormKeys.Name,
                defaultMessage: 'Name',
            }),
            id: 'name',
            cell: (info) => getFullName(info.row.original),
        }),
        columnHelper.accessor('email', {
            header: intl.formatMessage({
                id: FormKeys.Email,
                defaultMessage: 'Email',
            }),
        }),
    ];

    props.roles.forEach((role) => {
        columns.push(
            columnHelper.accessor('id', {
                header: role.name,
                cell: (info) => {
                    const user = info.row.original;
                    const isSelected =
                        isUserSelected(user) &&
                        user.role &&
                        user.role.id === role.id;

                    return (
                        <RadioButton
                            checked={isSelected}
                            onClick={() => handleSetUserRole(user, role)}
                        />
                    );
                },
                id: role.id,
            })
        );
    });

    let innerHtml;
    if (props.isLoading) {
        innerHtml = <TableSkeleton columns={3} />;
    } else {
        innerHtml =
            props.availableUsers.length > 0 ? (
                <Table
                    data={props.availableUsers}
                    columns={columns}
                    id="add-user"
                />
            ) : (
                <span>There are no available users to add</span>
            );
    }

    const isSubmitDisabled =
        usersToAdd.length === 0 ||
        (usersToAdd.length > 0 &&
            usersToAdd.filter((u) => u.role).length !== usersToAdd.length);

    return (
        <Dialog open={props.isOpen}>
            <DialogHeader title={title} onClose={handleCancel} />
            <DialogBody>
                <div
                    className={cx(
                        GridStyles.fullWidthGridChild,
                        Flex.flex,
                        Flex.column
                    )}
                >
                    <span className={Styles.helperText}>
                        {props.helperText}
                    </span>
                    {innerHtml}
                </div>
            </DialogBody>
            <DialogFooter
                onCancel={handleCancel}
                onSubmit={handleAddUsers}
                isSubmitDisabled={isSubmitDisabled}
                submitText={submitText}
                isSubmitting={isSubmitting}
                submittingText={submittingText}
            />
        </Dialog>
    );
};
