import { api } from '@orbica/platform-sdk-dev';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@Services/redux/reduxStore';
import { IClustersState, initialClustersState } from './State';

const ClustersSlice = createSlice({
    name: 'Clusters',
    initialState: initialClustersState,
    reducers: {
        updateClusters(
            store: IClustersState,
            action: PayloadAction<api.ICluster[]>
        ) {
            store.clusters = action.payload;
        },
    },
});

export const ClustersActions = ClustersSlice.actions;

export const clustersState = (state: RootState) => state.clusters.clusters;

export default ClustersSlice.reducer;
