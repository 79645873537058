import { ReactComponent as UserPlusIcon } from '@Icons/lg/user-plus.svg';
import GridStyles from '@Styles/grid.scss';
import { api } from '@orbica/platform-sdk-dev';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@Components/Buttons';
import { Header, HeaderType } from '@Components/Header';
import { LabelWithIconButton } from '@Components/Label/LabelWithIconButton';
import { MainContainer, Page, PageContent } from '@Components/Page';
import {
    ISettingsUser,
    ManageUserRoleDialog,
    RemoveUserFromEntityDialog,
    UserRoleSettingsTable,
    getFullName,
} from '@Components/Settings';
import { AddUsersToEntityDialog } from '@Components/Settings/AddUsersToEntityDialog';
import { EntityNameDisplayName } from '@Components/Settings/EntityNameDisplayName';
import { ToastType } from '@Components/Toasts';
import { canCurrentUserEditWorkspaceState } from '@Data/auth';
import {
    OrganizationsApi,
    selectedOrganizationState,
    selectedOrganizationUsersState,
} from '@Data/organizations';
import { WorkspacesApi, selectedWorkspaceState } from '@Data/workspaces';
import {
    selectedWorkspaceUsersState,
    workspacesRolesState,
} from '@Data/workspaces/Reducer';
import {
    EntityKeys,
    FormKeys,
    SettingsKeys,
    WorkspaceKeys,
} from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';
import SettingsStyles from '../Settings.scss';

export const WorkspaceSettingsView = () => {
    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();

    const organizationId = params.organizationId;
    const workspaceId = params.workspaceId;

    const canCurrentUserEditWorkspace = useSelector(
        canCurrentUserEditWorkspaceState
    );
    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);
    const organizationUsers = useSelector(selectedOrganizationUsersState);
    const workspaceUsers = useSelector(selectedWorkspaceUsersState);
    const workspaceRoles = useSelector(workspacesRolesState);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userToEdit, setUserToEdit] = useState<ISettingsUser>(null);
    const [isUserRoleDialogOpen, setIsUserRoleDialogOpen] =
        useState<boolean>(false);
    const [isAddUsersDialogOpen, setIsAddUsersDialogOpen] =
        useState<boolean>(false);
    const [availableUsers, setAvailableUsers] = useState<ISettingsUser[]>([]);
    const [areAvailableUsersLoading, setAreAvailableUsersLoading] =
        useState<boolean>(false);
    const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] =
        useState<boolean>(false);

    useEffect(() => {
        const getSelectedOrganization = async () => {
            await OrganizationsApi.loadOrganizationById(organizationId);
        };

        if (
            !_isNil(organizationId) &&
            (_isNil(selectedOrganization) ||
                selectedOrganization.id !== organizationId)
        ) {
            getSelectedOrganization();
        }
    }, [selectedOrganization, organizationId]);

    const getSelectedWorkspace = async () => {
        await WorkspacesApi.loadWorkspaceById(workspaceId);
    };

    useEffect(() => {
        if (
            !_isNil(selectedOrganization) &&
            !_isNil(workspaceId) &&
            (_isNil(selectedWorkspace) || selectedWorkspace.id !== workspaceId)
        ) {
            getSelectedWorkspace();
        }
    }, [selectedOrganization, selectedWorkspace, workspaceId]);

    const getWorkspaceUsers = async () => {
        const orgUsers = _isNil(organizationUsers)
            ? await OrganizationsApi.getOrganizationUsers(organizationId)
            : organizationUsers;

        await WorkspacesApi.getWorkspaceUsers(selectedWorkspace.id, orgUsers);
    };

    useEffect(() => {
        const getUsers = async () => {
            await getWorkspaceUsers();
            await WorkspacesApi.getWorkspaceRoles();
            setIsLoading(false);
        };

        if (!_isNil(selectedWorkspace)) {
            getUsers();
        }
    }, [selectedWorkspace]);

    useEffect(() => {
        if (
            !isLoading &&
            !_isNil(selectedOrganization) &&
            selectedOrganization.id === organizationId &&
            !canCurrentUserEditWorkspace
        ) {
            navigate('/');
        }
    }, [
        selectedOrganization,
        organizationId,
        canCurrentUserEditWorkspace,
        isLoading,
    ]);

    const handleOpenAddUsersDialog = async () => {
        setIsAddUsersDialogOpen(true);
        setAreAvailableUsersLoading(true);

        const orgUsers = await OrganizationsApi.getOrganizationUsers(
            selectedOrganization.id
        );

        const unaddedOrgUsers: ISettingsUser[] = orgUsers
            .filter((orgUser) => {
                return (
                    orgUser.role !== 'Admin' &&
                    workspaceUsers.findIndex(
                        (wspUser) => wspUser.id === orgUser.id
                    ) === -1
                );
            })
            .map((user) => {
                return {
                    id: user.id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    email: user.email,
                    role: null,
                };
            });

        setAvailableUsers(unaddedOrgUsers);
        setAreAvailableUsersLoading(false);
    };

    const handleOpenUserRoleDialog = async (user: ISettingsUser) => {
        setUserToEdit(user);
        setIsUserRoleDialogOpen(true);
    };

    const handleOpenRemoveUserDialog = (user: ISettingsUser) => {
        setUserToEdit(user);
        setIsRemoveUserDialogOpen(true);
    };

    const handleUpdateUserRole = async (
        user: ISettingsUser,
        newRole: api.IRole
    ) => {
        const wasSuccessful = await WorkspacesApi.updateWorkspaceUserRole(
            selectedWorkspace,
            user.id,
            newRole
        );

        if (wasSuccessful) {
            const toastMsg = intl.formatMessage(
                {
                    id: FormKeys.ObjectUpdated,
                    defaultMessage: 'Role updated',
                },
                { object: 'Role' }
            );
            createToast(toastMsg, ToastType.SUCCESS);
            await getWorkspaceUsers();
        }
    };

    const handleAddUsersToWorkspace = async (users: ISettingsUser[]) => {
        await WorkspacesApi.addUsersToWorkspace(users, selectedWorkspace.id);
        await getWorkspaceUsers();
    };

    const handleRemoveUserFromWorkspace = async (user: ISettingsUser) => {
        const wasSuccessful = await WorkspacesApi.removeUserFromWorkspace(
            user,
            selectedWorkspace.id
        );
        if (wasSuccessful) {
            createToast(
                intl.formatMessage({
                    id: SettingsKeys.UserRemoved,
                    defaultMessage: 'User Removed',
                }),
                ToastType.SUCCESS
            );
            await getWorkspaceUsers();
        }
    };

    const addUsersHelperText = intl.formatMessage({
        id: WorkspaceKeys.AddMembers,
        defaultMessage: 'Add members of your Organization to your Workspace',
    });

    const handleUpdateDisplayName = async (name: string) => {
        await WorkspacesApi.editWorkspaceDisplayName(selectedWorkspace, name);
        await getSelectedWorkspace();
    };

    const localizedWorkspaceString = intl.formatMessage({
        id: EntityKeys.Workspace,
        defaultMessage: 'Workspace',
    });

    return (
        <React.Fragment>
            <Page>
                <Header
                    headerType={HeaderType.SETTINGS}
                    isLoading={isLoading}
                />
                <PageContent>
                    <MainContainer
                        gridClassName={cx(
                            GridStyles.alignContentStart,
                            SettingsStyles.settingsGrid
                        )}
                    >
                        <div className={SettingsStyles.titleContainer}>
                            <div className={SettingsStyles.title}>
                                {intl.formatMessage({
                                    id: SettingsKeys.WorkspaceSettings,
                                    defaultMessage: 'Workspace Settings',
                                })}
                            </div>
                        </div>
                        <div className={SettingsStyles.divider} />

                        <EntityNameDisplayName
                            entityName={selectedWorkspace?.name}
                            entityDisplayName={selectedWorkspace?.display_name}
                            onSubmitDisplayName={handleUpdateDisplayName}
                            entityType={localizedWorkspaceString}
                        />

                        <div className={SettingsStyles.tableContainer}>
                            <LabelWithIconButton
                                label={intl.formatMessage({
                                    id: SettingsKeys.Users,
                                    defaultMessage: 'Users',
                                })}
                                iconButton={
                                    <IconButton
                                        icon={<UserPlusIcon />}
                                        className={SettingsStyles.addUserIcon}
                                        onClick={handleOpenAddUsersDialog}
                                        tooltip={addUsersHelperText}
                                        tooltipProps={{
                                            placement: 'right',
                                        }}
                                    />
                                }
                            />
                            <UserRoleSettingsTable
                                users={workspaceUsers}
                                isLoading={isLoading}
                                onEditUserRole={handleOpenUserRoleDialog}
                                entityName={localizedWorkspaceString}
                                onRemoveUser={handleOpenRemoveUserDialog}
                            />
                        </div>
                    </MainContainer>
                </PageContent>
            </Page>
            <ManageUserRoleDialog
                title={intl.formatMessage(
                    {
                        id: WorkspaceKeys.setWorkspaceRole,
                        defaultMessage: `Set Workspace Role - ${getFullName(
                            userToEdit
                        )}`,
                    },
                    { userName: userToEdit ? getFullName(userToEdit) : '' }
                )}
                isOpen={isUserRoleDialogOpen}
                onClose={() => setIsUserRoleDialogOpen(false)}
                user={userToEdit}
                availableRoles={workspaceRoles}
                onUpdateRole={handleUpdateUserRole}
            />
            <AddUsersToEntityDialog
                isOpen={isAddUsersDialogOpen}
                onClose={() => setIsAddUsersDialogOpen(false)}
                availableUsers={availableUsers}
                roles={workspaceRoles}
                onAddUsers={handleAddUsersToWorkspace}
                entityName={selectedWorkspace?.name}
                isLoading={areAvailableUsersLoading}
                defaultRole={workspaceRoles?.find((r) => r.name === 'Member')}
                helperText={addUsersHelperText}
            />
            <RemoveUserFromEntityDialog
                user={userToEdit}
                isOpen={isRemoveUserDialogOpen}
                onClose={() => setIsRemoveUserDialogOpen(false)}
                onRemove={handleRemoveUserFromWorkspace}
                entityName={
                    selectedWorkspace ? selectedWorkspace.display_name : ''
                }
            />
        </React.Fragment>
    );
};
