import { api } from '@orbica/platform-sdk-dev';
import store from '@Services/redux/reduxStore';
import { OrbicaSdkClient } from '@Services/sdk/OrbicaSdkClient';
import { handleFailedSdkResponse } from '@Services/sdk/helpers';
import { IOrganization, OrganizationType } from './Interfaces';
import { OrganizationsActions } from './Reducer';

const mapOrgInterfaces = (org: api.IOrganization): IOrganization => {
    return {
        ...org,
        type: OrganizationType.ORGANIZATION,
    };
};

const Api = {
    reset: () => {
        store.dispatch(OrganizationsActions.updateOrganizations([]));
        store.dispatch(OrganizationsActions.setSelectedOrganization(null));
    },
    loadOrganizations: async (): Promise<IOrganization[]> => {
        const orgApi = await OrbicaSdkClient.instance.getOrganizationApi();
        const response = await orgApi.all();
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        } else {
            const orgs = response.data as api.IOrganization[];
            const mappedOrgs = orgs.map((o) => mapOrgInterfaces(o));

            store.dispatch(
                OrganizationsActions.updateOrganizations(mappedOrgs)
            );
            return mappedOrgs;
        }
    },
    setSelectedOrganization: (
        organization: IOrganization,
        callback?: () => void
    ) => {
        store.dispatch(
            OrganizationsActions.setSelectedOrganization(organization)
        );

        if (callback) {
            callback();
        }
    },
    loadOrganizationById: async (
        organizationId: string,
        throwError = false
    ): Promise<IOrganization> => {
        const orgApi = await OrbicaSdkClient.instance.getOrganizationApi();
        const response = await orgApi.get(organizationId);
        if (response.status === 'failed') {
            if (!throwError) {
                handleFailedSdkResponse(response.data);
                return null;
            } else {
                //@ts-ignore
                throw new Error(response.data.message);
            }
        } else {
            const org = response.data as api.IOrganization;
            const mappedOrg = mapOrgInterfaces(org);
            store.dispatch(
                OrganizationsActions.setSelectedOrganization(mappedOrg)
            );
        }
    },
    createOrganization: async (
        organization: api.IOrganizationNew
    ): Promise<IOrganization> => {
        const organizationApi =
            await OrbicaSdkClient.instance.getOrganizationApi();
        const response = await organizationApi.create(organization);

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }

        const newOrganization = response.data as api.IOrganization;

        return newOrganization;
    },
    addUserToOrganization: async (user: api.IUserNew): Promise<api.IUser> => {
        const organizationApi =
            await OrbicaSdkClient.instance.getOrganizationApi();
        const response = await organizationApi.addUser(user);

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }

        const newUser = response.data as api.IUser;

        return newUser;
    },
    updateOrganizationUserRole: async (
        selectedOrganization: IOrganization,
        userId: string,
        role: api.IRole
    ): Promise<api.IUpdatedUserRole> => {
        const organizationApi =
            await OrbicaSdkClient.instance.getOrganizationApi();

        const payload: api.IUserRoleUpdateInfo = {
            org_id: selectedOrganization.id,
            user_id: userId,
            role: role.name,
        };

        const response = await organizationApi.updateUserRole(payload);

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }

        const updatedUserRole = response.data as api.IUpdatedUserRole;

        return updatedUserRole;
    },
    setSelectedOrganizationUsers: (
        users: api.IOrgUserDetails[],
        callback?: () => void
    ) => {
        store.dispatch(
            OrganizationsActions.setSelectedOrganizationUsers(users)
        );

        if (callback) {
            callback();
        }
    },
    getOrganizationUsers: async (organizationId: string) => {
        const organizationApi =
            await OrbicaSdkClient.instance.getOrganizationApi();
        const response = await organizationApi.getOrgUsers(organizationId);

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }

        const users = response.data as api.IOrgUserDetails[];
        store.dispatch(
            OrganizationsActions.setSelectedOrganizationUsers(users)
        );

        return users;
    },
    getOrganizationRoles: async () => {
        const orgApi = await OrbicaSdkClient.instance.getOrganizationApi();
        const response = await orgApi.getOrganizationRoles();
        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        }
        store.dispatch(
            OrganizationsActions.setRoles(response.data as api.IRole[])
        );
    },
};

export default Api;
