import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
} from '@Components/Dialog';
import {
    EntityDisplayNameAndNameInputs,
    EntityNameDisplayNameValues,
} from '@Components/FormFields';
import { IWorkspace, WorkspacesApi } from '@Data/workspaces';
import { unusableWorkspaceNamesState } from '@Data/workspaces/Reducer';
import {
    isEntityDisplayNameValid,
    isEntityNameValid,
} from '@Services/helpers/Entities/EntityHelpers';
import { toTitleCase } from '@Services/helpers/Strings';
import { EntityKeys, FormKeys, WorkspaceKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';

interface IMangageWorkspaceDialog {
    isOpen: boolean;
    onClose: () => void;
    selectedOrganizationId: string;
}

const ManageWorkspaceDialog = (props: IMangageWorkspaceDialog) => {
    const intl = useIntl();
    const [workspaceName, setWorkspaceName] = useState<string>('');
    const [workspaceDisplayName, setWorkspaceDisplayName] =
        useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const unusableWorkspaceNames = useSelector(unusableWorkspaceNamesState);

    const navigate = useNavigate();

    const resetForm = () => {
        setWorkspaceName('');
        setWorkspaceDisplayName('');
        setIsSubmitting(false);
    };

    const handleClose = async () => {
        if (!isSubmitting) {
            await props.onClose();
            resetForm();
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const callbackFn = async (workspace: IWorkspace) => {
            await WorkspacesApi.loadWorkspaces(props.selectedOrganizationId);
            WorkspacesApi.setSelectedWorkspace(workspace, async () => {
                await props.onClose();
                const url = Routes.workspaces.getUrl(
                    props.selectedOrganizationId,
                    workspace.id
                );
                navigate(url);
                resetForm();
            });
        };

        const newWorkspace = await WorkspacesApi.createWorkspace(
            workspaceName,
            workspaceDisplayName,
            props.selectedOrganizationId
        );

        if (!_isNil(newWorkspace)) {
            await callbackFn(newWorkspace);
        } else {
            setIsSubmitting(false);
        }
    };

    const workspaceStr = toTitleCase(
        intl.formatMessage({
            id: EntityKeys.Workspace,
            defaultMessage: 'workspace',
        })
    );

    const title = intl.formatMessage({
        id: WorkspaceKeys.CreateNewWorkspace,
        defaultMessage: 'Create a new workspace',
    });

    const submitText = intl.formatMessage(
        { id: FormKeys.CreateObject, defaultMessage: 'Create Workspace' },
        { object: workspaceStr }
    );

    const submittingText = intl.formatMessage(
        {
            id: FormKeys.CreatingObject,
            defaultMessage: 'Creating Workspace',
        },
        { object: workspaceStr }
    );

    const handleUpdateInputFields = (
        newValues: EntityNameDisplayNameValues
    ) => {
        if (!_isNil(newValues.displayName)) {
            setWorkspaceDisplayName(newValues.displayName);
        }

        if (!_isNil(newValues.name)) {
            setWorkspaceName(newValues.name);
        }
    };

    const wspDisplayNameValidation =
        isEntityDisplayNameValid(workspaceDisplayName);
    const wspNameValidation = isEntityNameValid(workspaceName);
    const canSubmit =
        wspDisplayNameValidation.isValid &&
        wspNameValidation.isValid &&
        workspaceName !== '' &&
        workspaceDisplayName !== '';

    return (
        <Dialog open={props.isOpen} onClose={handleClose}>
            <DialogHeader
                onClose={handleClose}
                isCloseDisabled={isSubmitting}
                title={title}
            />
            <DialogBody>
                <EntityDisplayNameAndNameInputs
                    nameValue={workspaceName}
                    displayNameValue={workspaceDisplayName}
                    onChange={handleUpdateInputFields}
                    protectedNames={unusableWorkspaceNames}
                    parentEntity={intl.formatMessage({
                        id: EntityKeys.Organization,
                        defaultMessage: 'organization',
                    })}
                    entity={intl.formatMessage({
                        id: EntityKeys.Workspace,
                        defaultMessage: 'workspace',
                    })}
                />
            </DialogBody>

            <DialogFooter
                submitText={submitText}
                onCancel={handleClose}
                onSubmit={handleSubmit}
                isSubmitDisabled={!canSubmit}
                isSubmitting={isSubmitting}
                submittingText={submittingText}
            />
        </Dialog>
    );
};

export default ManageWorkspaceDialog;
