import baseMuiTheme from '@Styles/MuiTheming';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { WebStorageStateStore } from 'oidc-client-ts';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { Provider } from 'react-redux';
import store from '@Services/redux/reduxStore';
import App from './App';

const container = document.getElementById('root')!;
const root = createRoot(container);

const oidcConfig: AuthProviderProps = {
    authority: process.env.ZITADEL_AUTHORITY,
    client_id: process.env.ZITADEL_CLIENT_ID,
    response_type: 'code',
    redirect_uri: process.env.BASE_URI,
    scope: `openid profile email offline_access urn:zitadel:iam:user:metadata`,
    post_logout_redirect_uri: process.env.LOGOUT_URI,
    onSigninCallback: () =>
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        ),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
};

const posthogConfig = {
    api_host: 'https://app.posthog.com',
};

root.render(
    <PostHogProvider
        apiKey={process.env.POSTHOG_API_KEY}
        options={posthogConfig}
    >
        <React.StrictMode>
            <AuthProvider {...oidcConfig}>
                <Provider store={store}>
                    <ThemeProvider theme={baseMuiTheme}>
                        <CssBaseline>
                            <App />
                        </CssBaseline>
                    </ThemeProvider>
                </Provider>
            </AuthProvider>
        </React.StrictMode>
    </PostHogProvider>
);
