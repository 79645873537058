import ColorStyles from '@Styles/colors.scss';
import { Breadcrumbs } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ViewKeys } from '@Services/i18n/keys';
import { Routes } from '@Services/navigation';
import Styles from './Breadcrumbs.scss';
import { BreadcrumbsType, IBreadcrumbsComponentProps } from './Interfaces';

/**
 * A component that displays a user's current workspace.
 * This component is intended to be extended.
 */
export const BreadcrumbsComponent = (props: IBreadcrumbsComponentProps) => {
    const intl = useIntl();

    const itemStyle =
        props.type === BreadcrumbsType.HEADER
            ? Styles.headerBreadcrumbsItem
            : Styles.bodyBreadcrumbsItem;

    const bcs: Array<ReactElement> = [];
    if (props.displayRootWorkspace) {
        const url = Routes.workspaces.getUrl(props.selectedOrganizationId);
        bcs.push(
            <Link className={itemStyle} to={url} key="bcRoot">
                {intl.formatMessage({
                    id: ViewKeys.Workspaces,
                    defaultMessage: 'Workspaces',
                })}
            </Link>
        );
    }

    if (!_isNil(props.selectedWorkspace)) {
        const url = Routes.workspaces.getUrl(
            props.selectedOrganizationId,
            props.selectedWorkspace.id
        );
        bcs.push(
            <Link className={itemStyle} to={url} key="bcSelectedWorkspace">
                {props.selectedWorkspace.display_name}
            </Link>
        );
    }

    return (
        <Breadcrumbs
            sx={{
                '& .MuiBreadcrumbs-separator': {
                    color:
                        props.type === BreadcrumbsType.HEADER
                            ? ColorStyles.greyscale20
                            : ColorStyles.greyscale70,
                },
            }}
        >
            {bcs}
        </Breadcrumbs>
    );
};
