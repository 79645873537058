import ColorStyles from '@Styles/colors.scss';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import _isNil from 'lodash/isNil';
import React, { useRef, useState } from 'react';
import { IIconButtonProps } from './Interfaces';

const StyledContainer = styled('button')(
    ({ theme, fillColor, strokeColor }) => ({
        height: '32px',
        width: '32px',
        borderRadius: '4px',
        padding: 0,
        border: '1px solid transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        transition: theme.transitions.create(['background-color'], {
            duration: theme.transitions.duration.standard,
        }),

        svg: {
            path:
                fillColor || strokeColor
                    ? {
                          fill: fillColor ?? 'unset',
                          stroke: strokeColor ?? 'unset',
                      }
                    : null,
        },

        ['&:disabled']: {
            cursor: 'initial',

            ['&:hover']: {
                backgroundColor: 'transparent',
            },
        },

        ['&:hover']: {
            backgroundColor: ColorStyles.greyscale20,
        },
    })
);

/**
 * A styled button that displays an icon, and an optional tooltip.
 * NB: To style svgs, check whether the stroke or fill has been set to "current",
 * eg.  <path
 *          d="M18 15L12 9L6 15"
 *          stroke="current"
 *          stroke-width="2"
 *          stroke-linecap="round"
 *          stroke-linejoin="round"
 *      />
 */
export const IconButton: React.FC<IIconButtonProps> = (props) => {
    const ref = useRef();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const {
        icon,
        fill,
        stroke,
        tooltip,
        tooltipProps,
        containerProps,
        as,
        ...buttonProps
    } = props;

    // const fillColor = fill ? fill : ColorStyles.greyscale60;
    // const strokeColor = stroke ? stroke : ColorStyles.greyscale60;

    const handleClick = (e) => {
        setIsVisible(false);
        props.onClick(e);
    };

    // stops react.popper complaining if the icon needs to be re-rendered with a tooltip
    const children = (
        <div {...containerProps} data-cy={props.tooltip?.toLowerCase()}>
            <StyledContainer
                {...buttonProps}
                ref={ref}
                disabled={props.disabled}
                onClick={handleClick}
                as={as}
                fillColor={fill}
                strokeColor={stroke}
            >
                {icon}
            </StyledContainer>
        </div>
    );

    return !_isNil(tooltip) && !props.disabled ? (
        <Tooltip
            title={tooltip}
            {...tooltipProps}
            open={isVisible}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
        </Tooltip>
    ) : (
        children
    );
};
