import { ReactComponent as InfoIcon } from '@Icons/lg/help-circle.svg';
import { ReactComponent as CloseIcon } from '@Icons/lg/x.svg';
import defaultImg from '@Images/taranaki.png';
import Flex from '@Styles/flex.scss';
import cx from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonSize, IconButton, TertiaryButton } from '@Components/Buttons';
import { SolutionKeys } from '@Services/i18n/keys';
import DisplayCard from '../DisplayCard';
import { ICardProps } from '../Interfaces';
import { ServiceCardImageContainer } from './ServiceCardImageContainer';
import Styles from './ServiceCards.scss';

export interface IServiceCatalogCardProps extends ICardProps {
    name: string;
    logo?: string;
    info: string;
    documentationUrl: string;
}

export const ServiceCatalogCard = (props: IServiceCatalogCardProps) => {
    const intl = useIntl();

    const { logo, name, info, documentationUrl, ...cardProps } = props;
    const [displayInfo, setDisplayInfo] = useState<boolean>(false);

    const logoSrc = logo ? logo : defaultImg;

    const onDisplayInfoBtnClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDisplayInfo(!displayInfo);
    };

    const displayDocumentation = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(documentationUrl);
        setDisplayInfo(!displayInfo);
    };

    const infoHtml = displayInfo ? (
        <div className={Styles.serviceCatalogInfoContainer}>
            <span>
                {intl.formatMessage({
                    id: info,
                    defaultMessage: info,
                })}
            </span>

            <TertiaryButton
                onClick={displayDocumentation}
                text={intl.formatMessage({
                    id: SolutionKeys.ViewDocumentation,
                    defaultMessage: 'View documentation',
                })}
                size={ButtonSize.SMALL}
                style={{
                    padding: 0,
                    justifyContent: 'flex-start',
                }}
            />
        </div>
    ) : (
        <img src={logoSrc} className={Styles.serviceCardLogo} />
    );

    const icon = displayInfo ? <CloseIcon /> : <InfoIcon />;

    return (
        <DisplayCard
            cardClasses={Styles.serviceCard}
            className={Styles.serviceCardBase}
            {...cardProps}
        >
            <ServiceCardImageContainer
                className={Styles.serviceCatalogCardLogoContainer}
                image={infoHtml}
            />
            <div className={Styles.serviceCatalogCardDetailsContainer}>
                <span className={Styles.serviceCatalogCardTitle}>
                    {intl.formatMessage({
                        id: name,
                        defaultMessage: name,
                    })}
                </span>
                <div className={cx(Flex.flex, Flex.justifyEnd)}>
                    <IconButton
                        as="div"
                        icon={icon}
                        onClick={onDisplayInfoBtnClick}
                        containerProps={{
                            style: {
                                marginRight: '-10px',
                            },
                        }}
                    />
                </div>
            </div>
        </DisplayCard>
    );
};
