import TypographyStyles from '@Styles/typography.scss';
import React from 'react';
import { Header, HeaderType } from '@Components/Header';
import { MainContainer, Page, PageContent } from '@Components/Page';

export const PageNotFoundView = () => {
    return (
        <Page>
            <Header headerType={HeaderType.DEFAULT} />
            <PageContent>
                <MainContainer fullWidth>
                    <div
                        style={{
                            gridColumn: '1/-1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <span className={TypographyStyles.h2}>
                            404 - Page not found
                        </span>
                    </div>
                </MainContainer>
            </PageContent>
        </Page>
    );
};
