import Flex from '@Styles/flex.scss';
import LayoutStyles from '@Styles/layout.scss';
import { Accordion } from '@mui/material';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AccordionSummary } from '@Components/Accordion/AccordionSummary';
import {
    EntityDisplayNameAndNameInputs,
    EntityNameDisplayNameValues,
} from '@Components/FormFields/EntityDisplayNameAndNameInputs';
import { InputSize, TextInput } from '@Components/Inputs';
import {
    IAddServiceCatalogService,
    IAnalyticsResource,
    IAnalyticsResourceLimitInput,
    IAnalyticsVersion,
    IAnalyticsVersionInput,
} from '@Data/solutions/Interfaces';
import { isNameClean, splitCamelCase } from '@Services/helpers/Strings';
import { EntityKeys, FormKeys, SolutionKeys } from '@Services/i18n/keys';
import { AnalyticsResourceLimitsSelector } from './AnalyticsResourceLimitsSelector';
import { AnalyticsVersionSelector } from './AnalyticsVersionSelector';
import { CustomServiceSettingsTextArea } from './CustomServiceSettingsTextArea';
import { PasswordInput } from './PasswordInput';
import Styles from './ServiceInformation.scss';
import { ServiceInformationContent } from './ServiceInformationContent';
import { UseDatabaseCheckbox } from './UseDatabaseCheckbox';

interface IServiceInformationProps {
    service: IAddServiceCatalogService;
    onSettingsChange: (
        value: string | boolean | any | any[],
        setting: string | string[]
    ) => void;
    unusableNames: string[];
}

const ServiceInformation = (props: IServiceInformationProps) => {
    const intl = useIntl();
    const [errorText, setErrorText] = useState<string>('');

    const handleSettingsChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        setting: string
    ) => {
        props.onSettingsChange(e.target.value, setting);
    };

    const handleAutoFormatName = (event, setting: string) => {
        const { wasClean, newName } = isNameClean(
            event.target.value,
            setErrorText
        );
        setErrorText(
            wasClean
                ? ''
                : intl.formatMessage({
                      id: FormKeys.ObjectNameHasBeenFormatted,
                      defaultMessage:
                          'This name has been automatically formatted to remove spaces and special characters.',
                  })
        );

        if (!wasClean) {
            props.onSettingsChange(newName, setting);
        }
    };

    const settings = Object.entries(props.service.settings.inputs).map(
        ([key, value]) => {
            if (key === 'customServiceSettings') {
                return (
                    <CustomServiceSettingsTextArea
                        onChange={(value) => props.onSettingsChange(value, key)}
                        value={value}
                        key={`${props.service.id}-custom-settings`}
                    />
                );
            } else if (key === 'name') {
                // if we can set a display name, don't render this
                if (
                    Object.prototype.hasOwnProperty.call(
                        props.service.settings.inputs,
                        'displayName'
                    )
                ) {
                    return null;
                }
                return (
                    <TextInput
                        size={InputSize.LARGE}
                        value={value}
                        label={intl.formatMessage({
                            id: SolutionKeys.ManageSolutionName,
                            defaultMessage: 'Service Name',
                        })}
                        key={`${props.service.id}-settings-name`}
                        containerClassName={LayoutStyles.dialogInputMedium}
                        required
                        onChange={(e) => handleSettingsChange(e, key)}
                        error={!!errorText} // Will be true if errorText is not an empty string
                        helperText={errorText} // Displays the error text
                        onBlur={(e) => handleAutoFormatName(e, key)}
                    />
                );
            } else if (key === 'displayName') {
                const [nameKey, nameValue] = Object.entries(
                    props.service.settings.inputs
                ).find(([key, value]) => key === 'name');

                // we need to batch update both values simultaneously
                const handleChange = (
                    newValues: EntityNameDisplayNameValues
                ) => {
                    const values = [];
                    const keys = [];

                    if (!_isNil(newValues.displayName)) {
                        values.push(newValues.displayName);
                        keys.push('displayName');
                    }

                    if (!_isNil(newValues.name)) {
                        values.push(newValues.name);
                        keys.push('name');
                    }

                    props.onSettingsChange(values, keys);
                };

                return (
                    <EntityDisplayNameAndNameInputs
                        displayNameValue={value}
                        nameValue={nameValue}
                        onChange={handleChange}
                        protectedNames={props.unusableNames}
                        entity={intl.formatMessage({
                            id: EntityKeys.Solution,
                            defaultMessage: 'solution',
                        })}
                        parentEntity={intl.formatMessage({
                            id: EntityKeys.Project,
                            defaultMessage: 'project',
                        })}
                        key={`${props.service.id}-settings-displayName`}
                    />
                    // <DisplayNameInput
                    //     displayNameValue={value}
                    //     nameValue={nameValue}
                    //     onChange={props.onSettingsChange}
                    //     key={`${props.service.id}-settings-displayName`}
                    // />
                );
            } else if (key === 'useDatabase') {
                return (
                    <UseDatabaseCheckbox
                        onChange={props.onSettingsChange}
                        settingName={key}
                        key={`${props.service.id}-settings-useDb`}
                        value={value}
                    />
                );
            } else if (key === 'password' || key === 'hasuraSecretKey') {
                const label =
                    key === 'hasuraSecretKey'
                        ? intl.formatMessage({
                              id: SolutionKeys.ManageSolutionHasuraSecretKey,
                              defaultMessage: 'Hasura Secret Key',
                          })
                        : null;

                const solutionName =
                    key === 'hasuraSecretKey' ? 'Hasura' : 'Jupyter';

                return (
                    <PasswordInput
                        onChange={props.onSettingsChange}
                        settingName={key}
                        key={`${props.service.id}-settings-password`}
                        value={value}
                        label={label}
                        solutionName={solutionName}
                    />
                );
            } else if (key === 'instanceSize') {
                return (
                    <TextInput
                        size={InputSize.LARGE}
                        value={value}
                        label={intl.formatMessage({
                            id: SolutionKeys.ManageSolutionInstanceSize,
                            defaultMessage: 'Instance size',
                        })}
                        key={`${props.service.id}-settings-${key}`}
                        containerClassName={LayoutStyles.dialogInputMedium}
                        required
                        onChange={(e) => handleSettingsChange(e, key)}
                    />
                );
            } else if (key === 'resourceLimit') {
                const resourceLimitObj = props.service.settings.inputs[
                    key
                ] as IAnalyticsResourceLimitInput;

                const handleResourceLimitChange = (
                    newLimit: IAnalyticsResource
                ) => {
                    resourceLimitObj.selectedResourceLimit = newLimit;
                    props.onSettingsChange(resourceLimitObj, key);
                };

                return (
                    <AnalyticsResourceLimitsSelector
                        key={`${props.service.id}-resourceLimitSelect`}
                        options={resourceLimitObj.options}
                        selectedResourceLimit={
                            resourceLimitObj.selectedResourceLimit
                        }
                        onChange={handleResourceLimitChange}
                    />
                );
            } else if (key === 'analyticsVersion') {
                const analyticsVersionObj = props.service.settings.inputs[
                    key
                ] as IAnalyticsVersionInput;

                const handleAnalyticsVersionChange = (
                    newVersion: IAnalyticsVersion
                ) => {
                    analyticsVersionObj.selectedVersion = newVersion;
                    props.onSettingsChange(analyticsVersionObj, key);
                };

                return (
                    <AnalyticsVersionSelector
                        key={`${props.service.id}-analyticsVersionSelect`}
                        options={analyticsVersionObj.options}
                        selectedVersion={analyticsVersionObj.selectedVersion}
                        onChange={handleAnalyticsVersionChange}
                    />
                );
            } else if (key === 'currentUserInformation') {
                return null;
            } else {
                return (
                    <TextInput
                        size={InputSize.LARGE}
                        value={value}
                        label={splitCamelCase(key, true)}
                        key={`${props.service.id}-settings-${key}`}
                        containerClassName={LayoutStyles.dialogInputSmall}
                        required
                        onChange={(e) => handleSettingsChange(e, key)}
                    />
                );
            }
        }
    );

    return (
        <React.Fragment>
            <div className={Styles.serviceDetailsContainer}>
                <div className={cx(Flex.flex, Flex.column)}>
                    <span className={Styles.serviceSettingsTitle}>
                        {intl.formatMessage({
                            id: props.service.name,
                            defaultMessage: props.service.name,
                        })}
                    </span>
                    {props.service.contents?.length > 0 && (
                        <Accordion>
                            <AccordionSummary
                                title={intl.formatMessage({
                                    id: SolutionKeys.Details,
                                    defaultMessage: 'Details',
                                })}
                            />
                            <div className={Styles.serviceContentsContainer}>
                                <React.Fragment>
                                    <span>
                                        {intl.formatMessage({
                                            id: SolutionKeys.Contents,
                                            defaultMessage: 'Contents',
                                        })}
                                    </span>
                                    {props.service.contents.map(
                                        (contentDetail) => (
                                            <ServiceInformationContent
                                                key={`${props.service.id}-contents-${contentDetail.name}`}
                                                content={contentDetail}
                                            />
                                        )
                                    )}
                                </React.Fragment>
                            </div>
                        </Accordion>
                    )}
                </div>
            </div>

            <span className={Styles.serviceSettingsSection}>
                {intl.formatMessage({
                    id: SolutionKeys.Settings,
                    defaultMessage: 'Settings',
                })}
            </span>
            <div className={Styles.settingsInputsContainer}>{settings}</div>
        </React.Fragment>
    );
};

export default ServiceInformation;
