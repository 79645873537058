import { ReactComponent as ChevronDownIcon } from '@Icons/lg/chevron-down.svg';
import theme from '@Styles/MuiTheming';
import {
    AccordionSummaryProps,
    AccordionSummary as MuiAccordionSummary,
    styled,
} from '@mui/material';
import React from 'react';
import Styles from './AccordionComponents.scss';

const StyledAccordion = styled(MuiAccordionSummary)`
    svg {
        transition: ${theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.standard,
        })};
    }

    & .Mui-expanded {
        svg {
            transform: rotate(180deg);
        }
    }
`;

/**
 * @noInheritDoc
 */
export interface IAccordionSummaryProps extends AccordionSummaryProps {
    /**
     * The text to display on the button
     */
    title: string;
}

/**
 * A styled implementation of MUI's Accordion Summary
 */
export const AccordionSummary = (props: IAccordionSummaryProps) => {
    const { title, ...accordionProps } = props;

    return (
        <StyledAccordion {...accordionProps}>
            <div className={Styles.accordionSummaryContainer}>
                <span className={Styles.accordionSummaryTitle}>{title}</span>
                <ChevronDownIcon className={Styles.accordionSummaryIcon} />
            </div>
        </StyledAccordion>
    );
};
