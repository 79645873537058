import { ReactComponent as UserPlusIcon } from '@Icons/lg/user-plus.svg';
import GridStyles from '@Styles/grid.scss';
import { api } from '@orbica/platform-sdk-dev';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@Components/Buttons';
import { Header, HeaderType } from '@Components/Header';
import { LabelWithIconButton } from '@Components/Label/LabelWithIconButton';
import { MainContainer, Page, PageContent } from '@Components/Page';
import {
    ISettingsUser,
    ManageUserRoleDialog,
    RemoveUserFromEntityDialog,
    UserRoleSettingsTable,
    getFullName,
} from '@Components/Settings';
import { AddUsersToEntityDialog } from '@Components/Settings/AddUsersToEntityDialog';
import { EntityNameDisplayName } from '@Components/Settings/EntityNameDisplayName';
import { ToastType } from '@Components/Toasts';
import { canCurrentUserEditProjectState } from '@Data/auth';
import {
    OrganizationsApi,
    selectedOrganizationState,
    selectedOrganizationUsersState,
} from '@Data/organizations';
import { ProjectsApi, selectedProjectState } from '@Data/projects';
import {
    projectsRolesState,
    selectedProjectUsersState,
} from '@Data/projects/Reducer';
import { WorkspacesApi, selectedWorkspaceState } from '@Data/workspaces';
import {
    EntityKeys,
    FormKeys,
    ProjectKeys,
    SettingsKeys,
} from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';
import SettingsStyles from '../Settings.scss';

export const ProjectSettingsView = () => {
    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();

    const organizationId = params.organizationId;
    const workspaceId = params.workspaceId;
    const projectId = params.projectId;

    const canCurrentUserEditProject = useSelector(
        canCurrentUserEditProjectState
    );
    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);
    const selectedProject = useSelector(selectedProjectState);
    const organizationUsers = useSelector(selectedOrganizationUsersState);
    const projectUsers = useSelector(selectedProjectUsersState);
    const projectRoles = useSelector(projectsRolesState);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userToEdit, setUserToEdit] = useState<ISettingsUser>(null);
    const [isUserRoleDialogOpen, setIsUserRoleDialogOpen] =
        useState<boolean>(false);
    const [isAddUsersDialogOpen, setIsAddUsersDialogOpen] =
        useState<boolean>(false);
    const [availableUsers, setAvailableUsers] = useState<ISettingsUser[]>([]);
    const [areAvailableUsersLoading, setAreAvailableUsersLoading] =
        useState<boolean>(false);
    const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] =
        useState<boolean>(false);

    useEffect(() => {
        const getSelectedOrganization = async () => {
            await OrganizationsApi.loadOrganizationById(organizationId);
        };

        if (
            !_isNil(organizationId) &&
            (_isNil(selectedOrganization) ||
                selectedOrganization.id !== organizationId)
        ) {
            getSelectedOrganization();
        }
    }, [selectedOrganization, organizationId]);

    useEffect(() => {
        const getSelectedWorkspace = async () => {
            await WorkspacesApi.loadWorkspaceById(workspaceId);
        };

        if (
            !_isNil(selectedOrganization) &&
            !_isNil(workspaceId) &&
            (_isNil(selectedWorkspace) || selectedWorkspace.id !== workspaceId)
        ) {
            getSelectedWorkspace();
        }
    }, [selectedOrganization, selectedWorkspace, workspaceId]);
    const getSelectedProject = async () => {
        await ProjectsApi.loadProjectById(projectId);
    };
    useEffect(() => {
        if (
            !_isNil(selectedOrganization) &&
            !_isNil(selectedWorkspace) &&
            !_isNil(projectId) &&
            (_isNil(selectedProject) || selectedProject.id !== projectId)
        ) {
            getSelectedProject();
        }
    }, [selectedOrganization, selectedWorkspace, selectedProject, projectId]);

    const getProjectUsers = async () => {
        const orgUsers = _isNil(organizationUsers)
            ? await OrganizationsApi.getOrganizationUsers(organizationId)
            : organizationUsers;

        await ProjectsApi.getProjectUsers(selectedProject.id, orgUsers);
    };

    useEffect(() => {
        const getUsers = async () => {
            await getProjectUsers();
            await ProjectsApi.getProjectRoles();
            setIsLoading(false);
        };

        if (!_isNil(selectedProject)) {
            getUsers();
        }
    }, [selectedProject]);

    useEffect(() => {
        if (
            !isLoading &&
            !_isNil(selectedOrganization) &&
            selectedOrganization.id === organizationId &&
            !_isNil(selectedWorkspace) &&
            selectedWorkspace.id === workspaceId &&
            !canCurrentUserEditProject
        ) {
            navigate('/');
        }
    }, [
        selectedOrganization,
        organizationId,
        selectedWorkspace,
        workspaceId,
        canCurrentUserEditProject,
        isLoading,
    ]);

    const handleOpenAddUsersDialog = async () => {
        setIsAddUsersDialogOpen(true);
        setAreAvailableUsersLoading(true);

        const orgUsers = await OrganizationsApi.getOrganizationUsers(
            selectedOrganization.id
        );

        const workspaceUsers = await WorkspacesApi.getWorkspaceUsers(
            selectedWorkspace.id,
            orgUsers
        );

        const unaddedWorkspaceUsers: ISettingsUser[] = workspaceUsers
            .filter((user) => {
                return (
                    user.role.name !== 'Admin' &&
                    projectUsers.findIndex(
                        (wspUser) => wspUser.id === user.id
                    ) === -1
                );
            })
            .map((user) => {
                return {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    role: null,
                };
            });

        setAvailableUsers(unaddedWorkspaceUsers);
        setAreAvailableUsersLoading(false);
    };

    const handleOpenUserRoleDialog = async (user: ISettingsUser) => {
        setUserToEdit(user);
        setIsUserRoleDialogOpen(true);
    };

    const handleOpenRemoveUserDialog = (user: ISettingsUser) => {
        setUserToEdit(user);
        setIsRemoveUserDialogOpen(true);
    };

    const handleUpdateUserRole = async (
        user: ISettingsUser,
        newRole: api.IRole
    ) => {
        const wasSuccessful = await ProjectsApi.upsertProjectUserRole(
            selectedProject,
            user.id,
            newRole
        );

        if (wasSuccessful) {
            const toastMsg = intl.formatMessage(
                {
                    id: FormKeys.ObjectUpdated,
                    defaultMessage: 'Role updated',
                },
                { object: 'Role' }
            );
            createToast(toastMsg, ToastType.SUCCESS);
            await getProjectUsers();
        }
    };

    const handleAddUsersToProject = async (users: ISettingsUser[]) => {
        await ProjectsApi.addUsersToProject(users, selectedProject.id);
        await getProjectUsers();
    };

    const handleRemoveUserFromProject = async (user: ISettingsUser) => {
        const wasSuccessful = await ProjectsApi.removeUserFromProject(
            user,
            selectedProject.id
        );
        if (wasSuccessful) {
            createToast(
                intl.formatMessage({
                    id: SettingsKeys.UserRemoved,
                    defaultMessage: 'User Removed',
                }),
                ToastType.SUCCESS
            );
            await getProjectUsers();
        }
    };

    const addUsersHelperText = intl.formatMessage({
        id: ProjectKeys.AddMembers,
        defaultMessage: 'Add members of your Workspace to your Project',
    });

    const localizedProjectString = intl.formatMessage({
        id: EntityKeys.Project,
        defaultMessage: 'Project',
    });

    const handleUpdateDisplayName = async (name: string) => {
        await ProjectsApi.editProjectDisplayName(
            selectedProject,
            name,
            selectedWorkspace.id
        );
        await getSelectedProject();
    };

    return (
        <React.Fragment>
            <Page>
                <Header
                    headerType={HeaderType.SETTINGS}
                    isLoading={isLoading}
                />
                <PageContent>
                    <MainContainer
                        gridClassName={cx(
                            GridStyles.alignContentStart,
                            SettingsStyles.settingsGrid
                        )}
                    >
                        <div className={SettingsStyles.titleContainer}>
                            <div className={SettingsStyles.title}>
                                {intl.formatMessage({
                                    id: SettingsKeys.ProjectSettings,
                                    defaultMessage: 'Project Settings',
                                })}
                            </div>
                        </div>
                        <div className={SettingsStyles.divider} />

                        <EntityNameDisplayName
                            entityName={selectedProject?.name}
                            entityDisplayName={selectedProject?.display_name}
                            onSubmitDisplayName={handleUpdateDisplayName}
                            entityType={localizedProjectString}
                        />

                        <div className={SettingsStyles.tableContainer}>
                            <LabelWithIconButton
                                label={intl.formatMessage({
                                    id: SettingsKeys.Users,
                                    defaultMessage: 'Users',
                                })}
                                iconButton={
                                    <IconButton
                                        icon={<UserPlusIcon />}
                                        className={SettingsStyles.addUserIcon}
                                        onClick={handleOpenAddUsersDialog}
                                        tooltip={addUsersHelperText}
                                        tooltipProps={{
                                            placement: 'right',
                                        }}
                                    />
                                }
                            />
                            <UserRoleSettingsTable
                                users={projectUsers}
                                isLoading={isLoading}
                                onEditUserRole={handleOpenUserRoleDialog}
                                entityName={localizedProjectString}
                                onRemoveUser={handleOpenRemoveUserDialog}
                            />
                        </div>
                    </MainContainer>
                </PageContent>
            </Page>
            <ManageUserRoleDialog
                title={intl.formatMessage(
                    {
                        id: ProjectKeys.SetProjectRole,
                        defaultMessage: `Set Project Role - ${getFullName(
                            userToEdit
                        )}`,
                    },
                    { userName: userToEdit ? getFullName(userToEdit) : '' }
                )}
                isOpen={isUserRoleDialogOpen}
                onClose={() => setIsUserRoleDialogOpen(false)}
                user={userToEdit}
                availableRoles={projectRoles}
                onUpdateRole={handleUpdateUserRole}
            />
            <AddUsersToEntityDialog
                isOpen={isAddUsersDialogOpen}
                onClose={() => setIsAddUsersDialogOpen(false)}
                availableUsers={availableUsers}
                roles={projectRoles}
                onAddUsers={handleAddUsersToProject}
                entityName={selectedProject?.display_name}
                isLoading={areAvailableUsersLoading}
                defaultRole={projectRoles?.find((r) => r.name === 'Member')}
                helperText={addUsersHelperText}
            />
            <RemoveUserFromEntityDialog
                user={userToEdit}
                isOpen={isRemoveUserDialogOpen}
                onClose={() => setIsRemoveUserDialogOpen(false)}
                onRemove={handleRemoveUserFromProject}
                entityName={selectedProject ? selectedProject.display_name : ''}
            />
        </React.Fragment>
    );
};
