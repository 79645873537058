import { TabsListUnstyled, TabsUnstyled } from '@mui/base';
import React from 'react';
import { ITabsProps } from './Interfaces';
import Styles from './Tabs.scss';

export const Tabs: React.FC<ITabsProps> = (props) => {
    return (
        <TabsUnstyled
            value={props.value}
            className={Styles.tabsContainer}
            onChange={props.onChange}
        >
            <TabsListUnstyled className={Styles.tabsContainer}>
                {props.children}
            </TabsListUnstyled>
        </TabsUnstyled>
    );
};
