import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import { IPersistentNotification } from '@Services/notifications';
import { RootState } from '@Services/redux/reduxStore';
import { INotificationsState, initialNotificationsState } from './State';

const NotificationsSlice = createSlice({
    name: 'Notifications',
    initialState: initialNotificationsState,
    reducers: {
        upsertPersistentNotification(
            state: INotificationsState,
            action: PayloadAction<IPersistentNotification>
        ) {
            const existingNotificationIndex =
                state.persistentNotifications.findIndex(
                    (n) => n.toastId === action.payload.toastId
                );
            if (existingNotificationIndex > -1) {
                state.persistentNotifications[existingNotificationIndex] =
                    action.payload;
            } else {
                state.persistentNotifications.push(action.payload);
            }
        },
        removePersistentNotification(
            state: INotificationsState,
            action: PayloadAction<IPersistentNotification>
        ) {
            const existingNotificationIndex =
                state.persistentNotifications.findIndex(
                    (n) => n.toastId === action.payload.toastId
                );
            if (existingNotificationIndex > -1) {
                state.persistentNotifications.splice(
                    existingNotificationIndex,
                    1
                );
            }
        },
    },
});

export const NotificationsActions = NotificationsSlice.actions;

export const persistentNotificationsState = (
    state: RootState
): Array<IPersistentNotification> =>
    state.notifications.persistentNotifications;
export const sortedPersistentNotificationsState = createSelector(
    persistentNotificationsState,
    (notifications) => {
        return _orderBy(notifications, 'updated', 'desc');
    }
);

export default NotificationsSlice.reducer;
