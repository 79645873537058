import ColorsStyles from '@Styles/colors.scss';
import { ToastType } from '@Components/Toasts';

export function getNotificationColor(type: ToastType): string {
    switch (type) {
        case ToastType.SUCCESS:
            return ColorsStyles.matcha;
        case ToastType.INFO:
            return ColorsStyles.blue;
        case ToastType.ERROR:
            return ColorsStyles.red;
        case ToastType.WARNING:
            return ColorsStyles.mandarin;
    }
}
