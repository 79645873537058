import { api } from '@orbica/platform-sdk-dev';
import store from '@Services/redux/reduxStore';
import { OrbicaSdkClient } from '@Services/sdk/OrbicaSdkClient';
import { handleFailedSdkResponse } from '@Services/sdk/helpers';
import { AuthActions } from './Reducer';

const Api = {
    setHasUser: () => {
        store.dispatch(AuthActions.updateUser(true));
    },
    getCurrentUserRoles: async (zitadelId: string): Promise<api.IUserRole> => {
        const userApi = await OrbicaSdkClient.instance.getUserApi();
        const response = await userApi.getUserDetails(zitadelId);

        if (response.status === 'failed') {
            handleFailedSdkResponse(response.data);
            return null;
        } else {
            const userDetails = response.data as api.IUserRole;
            store.dispatch(AuthActions.updateCurrentUserRoles(userDetails));
            return userDetails;
        }
    },
};

export default Api;
