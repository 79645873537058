import ColorStyles from '@Styles/colors.scss';
import TypographyStyles from '@Styles/typography.scss';
import { styled } from '@mui/system';
import cx from 'classnames';
import React from 'react';
import { InputSize } from '@Components/Inputs';
import { IFormControlLabelProps } from './Interfaces';

const getLabelClasses = (size: InputSize) => {
    return size === InputSize.SMALL
        ? TypographyStyles.bodyMedium
        : TypographyStyles.bodyNormal;
};

const StyledLabel = styled(`label`)<IFormControlLabelProps>(
    () => `
    color: ${ColorStyles.greyscale90};
    font-weight: 400;
    user-select: none;
    cursor: pointer;
`
);

export const FormControlLabel = (props: IFormControlLabelProps) => {
    const { focused, size, className, ...labelProps } = props;

    return (
        <StyledLabel
            focused={focused}
            className={cx(getLabelClasses(size), className)}
            size={size}
            {...labelProps}
        >
            {props.value}
        </StyledLabel>
    );
};
