import { ReactComponent as ChevronIcon } from '@Icons/lg/chevron-right.svg';
import { styled } from '@mui/material/styles';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { IButtonProps } from './Interfaces';
import { ChevronContainer, StyledHoverButton } from './StyledButton';

// without the bgcolor attribute, the transition will flicker
const SecondaryStyledButton = styled(StyledHoverButton)(
    ({ theme, color }) => `
    background: ${theme.palette[color].contrastText};
    color: ${theme.palette[color].main};
    border: 2px solid ${theme.palette[color].main};

    .leading-icon {        
        path {
            fill: ${theme.palette[color].main};
            stroke: ${theme.palette[color].main};  
        }    
                  
    }

    ${ChevronContainer} {            
        svg {
            path {
                stroke: ${theme.palette[color].main};
            }
        }
    } 

    &:hover, &:focus {
        color: ${theme.palette[color].contrastText};
        background: ${theme.palette[color].main};
        border: 2px solid ${theme.palette[color].main};

        .button-icon {
            stroke: #fff;
            fill: #fff;
        }

        .trailing-icon {
            stroke: #fff;
        }
    }
`
);

/**
 * Button with a transparent background and primary coloured border.
 * On hover, sets the background to the primary colour, and the text to its contrastText colour (see src/resources/styles/MuiTheming).
 * Can be augmented with a leading icon, and/or a trailing chevron that moves on hover.
 */
export const SecondaryButton: React.FC<IButtonProps> = ({
    text,
    size,
    icon,
    color = 'greyscale40',
    displayChevron = false,
    ...props
}) => {
    return (
        <SecondaryStyledButton
            variant="outlined"
            size={size}
            color={color}
            {...props}
        >
            {!_isNil(icon) && (
                <React.Fragment>
                    {React.cloneElement(icon, {
                        className: cx('button-icon', 'leading-icon'),
                    })}
                </React.Fragment>
            )}
            {text}
            {displayChevron && (
                <ChevronContainer>
                    <ChevronIcon />
                </ChevronContainer>
            )}
        </SecondaryStyledButton>
    );
};
