import LayoutStyles from '@Styles/layout.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox } from '@Components/FormControls';
import { ToastType } from '@Components/Toasts';
import { projectHasDatabaseState } from '@Data/solutions/Reducer';
import { SolutionKeys } from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';

interface IUseDatabaseCheckboxProps {
    settingName: string;
    onChange: (useDb: boolean, setting: string) => void;
    value: boolean;
}

export const UseDatabaseCheckbox = (props: IUseDatabaseCheckboxProps) => {
    const intl = useIntl();
    const hasDatabase = useSelector(projectHasDatabaseState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const doUse = e.target.checked;
        if (doUse && !hasDatabase) {
            createToast(
                intl.formatMessage({
                    id: SolutionKeys.DatastoreRequired,
                    defaultMessage:
                        'Add a Data Store service to this project to enable this option',
                }),
                ToastType.INFO
            );
        } else {
            props.onChange(e.target.checked, props.settingName);
        }
    };

    return (
        <Checkbox
            label={intl.formatMessage({
                id: SolutionKeys.ManageSolutionConnectToDatabase,
                defaultMessage: 'Connect to Database',
            })}
            onChange={handleChange}
            containerClassName={LayoutStyles.dialogInputFull}
            checked={props.value}
        />
    );
};
