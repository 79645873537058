import { ReactComponent as CheckIcon } from '@Icons/lg/check.svg';
import { ReactComponent as CrossIcon } from '@Icons/lg/x.svg';
import React from 'react';
import { IconButton } from '@Components/Buttons';
import { Spinner } from '@Components/LoadingIndicators';
import { ToastType } from '@Components/Toasts';
import {
    PersistentNotificationState,
    getNotificationColor,
} from '@Services/notifications';
import Styles from './Notifications.scss';

export interface IPersistentNotificationProps {
    state: PersistentNotificationState;
    type: ToastType;
    text: string;
    onRemove: () => void;
}

export const PersistentNotification = (props: IPersistentNotificationProps) => {
    let icon;
    switch (props.state) {
        case PersistentNotificationState.PENDING:
            icon = <Spinner thickness={3} />;
            break;
        case PersistentNotificationState.COMPLETED:
            icon =
                props.type === ToastType.ERROR ? <CrossIcon /> : <CheckIcon />;
            break;
    }

    return (
        <div className={Styles.persistentNotificationContainer}>
            <div className={Styles.pnInfoContainer}>
                <div
                    className={Styles.pnIconContainer}
                    style={{
                        backgroundColor: getNotificationColor(props.type),
                    }}
                >
                    {icon}
                </div>
                <div className={Styles.pnBody}>
                    <span>{props.text}</span>
                </div>
            </div>

            <div className={Styles.pnFooter}>
                <IconButton icon={<CrossIcon />} onClick={props.onRemove} />
            </div>
        </div>
    );
};
