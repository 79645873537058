import _isNil from 'lodash/isNil';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

interface IServiceCardImageContainerProps {
    className: string;
    image: React.ReactNode;
}

export const ServiceCardImageContainer = (
    props: IServiceCardImageContainerProps
) => {
    const { width, ref } = useResizeDetector<HTMLDivElement>();

    const imageContainerHeight = !_isNil(width) ? (width * 2) / 3 : 0;

    return (
        <div
            ref={ref}
            className={props.className}
            style={{ height: imageContainerHeight, width: '100%' }}
        >
            {props.image}
        </div>
    );
};
