import { ReactComponent as SearchIcon } from '@Icons/md/search.svg';
import React from 'react';
import { ITextInputProps } from './Interfaces';
import { TextInput } from './TextInput';

export const SearchInput: React.FC<ITextInputProps> = (props) => {
    return (
        <TextInput
            {...props}
            size={props.size}
            startAdornment={<SearchIcon />}
        />
    );
};
