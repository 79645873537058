import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import _isNil from 'lodash/isNil';
import { RootState } from '@Services/redux/reduxStore';
import {
    getPostgresSolution,
    getSolutionIcon,
    protectedServiceNames,
    tryFormatDisplayName,
} from './Helpers';
import { IAddServiceCatalogService, ISolution } from './Interfaces';
import { ISolutionsState, initialSolutionsState } from './State';

const SolutionsSlice = createSlice({
    name: 'Solutions',
    initialState: initialSolutionsState,
    reducers: {
        updateProjectSolutions(
            state: ISolutionsState,
            action: PayloadAction<Array<ISolution>>
        ) {
            state.projectSolutions = action.payload;
        },
        updateProjectSolutionStatus(
            state: ISolutionsState,
            action: PayloadAction<{ id: string; status: string }>
        ) {
            const foundSolutionIndex = state.projectSolutions.findIndex(
                (s) => s.id === action.payload.id
            );
            if (foundSolutionIndex > -1) {
                state.projectSolutions[foundSolutionIndex].status =
                    action.payload.status;
            }
        },
        updateServiceCatalog(
            state: ISolutionsState,
            action: PayloadAction<Array<IAddServiceCatalogService>>
        ) {
            state.serviceCatalog = action.payload;
        },
        setExtraCatalogIds(
            state: ISolutionsState,
            action: PayloadAction<{ knative: string; hasura: string }>
        ) {
            state.hasuraServiceId = action.payload.hasura;
            state.knativeServiceId = action.payload.knative;
        },
        updateSelectedProjectService(
            state: ISolutionsState,
            action: PayloadAction<ISolution>
        ) {
            state.selectedProjectService = action.payload;
        },
    },
});

export const SolutionsActions = SolutionsSlice.actions;

export const projectSolutionsState = (state: RootState) =>
    state.services.projectSolutions;
export const createServiceCatalogServicesState = (state: RootState) =>
    state.services.serviceCatalog;

export const projectHasDatabaseState = createSelector(
    projectSolutionsState,
    createServiceCatalogServicesState,
    (projectServices, serviceCatalog) => {
        if (projectServices.length === 0 || serviceCatalog.length === 0) {
            return false;
        }
        const postgresService = getPostgresSolution(serviceCatalog);

        const foundDbService = projectServices.find(
            (ps) => ps.service_catalog_id === postgresService?.id
        );

        return !_isNil(foundDbService);
    }
);

export const activeProjectSolutionsState = createSelector(
    projectSolutionsState,
    createServiceCatalogServicesState,
    (projectServices, serviceCatalog) => {
        const postgresService = getPostgresSolution(serviceCatalog);

        return projectServices
            .filter(
                (service) => service.service_catalog_id !== postgresService.id
            )
            .map((service) => getSolutionIcon(service))
            .map((service) => tryFormatDisplayName(service));
    }
);

export const knativeCatalogIdState = (state: RootState) =>
    state.services.knativeServiceId;

export const hasuraCatalogIdState = (state: RootState) =>
    state.services.hasuraServiceId;

export const selectedProjectServiceState = (state: RootState) =>
    state.services.selectedProjectService;

export const unusableServiceNamesState = createSelector(
    projectSolutionsState,
    (existingSolutions) => {
        const unusableNames = Array.isArray(existingSolutions)
            ? existingSolutions.map((s) => s.name.toLowerCase().trim())
            : [];
        unusableNames.push(...protectedServiceNames);
        return unusableNames;
    }
);

export default SolutionsSlice.reducer;
