import ColorStyles from '@Styles/colors.scss';
import { FormLabelProps } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';

const StyledLabel = styled('label')(
    () => `
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${ColorStyles.greyscale70};
`
);

const Required = styled('span')`
    color: ${ColorStyles.suntan};
    margin-left: 4px;
`;

export interface ILabelProps extends FormLabelProps {
    disableMargin?: boolean;
}

export const Label = (props: ILabelProps) => {
    const { disableMargin, children, required, ...labelProps } = props;

    return (
        <div style={{ marginBottom: disableMargin ? 0 : '8px' }}>
            <StyledLabel {...labelProps}>{children}</StyledLabel>
            {required && <Required>*</Required>}
        </div>
    );
};
