import theme from '@Styles/MuiTheming';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BodyBreadcrumbsTitle } from '@Components/Breadcrumbs';
import { ToastType } from '@Components/Toasts';
import { selectedOrganizationState } from '@Data/organizations';
import { IProject, ProjectsApi, selectedProjectState } from '@Data/projects';
import { SolutionsApi } from '@Data/solutions';
import { ISolution } from '@Data/solutions/Interfaces';
import { WorkspacesApi, selectedWorkspaceState } from '@Data/workspaces';
import { toTitleCase } from '@Services/helpers/Strings';
import { EntityKeys, FormKeys } from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';
import Styles from './Overview.scss';
import ProjectTitle from './ProjectTitle/ProjectTitle';
import { AddSolutionDialog } from './components/AddSolutionDialog/AddSolutionDialog';
import { ProjectSolutionsList } from './components/ProjectSolutionsList/ProjectSolutionsList';

let isLoaded = false;

const ProjectOverviewView = () => {
    const intl = useIntl();
    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);
    const selectedProject = useSelector(selectedProjectState);
    const navigate = useNavigate();

    const [isServiceCatalogOpen, setIsServiceCatalogOpen] =
        useState<boolean>(false);

    useEffect(() => {
        if (!isLoaded) {
            isLoaded = true;
            SolutionsApi.getServiceCatalog();
        }
    }, [isLoaded]);

    const handleCloseServiceCatalog = async (): Promise<void> => {
        setIsServiceCatalogOpen(false);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, theme.transitions.duration.standard);
        });
    };

    const solutionStr = toTitleCase(
        intl.formatMessage({
            id: EntityKeys.Solution,
            defaultMessage: 'solution',
        })
    );

    const handleDeleteSolution = async (solution: ISolution) => {
        const wasSuccessful = await SolutionsApi.deleteSolution(solution);
        if (wasSuccessful) {
            await SolutionsApi.getProjectSolutions(selectedProject.id);
            createToast(
                intl.formatMessage(
                    {
                        id: FormKeys.ObjectDeleted,
                        defaultMessage: 'Solution deleted',
                    },
                    { object: solutionStr }
                ),
                ToastType.SUCCESS
            );
        }
    };

    const handleDeleteProject = async () => {
        const wasSuccessful = await ProjectsApi.deleteProject(selectedProject);
        if (wasSuccessful) {
            await WorkspacesApi.loadWorkspaces(selectedOrganization.id);
            await ProjectsApi.loadProjects(selectedWorkspace.id);
            navigate('/');
            createToast(
                intl.formatMessage(
                    {
                        id: FormKeys.ObjectDeleted,
                        defaultMessage: 'Project deleted',
                    },
                    {
                        object: toTitleCase(
                            intl.formatMessage({
                                id: EntityKeys.Project,
                                defaultMessage: 'project',
                            })
                        ),
                    }
                ),
                ToastType.SUCCESS
            );
        }
    };

    return (
        <React.Fragment>
            <div className={Styles.overviewContainer}>
                <div className={Styles.titleContainer}>
                    <BodyBreadcrumbsTitle
                        isLoading={
                            _isNil(selectedOrganization) ||
                            _isNil(selectedWorkspace || _isNil(selectedProject))
                        }
                        selectedOrganizationId={selectedOrganization?.id}
                        selectedProject={selectedProject}
                        selectedWorkspace={selectedWorkspace}
                    />
                    <ProjectTitle
                        project={selectedProject}
                        onDelete={handleDeleteProject}
                    />
                </div>

                <div className={Styles.titleDivider} />
            </div>

            <div className={Styles.dataDisplayContainer}>
                <ProjectSolutionsList
                    projectId={selectedProject?.id}
                    onAddService={() => setIsServiceCatalogOpen(true)}
                    onDeleteSolution={handleDeleteSolution}
                />
            </div>
            <AddSolutionDialog
                isOpen={isServiceCatalogOpen}
                onClose={handleCloseServiceCatalog}
                selectedProject={selectedProject}
                selectedOrganization={selectedOrganization}
                selectedWorkspace={selectedWorkspace}
            />
        </React.Fragment>
    );
};

export default ProjectOverviewView;
