import { api } from '@orbica/platform-sdk-dev';
import { ISettingsUser } from '@Components/Settings';
import { SortOrder } from '@Services/helpers/Sorting';
import { IProject, ProjectDisplayType, ProjectSortType } from './Interfaces';

export interface IProjectsState {
    projects: Array<IProject>;
    selectedProject: IProject | null;
    projectDisplayType: ProjectDisplayType;
    sortType: ProjectSortType;
    sortOrder: SortOrder;
    selectedProjectUsers: Array<ISettingsUser>;
    roles: api.IRole[];
}

export const initialProjectsState: IProjectsState = {
    projects: [],
    selectedProject: null,
    projectDisplayType: ProjectDisplayType.CARD,
    sortType: ProjectSortType.UPDATED,
    sortOrder: SortOrder.DESCENDING,
    selectedProjectUsers: [],
    roles: [],
};
