import LayoutStyles from '@Styles/layout.scss';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { InputSize, TextInput } from '@Components/Inputs';
import { LabelWithIconTooltip } from '@Components/Label';
import { FormKeys } from '@Services/i18n/keys';

interface IPasswordInputProps {
    settingName: string;
    onChange: (password: string, setting: string) => void;
    value: string;
    label?: string;
    solutionName: string;
}

export const PasswordInput = (props: IPasswordInputProps) => {
    const intl = useIntl();

    const { label, ...passwordProps } = props;
    const labelValue = !_isNil(label)
        ? label
        : intl.formatMessage({
              id: FormKeys.Password,
              defaultMessage: 'Password',
          });

    const [password1, setPassword1] = useState<string>(passwordProps.value);
    const [password2, setPassword2] = useState<string>(passwordProps.value);

    const passwordsHaveValues = password1 !== '' && password2 !== '';
    const arePasswordsAligned = passwordsHaveValues && password1 === password2;

    useEffect(() => {
        if (passwordsHaveValues && arePasswordsAligned) {
            passwordProps.onChange(password1, passwordProps.settingName);
        } else {
            passwordProps.onChange('', passwordProps.settingName);
        }
    }, [passwordsHaveValues, arePasswordsAligned]);

    return (
        <>
            <div className={LayoutStyles.dialogInputMedium}>
                <LabelWithIconTooltip
                    label={`Create ${labelValue}`}
                    tooltipTitle={`Enter a password to secure access to your ${props.solutionName} solution`}
                    required
                />
                <TextInput
                    size={InputSize.LARGE}
                    value={password1}
                    containerClassName={LayoutStyles.dialogInputMedium}
                    required
                    onChange={(e) => setPassword1(e.target.value)}
                    type="password"
                    error={passwordsHaveValues && !arePasswordsAligned}
                />
            </div>

            <TextInput
                size={InputSize.LARGE}
                value={password2}
                label={intl.formatMessage(
                    {
                        id: FormKeys.ReEnterPassword,
                        defaultMessage: 'Re-enter Password',
                    },
                    { password: labelValue }
                )}
                containerClassName={LayoutStyles.dialogInputMedium}
                required
                onChange={(e) => setPassword2(e.target.value)}
                type="password"
                error={passwordsHaveValues && !arePasswordsAligned}
                helperText={intl.formatMessage({
                    id: FormKeys.PasswordsDoNotMatch,
                    defaultMessage: 'Passwords do not match',
                })}
            />
        </>
    );
};
