import { ReactComponent as GridIcon } from '@Icons/lg/grid.svg';
import { ReactComponent as ListIcon } from '@Icons/lg/list-left.svg';
import React from 'react';
import { useIntl } from 'react-intl';
import { IconButton } from '@Components/Buttons';
import ProjectsApi from '@Data/projects/Api';
import { ProjectDisplayType } from '@Data/projects/Interfaces';
import { SortingKeys, ViewKeys } from '@Services/i18n/keys';
import Styles from './Sorting.scss';

interface IViewTypeSelectorProps {
    selectedDisplayType: ProjectDisplayType;
}

const ViewTypeSelector = (props: IViewTypeSelectorProps) => {
    const intl = useIntl();

    const handleSelect = (type: ProjectDisplayType) => {
        ProjectsApi.setProjectDisplayType(type);
    };

    const projectsStr = intl
        .formatMessage({ id: ViewKeys.Projects, defaultMessage: 'projects' })
        .toLowerCase();

    return (
        <div className={Styles.viewTypeContainer}>
            <IconButton
                onClick={() => handleSelect(ProjectDisplayType.CARD)}
                icon={<GridIcon />}
                tooltip={intl.formatMessage(
                    {
                        id: SortingKeys.ViewAsCards,
                        defaultMessage: 'View projects as Cards',
                    },
                    { items: projectsStr }
                )}
                className={
                    props.selectedDisplayType === ProjectDisplayType.CARD
                        ? Styles.selectedViewTypeIcon
                        : null
                }
            />

            <IconButton
                onClick={() => handleSelect(ProjectDisplayType.LIST)}
                icon={<ListIcon />}
                tooltip={intl.formatMessage(
                    {
                        id: SortingKeys.ViewAsList,
                        defaultMessage: 'View projects as a List',
                    },
                    { items: projectsStr }
                )}
                className={
                    props.selectedDisplayType === ProjectDisplayType.LIST
                        ? Styles.selectedViewTypeIcon
                        : null
                }
            />
        </div>
    );
};

export default ViewTypeSelector;
