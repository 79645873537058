import Auth from './Auth';
import Entities from './Entities';
import Forms from './Forms';
import I18n from './I18n';
import Notifications from './Notifications';
import Organizations from './Organizations';
import Projects from './Projects';
import Settings from './Settings';
import Solutions from './Solutions';
import Sorting from './Sorting';
import Views from './Views';
import Workspaces from './Workspaces';

export {
    Auth as AuthKeys,
    Entities as EntityKeys,
    I18n as I18nKeys,
    Solutions as SolutionKeys,
    Workspaces as WorkspaceKeys,
    Views as ViewKeys,
    Sorting as SortingKeys,
    Forms as FormKeys,
    Organizations as OrganizationKeys,
    Projects as ProjectKeys,
    Notifications as NotificationKeys,
    Settings as SettingsKeys,
};
