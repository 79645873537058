const Organizations = {
    Personal: 'organizations.personal',
    CreateAWorkspace: 'organizations.createAWorkspace',
    NoWorkspacesUser: 'organizations.noWorkspacesUser',
    AddUsers: 'organizations.addUsers',
    AddUser: 'organizations.addUser',
    Email: 'organizations.email',
    EmailPlaceholder: 'organizations.emailPlaceholder',
    InviteByEmails: 'organizations.inviteByEmails',
    AddUsersFromAD: 'organizations.addUsersFromAD',
    AddUsersFromADInstruction: 'organizations.addUsersFromADInstruction',
    SetOrganizationRole: 'organizations.setOrganizationRole',
    SetAsOrganizationAdmin: 'organizations.SetAsOrganizationAdmin',
    SetAsOrganizationMember: 'organizations.SetAsOrganizationMember',
    OrganizationName: 'organizations.organizationName',
    UserAdded: 'organizations.userAdded',
};

export default Organizations;
