import { ReactComponent as UserPlusIcon } from '@Icons/lg/user-plus.svg';
import GridStyles from '@Styles/grid.scss';
import { api } from '@orbica/platform-sdk-dev';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@Components/Buttons';
import { Header, HeaderType } from '@Components/Header';
import { TextInput } from '@Components/Inputs';
import { InputSize } from '@Components/Inputs/Interfaces';
import { Label } from '@Components/Label';
import { LabelWithIconButton } from '@Components/Label/LabelWithIconButton';
import { MainContainer, Page, PageContent } from '@Components/Page';
import {
    ManageUserRoleDialog,
    UserRoleSettingsTable,
} from '@Components/Settings';
import { ISettingsUser, getFullName } from '@Components/Settings/Interfaces';
import { ToastType } from '@Components/Toasts';
import { canCurrentUserEditOrgState } from '@Data/auth';
import {
    OrganizationsApi,
    selectedOrganizationState,
    selectedOrganizationUsersState,
} from '@Data/organizations';
import { organizationRolesState } from '@Data/organizations/Reducer';
import {
    EntityKeys,
    FormKeys,
    OrganizationKeys,
    SettingsKeys,
} from '@Services/i18n/keys';
import { createToast } from '@Services/notifications';
import AddUserWithRoleDialog from '../OrganizationSettingsView/components/AddUserWithRoleDialog/AddUserWithRoleDialog';
import SettingsStyles from '../Settings.scss';

const OrganizationSettingsView = () => {
    const intl = useIntl();
    const params = useParams();

    const canCurrentUserEditOrg = useSelector(canCurrentUserEditOrgState);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const organizationId = params.organizationId;
    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedOrganizationUsers = useSelector(
        selectedOrganizationUsersState
    );
    const availableRoles = useSelector(organizationRolesState);

    const [isSubmittingOrganizationName, setIsSubmittingOrganizationName] =
        useState<boolean>(false);
    const [isEditingOrganizationName, setIsEditingOrganizationName] =
        useState<boolean>(false);
    const [isAddUserDialogOpen, setIsAddUserDialogOpen] =
        useState<boolean>(false);
    const [
        isSetOrganizationRoleDialogOpen,
        setIsSetOrganizationRoleDialogOpen,
    ] = useState<boolean>(false);

    const [editOrgName, setEditOrgName] = useState<string>('');
    const [editUser, setEditUser] = useState<ISettingsUser | null>(null);

    const settingsUsers =
        _isNil(selectedOrganizationUsers) || availableRoles.length === 0
            ? []
            : selectedOrganizationUsers.map((user) => {
                  const role = availableRoles.find((r) => r.name === user.role);
                  return {
                      id: user.id,
                      email: user.email,
                      firstName: user.first_name,
                      lastName: user.last_name,
                      role: role,
                  };
              });

    useEffect(() => {
        if (selectedOrganization) {
            setEditOrgName(selectedOrganization.name);
        }
    }, [selectedOrganization]);

    useEffect(() => {
        const getSelectedOrganization = async () => {
            await OrganizationsApi.loadOrganizationById(organizationId);
        };

        if (
            !_isNil(organizationId) &&
            (_isNil(selectedOrganization) ||
                selectedOrganization.id !== organizationId)
        ) {
            setIsLoading(true);
            getSelectedOrganization();
        }
    }, [selectedOrganization, organizationId]);

    useEffect(() => {
        if (
            !isLoading &&
            !_isNil(selectedOrganization) &&
            selectedOrganization.id === organizationId &&
            !canCurrentUserEditOrg
        ) {
            navigate('/');
        }
    }, [
        selectedOrganization,
        organizationId,
        canCurrentUserEditOrg,
        isLoading,
    ]);

    useEffect(() => {
        const getOrgUsers = async () => {
            setIsLoading(true);
            await OrganizationsApi.getOrganizationRoles();
            await getSelectedOrganizationUsers();
            setIsLoading(false);
        };

        if (!_isNil(selectedOrganization)) {
            getOrgUsers();
        }
    }, [selectedOrganization]);

    const getSelectedOrganizationUsers = async () => {
        await OrganizationsApi.getOrganizationUsers(selectedOrganization.id);
    };

    const onChangeEditOrgName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditOrgName(e.target.value);
    };

    const handleAddUsers = () => {
        setIsAddUserDialogOpen(true);
    };

    const handleOpenUserRoleDialog = (user: ISettingsUser) => {
        setEditUser(user);
        setIsSetOrganizationRoleDialogOpen(true);
    };

    const handleAddUserDialogClose = () => {
        setIsAddUserDialogOpen(false);
    };

    const handleSetOrganizationRoleDialogClose = () => {
        setIsSetOrganizationRoleDialogOpen(false);
    };

    const handleUpdateRole = async (user: ISettingsUser, role: api.IRole) => {
        const wasSuccessful = await OrganizationsApi.updateOrganizationUserRole(
            selectedOrganization,
            user.id,
            role
        );

        if (wasSuccessful) {
            const toastMsg = intl.formatMessage(
                {
                    id: FormKeys.ObjectUpdated,
                    defaultMessage: 'Role updated',
                },
                { object: 'Role' }
            );
            createToast(toastMsg, ToastType.SUCCESS);
            await getSelectedOrganizationUsers();
        }
    };

    return (
        <React.Fragment>
            <Page>
                <Header
                    headerType={HeaderType.SETTINGS}
                    isLoading={isLoading || isSubmittingOrganizationName}
                />
                <PageContent>
                    <MainContainer
                        gridClassName={cx(
                            GridStyles.alignContentStart,
                            SettingsStyles.settingsGrid
                        )}
                        id="organization-settings"
                    >
                        <div className={SettingsStyles.titleContainer}>
                            <div className={SettingsStyles.title}>
                                {intl.formatMessage({
                                    id: SettingsKeys.OrganizationSettings,
                                    defaultMessage: 'Organization Settings',
                                })}
                            </div>
                        </div>
                        <div className={SettingsStyles.divider} />
                        <div className={SettingsStyles.entityNameContainer}>
                            <Label>
                                {intl.formatMessage({
                                    id: OrganizationKeys.OrganizationName,
                                    defaultMessage: 'Organization Name',
                                })}
                            </Label>
                            <TextInput
                                containerStyle={{
                                    width: '50%',
                                    minWidth: '300px',
                                }}
                                size={InputSize.LARGE}
                                value={editOrgName}
                                disabled={
                                    !isEditingOrganizationName ||
                                    isSubmittingOrganizationName
                                }
                                onChange={onChangeEditOrgName}
                            />
                        </div>

                        <div className={SettingsStyles.tableContainer}>
                            <LabelWithIconButton
                                label={intl.formatMessage({
                                    id: SettingsKeys.Users,
                                    defaultMessage: 'Users',
                                })}
                                iconButton={
                                    <IconButton
                                        icon={<UserPlusIcon />}
                                        className={SettingsStyles.addUserIcon}
                                        onClick={handleAddUsers}
                                        tooltip={intl.formatMessage({
                                            id: SettingsKeys.AddUser,
                                            defaultMessage: 'Add User',
                                        })}
                                        tooltipProps={{
                                            placement: 'right',
                                        }}
                                    />
                                }
                            />
                            <UserRoleSettingsTable
                                users={settingsUsers}
                                isLoading={isLoading}
                                onEditUserRole={handleOpenUserRoleDialog}
                                entityName={intl.formatMessage({
                                    id: EntityKeys.Organization,
                                    defaultMessage: 'Organization',
                                })}
                            />
                        </div>
                    </MainContainer>
                </PageContent>
            </Page>
            <AddUserWithRoleDialog
                isOpen={isAddUserDialogOpen}
                onClose={handleAddUserDialogClose}
                getSelectedOrganizationUsers={getSelectedOrganizationUsers}
            />
            <ManageUserRoleDialog
                title={intl.formatMessage(
                    {
                        id: OrganizationKeys.SetOrganizationRole,
                        defaultMessage: `Set Organization Role - ${getFullName(
                            editUser
                        )}`,
                    },
                    {
                        userName: editUser ? getFullName(editUser) : '',
                    }
                )}
                isOpen={isSetOrganizationRoleDialogOpen}
                onClose={handleSetOrganizationRoleDialogClose}
                user={editUser}
                availableRoles={availableRoles}
                onUpdateRole={handleUpdateRole}
            />
        </React.Fragment>
    );
};

export default OrganizationSettingsView;
