import '@Styles/styles.css';
import posthog from 'posthog-js';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthApi, hasUserState, haveRolesLoadedState } from '@Data/auth';
import RequireAuth from '@Services/authentication/RequireAuth';
import LocalizationProvider from '@Services/i18n/LocalizationProvider';
import { Routes as AppRoutes } from '@Services/navigation';
import { OrbicaSdkClient } from '@Services/sdk/OrbicaSdkClient';
import { PageNotFoundView } from '@Views/404/404';

const App = () => {
    const auth = useAuth();
    const hasUserData = useSelector(hasUserState);
    const haveRolesLoaded = useSelector(haveRolesLoadedState);

    useEffect(() => {
        if (!auth.isLoading && auth.user && auth.isAuthenticated) {
            OrbicaSdkClient.instance.setUser(auth.user);
            posthog?.identify(auth.user.profile.email.toLowerCase());
        }
    }, [auth.user, auth.isLoading, auth.isAuthenticated, posthog]);

    useEffect(() => {
        if (auth.isAuthenticated && hasUserData && !haveRolesLoaded) {
            AuthApi.getCurrentUserRoles(auth.user.profile.sub);
        }
    }, [auth.isAuthenticated, hasUserData, haveRolesLoaded, auth.user]);

    return (
        <LocalizationProvider>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                transition={Slide}
                limit={1}
                icon={false}
                closeButton={null}
                style={{
                    width: '100vw',
                    top: 0,
                    padding: 0,
                }}
            />
            <BrowserRouter>
                <Routes>
                    <Route
                        path={AppRoutes.login.href}
                        element={AppRoutes.login.component}
                    />
                    <Route
                        path={AppRoutes.workspaces.href}
                        element={
                            <RequireAuth>
                                {AppRoutes.workspaces.component}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={AppRoutes.project.root.href}
                        element={
                            <RequireAuth>
                                {AppRoutes.project.root.component}
                            </RequireAuth>
                        }
                    >
                        <Route
                            path={AppRoutes.project.overview.href}
                            element={AppRoutes.project.overview.component}
                        />
                        <Route
                            path={AppRoutes.project.data.href}
                            element={AppRoutes.project.data.component}
                        />
                    </Route>
                    <Route
                        path={AppRoutes.settings.organizationSettings.href}
                        element={
                            <RequireAuth>
                                {
                                    AppRoutes.settings.organizationSettings
                                        .component
                                }
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={AppRoutes.settings.workspaceSettings.href}
                        element={
                            <RequireAuth>
                                {AppRoutes.settings.workspaceSettings.component}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={AppRoutes.settings.projectSettings.href}
                        element={
                            <RequireAuth>
                                {AppRoutes.settings.projectSettings.component}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={AppRoutes.temporaryProjectService.href}
                        element={
                            <RequireAuth>
                                {AppRoutes.temporaryProjectService.component}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'*'}
                        element={
                            <RequireAuth>
                                <PageNotFoundView />
                            </RequireAuth>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </LocalizationProvider>
    );
};

export default App;
