import * as React from 'react';
import { ProjectSettingsView } from '@Views/Settings/ProjectSettingsView/ProjectSettingsView';
import { WorkspaceSettingsView } from '@Views/Settings/WorkspaceSettingsView/WorkspaceSettingsView';
import {
    LoginView,
    OrganizationSettingsView,
    ProjectDataView,
    ProjectOverviewView,
    ProjectView,
    TemporaryProjectServiceView,
    WorkspacesView,
} from '@Views/index';
import { View } from './Views';

const Routes = {
    login: {
        href: '/login',
        view: View.LOGIN,
        component: <LoginView />,
        getUrl: () => Routes.login.href,
    },
    workspaces: {
        href: '/:organizationId?/:workspaceId?',
        view: View.WORKSPACES,
        component: <WorkspacesView />,
        getUrl: (organizationId?: string, workspaceId?: string) => {
            if (organizationId && workspaceId) {
                return Routes.workspaces.href
                    .replace(':organizationId?', organizationId)
                    .replace(':workspaceId?', workspaceId);
            } else if (organizationId && !workspaceId) {
                return Routes.workspaces.href
                    .replace(':organizationId?', organizationId)
                    .replace('/:workspaceId?', '');
            } else {
                return '/';
            }
        },
    },
    project: {
        root: {
            href: '/project/:organizationId/:workspaceId/:projectId',
            component: <ProjectView />,
            getUrl: (
                organizationId: string,
                workspaceId: string,
                projectId: string
            ) => {
                return Routes.project.root.href
                    .replace(':organizationId', organizationId)
                    .replace(':workspaceId', workspaceId)
                    .replace(':projectId', projectId);
            },
        },
        overview: {
            href: 'overview',
            view: View.PROJECT_OVERVIEW,
            component: <ProjectOverviewView />,
            getUrl: (
                organizationId: string,
                workspaceId: string,
                projectId: string
            ) =>
                `${Routes.project.root.getUrl(
                    organizationId,
                    workspaceId,
                    projectId
                )}/overview`,
            getRelativeUrl: () => '../overview',
        },
        data: {
            href: 'data',
            view: View.PROJECT_DATA,
            component: <ProjectDataView />,
            getUrl: (
                organizationId: string,
                workspaceId: string,
                projectId: string
            ) =>
                `${Routes.project.root.getUrl(
                    organizationId,
                    workspaceId,
                    projectId
                )}/data`,
            getRelativeUrl: () => '../data',
        },
    },
    settings: {
        organizationSettings: {
            href: '/settings/organization/:organizationId',
            view: View.ORGANIZATION_SETTINGS,
            component: <OrganizationSettingsView />,
            getUrl: (organizationId: string) => {
                return Routes.settings.organizationSettings.href.replace(
                    ':organizationId',
                    organizationId
                );
            },
        },
        workspaceSettings: {
            href: '/settings/workspace/:organizationId/:workspaceId',
            view: View.WORKSPACE_SETTINGS,
            component: <WorkspaceSettingsView />,
            getUrl: (organizationId: string, workspaceId: string) => {
                return Routes.settings.workspaceSettings.href
                    .replace(':organizationId', organizationId)
                    .replace(':workspaceId', workspaceId);
            },
        },
        projectSettings: {
            href: '/settings/project/:organizationId/:workspaceId/:projectId',
            view: View.PROJECT_SETTINGS,
            component: <ProjectSettingsView />,
            getUrl: (
                organizationId: string,
                workspaceId: string,
                projectId: string
            ) => {
                return Routes.settings.projectSettings.href
                    .replace(':organizationId', organizationId)
                    .replace(':workspaceId', workspaceId)
                    .replace(':projectId', projectId);
            },
        },
    },

    temporaryProjectService: {
        href: '/project/:organizationId/:workspaceId/:projectId/service/:serviceId',
        component: <TemporaryProjectServiceView />,
        getUrl: (
            organizationId: string,
            workspaceId: string,
            projectId: string,
            serviceId: string
        ) => {
            return Routes.temporaryProjectService.href
                .replace(':organizationId', organizationId)
                .replace(':workspaceId', workspaceId)
                .replace(':projectId', projectId)
                .replace(':serviceId', serviceId);
        },
    },
};

export default Routes;
