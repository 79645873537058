const Settings = {
    OrganizationSettings: 'settings.orgSettings',
    Name: 'settings.name',
    Email: 'settings.email',
    Role: 'settings.role',
    Users: 'settings.users',
    UpdateRole: 'settings.updateRole',
    UpdatingRole: 'settings.updatingRole',
    WorkspaceSettings: 'settings.workspaceSettings',
    ProjectSettings: 'settings.projectStettings',
    AddUser: 'settings.addUser',
    AddUsers: 'settings.addUsers',
    AddUsersToEntity: 'forms.addUsersToEntity',
    AddingUsers: 'settings.addingUsers',
    AddingUser: 'settings.addingUser',
    NoUsersAddedToYourEntity: 'settings.noUsersAddedToEntity',
    RemoveUser: 'settings.removeUser',
    RemoveUserFromEntity: 'settings.removeUserFromEntity',
    RemovingUser: 'settings.removingUser',
    UserRemoved: 'settings.userRemoved',
};

export default Settings;
