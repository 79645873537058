import { api as SdkApi } from '@orbica/platform-sdk-dev';
import { PlatformConfig } from '@orbica/platform-sdk-dev/dist/src/interfaces/orbica.interfaces';
import { User } from 'oidc-client-ts';
import AuthApi from '@Data/auth/Api';

const singleton = Symbol();
const singletonEnforcer = Symbol();

export class OrbicaSdkClient {
    private user: User;

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error("can't construct singleton");
        }
    }

    static get instance(): OrbicaSdkClient {
        if (!this[singleton]) {
            this[singleton] = new OrbicaSdkClient(singletonEnforcer);
        }
        return this[singleton];
    }

    setUser(user: User) {
        this.user = user;
        AuthApi.setHasUser();
        return this;
    }

    private getSdkConfig(): PlatformConfig {
        if (process.env.GRAPHQL_URL && this.user) {
            return {
                apiUrl: process.env.GRAPHQL_URL,
                username: 'hasura-console',
                accessToken: this.user.access_token,
                autoLogin: true,
            };
        } else {
            throw new Error('there is no user data');
        }
    }

    async getServiceApi(): Promise<SdkApi.ServiceApi> {
        const api = new SdkApi.ServiceApi(this.getSdkConfig());
        return new Promise((resolve, reject) => {
            api.on('ready', () => {
                resolve(api);
            });
            api.on('error', reject);
        });
    }

    async getOrganizationApi(): Promise<SdkApi.OrganizationApi> {
        const api = new SdkApi.OrganizationApi(this.getSdkConfig());
        return new Promise((resolve, reject) => {
            api.on('ready', () => {
                resolve(api);
            });
            api.on('error', reject);
        });
    }

    async getProjectApi(): Promise<SdkApi.ProjectApi> {
        const api = new SdkApi.ProjectApi(this.getSdkConfig());
        return new Promise((resolve, reject) => {
            api.on('ready', () => {
                resolve(api);
            });
            api.on('error', reject);
        });
    }

    async getWorkspaceApi(): Promise<SdkApi.WorkspaceApi> {
        const api = new SdkApi.WorkspaceApi(this.getSdkConfig());
        return new Promise((resolve, reject) => {
            api.on('ready', () => {
                resolve(api);
            });
            api.on('error', reject);
        });
    }

    async getUserApi(): Promise<SdkApi.UserApi> {
        const api = new SdkApi.UserApi(this.getSdkConfig());
        return new Promise((resolve, reject) => {
            api.on('ready', () => {
                resolve(api);
            });
            api.on('error', reject);
        });
    }

    async getServiceCatalogApi(): Promise<SdkApi.ServiceCatalogApi> {
        const api = new SdkApi.ServiceCatalogApi(this.getSdkConfig());
        return new Promise((resolve, reject) => {
            api.on('ready', () => {
                resolve(api);
            });
            api.on('error', reject);
        });
    }

    async getClustersApi(): Promise<SdkApi.ClusterApi> {
        const api = new SdkApi.ClusterApi(this.getSdkConfig());
        return new Promise((resolve, reject) => {
            api.on('ready', () => {
                resolve(api);
            });
            api.on('error', reject);
        });
    }
}
