import ColorStyles from '@Styles/colors.scss';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { styled } from '@mui/system';
import cx from 'classnames';
import _isNil from 'lodash/isNil';
import React from 'react';
import { InputSize } from '@Components/Inputs';
import { FormControlLabel } from './FormControlLabel';
import Styles from './FormControls.scss';
import { ICheckboxProps } from './Interfaces';

const CheckboxIcon = styled('span')({
    borderRadius: 2,
    width: 18,
    height: 18,
    border: `1px solid ${ColorStyles.greyscale40}`,
});

const StyledCheckbox = styled(MuiCheckbox)`
    height: 18px;
    width: 18px;
    padding: 0px;
`;

export const Checkbox = (props: ICheckboxProps) => {
    const {
        label,
        id,
        onClick,
        labelStyle,
        containerStyle,
        containerClassName,
        ...checkboxProps
    } = props;
    const cbId = !_isNil(id) ? id : Math.random().toString(16);

    return (
        <div
            className={cx(Styles.formControlsContainer, containerClassName)}
            style={containerStyle}
        >
            <StyledCheckbox
                disableRipple
                color="matcha"
                icon={<CheckboxIcon />}
                inputProps={{
                    'aria-label': cbId,
                }}
                value={label}
                onClick={onClick}
                id={cbId}
                {...checkboxProps}
            />
            {!_isNil(label) && (
                <FormControlLabel
                    htmlFor={cbId}
                    focused={props.checked}
                    value={props.label}
                    size={InputSize.MEDIUM}
                    onClick={onClick}
                    style={{
                        marginLeft: '12px',
                        ...(labelStyle ?? {}),
                    }}
                />
            )}
        </div>
    );
};
