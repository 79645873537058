import ColorsStyles from '@Styles/colors.scss';
import { Menu } from '@mui/material';
import _isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dialog, DialogFooter, DialogHeader } from '@Components/Dialog';
import { MenuItem } from '@Components/Menu';
import { canCurrentUserEditWorkspaceState } from '@Data/auth';
import { EntityKeys, FormKeys } from '@Services/i18n/keys';

export interface IProjectCardMenuProps {
    onEdit: () => void;
    onDelete: () => void;
    onClose: () => void;
    anchorEl?: HTMLElement;
    projectName?: string;
}

export const ProjectCardMenu = (props: IProjectCardMenuProps) => {
    const intl = useIntl();
    const canUserEditProject = useSelector(canCurrentUserEditWorkspaceState);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleEdit = () => {
        props.onEdit();
        props.onClose();
    };

    const handleDelete = async () => {
        setIsSubmitting(true);
        await props.onDelete();
        setIsDeleteDialogOpen(false);
        setIsSubmitting(false);
    };

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
        props.onClose();
    };

    const projectStr = intl.formatMessage({
        id: EntityKeys.Project,
        defaultMessage: 'project',
    });

    return (
        <React.Fragment>
            {canUserEditProject && (
                <Menu
                    open={!_isNil(props.anchorEl)}
                    anchorEl={props.anchorEl}
                    onClose={props.onClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {/* <MenuItem onClick={handleEdit}>{intl.formatMessage({ id: FormKeys.EditObject, defaultMessage: "Edit project" }, { object: projectStr })}</MenuItem>
                <Divider /> */}
                    <MenuItem
                        onClick={openDeleteDialog}
                        color={ColorsStyles.red}
                    >
                        {intl.formatMessage(
                            {
                                id: FormKeys.DeleteObject,
                                defaultMessage: 'Delete project',
                            },
                            { object: projectStr }
                        )}
                    </MenuItem>
                </Menu>
            )}
            <Dialog open={isDeleteDialogOpen}>
                <DialogHeader
                    onClose={() => setIsDeleteDialogOpen(false)}
                    title={intl.formatMessage(
                        {
                            id: FormKeys.ConfirmDelete,
                            defaultMessage: `Are you sure you want to delete ${props.projectName}?`,
                        },
                        {
                            objectName: props.projectName
                                ? props.projectName
                                : '',
                        }
                    )}
                />
                <DialogFooter
                    onCancel={() => setIsDeleteDialogOpen(false)}
                    submitText={intl.formatMessage({
                        id: FormKeys.Delete,
                        defaultMessage: 'Delete',
                    })}
                    onSubmit={handleDelete}
                    isSubmitting={isSubmitting}
                    submittingText={intl.formatMessage(
                        {
                            id: FormKeys.DeletingObject,
                            defaultMessage: 'Deleting project',
                        },
                        { object: projectStr }
                    )}
                />
            </Dialog>
        </React.Fragment>
    );
};
