import Flex from '@Styles/flex.scss';
import TypographyStyles from '@Styles/typography.scss';
import { ButtonUnstyled } from '@mui/base';
import cx from 'classnames';
import React from 'react';
import { ButtonRouterProps, getRoleProps } from '@Components/MuiRouterLink';
import { IWorkspace } from '@Data/workspaces';
import Styles from './Workspaces.scss';

/**
 * @noInheritDoc
 */
export interface IWorkspaceButtonProps
    extends ButtonRouterProps,
        React.HTMLAttributes<HTMLButtonElement> {
    workspace: IWorkspace;
    isSelected: boolean;
    displayTail?: boolean;
}

export const WorkspaceButton: React.FC<IWorkspaceButtonProps> = (props) => {
    const {
        href,
        onClick,
        relative,
        workspace,
        isSelected,
        className,
        displayTail,
        ...buttonProps
    } = props;

    const roleProps = getRoleProps(onClick, href, relative);

    const btn = (
        <ButtonUnstyled
            {...roleProps}
            className={cx(
                Styles.workspaceBtn,
                isSelected ? Styles.selectedWorkspaceBtn : null,
                displayTail ? Styles.workspaceBtnTail : null,
                className
            )}
            {...buttonProps}
        >
            <div
                className={cx(Flex.flex, Flex.alignItemsCenter)}
                data-cy={
                    isSelected
                        ? 'workspace-button-selected'
                        : 'workspace-button'
                }
            >
                <div className={cx(Styles.colorIconContainer)}>
                    <div
                        className={Styles.colorIcon}
                        style={{ backgroundColor: workspace.color }}
                    />
                </div>

                <span
                    className={cx(TypographyStyles.bodyNormal, Styles.wspName)}
                >
                    {workspace.display_name}
                </span>
            </div>
            <span className={TypographyStyles.bodyNormal}>
                {workspace.projectCount}
            </span>
        </ButtonUnstyled>
    );

    return !displayTail ? (
        btn
    ) : (
        <div className={Styles.btnTailContainer}>
            {btn}
            <div className={Styles.tail} />
        </div>
    );
};
