import logo from '@Images/wordmark-logo.png';
import { ButtonUnstyled } from '@mui/base';
import cx from 'classnames';
import React from 'react';
import { ButtonRouterProps, getRoleProps } from '@Components/MuiRouterLink';
import Styles from './LogoIcon.scss';

/**
 * @noInheritDoc
 */
export interface ILogoIconProps
    extends ButtonRouterProps,
        React.HTMLAttributes<HTMLButtonElement> {
    size?: string;
}

export const LogoIcon = (props: ILogoIconProps) => {
    const { size, href, onClick, relative, className, ...buttonProps } = props;

    const logoClass = size && size === 'small' ? Styles.logoSmall : Styles.logo;

    const roleClasses = getRoleProps(onClick, href, relative);

    return (
        <ButtonUnstyled
            className={cx(Styles.iconContainer, className)}
            {...roleClasses}
            {...buttonProps}
        >
            <img src={logo} className={logoClass} />
        </ButtonUnstyled>
    );
};
