import _isNil from 'lodash/isNil';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingLogo } from '@Components/LoadingIndicators';
import { Page } from '@Components/Page';
import { ToastType } from '@Components/Toasts';
import {
    OrganizationsApi,
    selectedOrganizationState,
} from '@Data/organizations';
import { ProjectsApi, selectedProjectState } from '@Data/projects';
import { SolutionsApi, selectedProjectServiceState } from '@Data/solutions';
import { WorkspacesApi, selectedWorkspaceState } from '@Data/workspaces';
import { FeatureFlags } from '@Services/featureFlags/FeatureFlagsList';
import { createToast } from '@Services/notifications';
import { ProjectServiceIframe } from './ProjectServiceIframe';

export const TemporaryProjectServiceView = () => {
    const iframeId = 'projectServiceIframe';
    const FLAG_tryDisplaySwagger = useFeatureFlagEnabled(
        FeatureFlags.RenderPublisherSwagger
    );

    const navigate = useNavigate();
    const params = useParams();

    const organizationId = params.organizationId;
    const workspaceId = params.workspaceId;
    const projectId = params.projectId;
    const serviceId = params.serviceId;

    const selectedOrganization = useSelector(selectedOrganizationState);
    const selectedWorkspace = useSelector(selectedWorkspaceState);
    const selectedProject = useSelector(selectedProjectState);
    const selectedProjectService = useSelector(selectedProjectServiceState);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [url, setUrl] = useState<string>(null);

    const throwUnauthorizedMessage = () => {
        createToast(
            'You do not have access to the requested resource. Please contact your administrator',
            ToastType.ERROR
        );
        navigate('/');
    };

    useEffect(() => {
        if (
            _isNil(organizationId) ||
            _isNil(workspaceId) ||
            _isNil(projectId) ||
            _isNil(serviceId)
        ) {
            navigate('/');
        }
    }, [organizationId, workspaceId, projectId, serviceId]);

    useEffect(() => {
        const getSelectedOrganization = async () => {
            try {
                await OrganizationsApi.loadOrganizationById(
                    organizationId,
                    true
                );
            } catch (unauthorizedError) {
                throwUnauthorizedMessage();
            }
        };

        if (
            !_isNil(organizationId) &&
            (_isNil(selectedOrganization) ||
                selectedOrganization.id !== organizationId)
        ) {
            getSelectedOrganization();
        }
    }, [selectedOrganization, organizationId]);

    useEffect(() => {
        const getSelectedWorkspace = async () => {
            await WorkspacesApi.loadWorkspaceById(workspaceId);
        };

        if (
            !_isNil(selectedOrganization) &&
            !_isNil(workspaceId) &&
            (_isNil(selectedWorkspace) || selectedWorkspace.id !== workspaceId)
        ) {
            getSelectedWorkspace();
        }
    }, [selectedOrganization, selectedWorkspace, workspaceId]);

    useEffect(() => {
        const getSelectedProject = async () => {
            await ProjectsApi.loadProjectById(projectId);
        };

        if (
            !_isNil(selectedOrganization) &&
            !_isNil(selectedWorkspace) &&
            !_isNil(projectId) &&
            (_isNil(selectedProject) || selectedProject.id !== projectId)
        ) {
            getSelectedProject();
        }
    }, [selectedOrganization, selectedWorkspace, selectedProject, projectId]);

    useEffect(() => {
        const getSelectedService = async () => {
            const service = await SolutionsApi.loadProjectServiceById(
                serviceId
            );
            if (service.status === 'up' && service.outputs?.endpoint) {
                if (
                    service.name.toLowerCase() === 'analytics' ||
                    (!_isNil(service.values['openExternal']) &&
                        service.values['openExternal'] === true)
                ) {
                    window.location.href = service.outputs.endpoint;
                } else {
                    const name =
                        !_isNil(service.display_name) &&
                        service.display_name !== ''
                            ? service.display_name
                            : service.name;
                    document.title = `${name} - Orbica Platform`;
                    if (
                        service.outputs?.type &&
                        service.outputs.type === 'publisher' &&
                        FLAG_tryDisplaySwagger
                    ) {
                        setUrl('about:blank');
                        setIsLoading(false);

                        const swaggerHtmlResponse = await fetch(
                            `${service.outputs.endpoint}`,
                            {
                                method: 'GET',
                            }
                        );
                        swaggerHtmlResponse.text().then((body) => {
                            const iframe = document.getElementById(
                                iframeId
                            ) as HTMLIFrameElement;
                            iframe.contentWindow.document.open();
                            iframe.contentWindow.document.write(body);
                            iframe.contentWindow.document.close();
                        });
                    } else {
                        setUrl(service.outputs.endpoint);
                        setIsLoading(false);
                    }
                }
            } else {
                createToast(
                    'The selected Solution is not yet ready',
                    ToastType.ERROR
                );
                navigate(-1);
            }
        };

        if (
            !_isNil(selectedOrganization) &&
            !_isNil(selectedWorkspace) &&
            !_isNil(selectedProject) &&
            (_isNil(selectedProjectService) ||
                selectedProjectService.id !== serviceId)
        ) {
            getSelectedService();
        }
    }, [
        selectedOrganization,
        selectedWorkspace,
        selectedProject,
        serviceId,
        selectedProjectService,
    ]);

    const innerHtml = isLoading ? (
        <LoadingLogo />
    ) : (
        <ProjectServiceIframe src={url} id={iframeId} />
    );

    return <Page>{innerHtml}</Page>;
};
